import { Box, Flex, Text } from "@chakra-ui/react";
import { HiOutlineUserCircle } from "react-icons/hi2";

import { ChangeEvent } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomLink from "../../components/common/CustomLink";
import MobileStockMenu from "../../components/common/menu/MobileStockMenu";
import Logo from "../../components/Logo";
import { ICompany } from "../../types/Stock";
import CompanySearch from "./CompanySearch";

type TProps = {
  searchValue: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  stocks: ICompany[];
  clearSearch: () => void;
};

function MobileTopContent({
  searchValue = "",
  onChange,
  stocks,
  clearSearch,
}: TProps) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isHomePage = pathname === "/" || pathname?.startsWith("/?");
  const isStockPage = pathname?.startsWith("/stocks");
  const isWatchlist =
    pathname?.startsWith("/watchlist") || pathname?.startsWith("/portfolio");
  const isScreener = pathname?.startsWith("/screener");

  return (
    <Flex flexDirection="column" width={{ md: "fit-content", base: "100%" }}>
      <Flex
        alignItems="flex-start"
        justifyContent="center"
        position="relative"
        mb={{ md: "0", base: "40px" }}
      >
        <Box display={{ base: isHomePage ? "none" : "block", md: "none" }}>
          <CustomLink
            to={"/"}
            title="Home"
            ariaLabel="Back to Home page"
            withTransition
          >
            <BiChevronLeft fontSize="24px" color="currentColor" />
          </CustomLink>
        </Box>
        <Flex
          alignItems="center"
          columnGap="24px"
          justifyContent="center"
          position="relative"
          ml={{ base: "auto" }}
          pt={{ base: "4px", md: "0" }}
        >
          {/* <CustomLink
            as='button'
            title='Settings'
            ariaLabel='Settings'
            withTransition
            onClick={(e) => {
              e.preventDefault();
              dispatch(openModal({ name: 'Locale', link: '', type: 'locale' }));
            }}
          >
            <IoMdSettings fontSize='30px' color='currentColor' />
          </CustomLink> */}

          <CustomLink
            to={"/profile"}
            title="Profile"
            ariaLabel="Profile"
            withTransition
            // onClick={(e) => {
            //   e.preventDefault();
            //   dispatch(openModal({ name: "User", link: "", type: "user" }));
            // }}
          >
            <HiOutlineUserCircle fontSize="30px" color="currentColor" />
          </CustomLink>
        </Flex>
      </Flex>
      {isHomePage && (
        <Box
          display={{ md: "none", base: "flex" }}
          w="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection={"column"}
          color="#FFF"
          gap="16px"
        >
          <Box mb="28px">
            <Logo />
          </Box>

          <Flex
            w="250px"
            textAlign="center"
            fontSize="24px"
            lineHeight="29px"
            fontWeight="400"
            alignItems="center"
            justifyContent="center"
            flexDirection={"column"}
            mb="10px"
          >
            <span>Find the stocks</span>
            <span>that beat the market</span>
          </Flex>

          <CompanySearch
            searchValue={searchValue}
            stocks={stocks}
            onChange={onChange}
            show
            reversed
            mobile
            clearSearch={clearSearch}
          />

          <Text>Or start with one of the most popular...</Text>
        </Box>
      )}
      {isStockPage && (
        <Box
          display={{ md: "none", base: "block" }}
          w="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection={"column"}
          color="#FFF"
        >
          <Text
            fontSize={"32px"}
            fontWeight={500}
            lineHeight={"35px"}
            mb="16px"
          >
            Stock analysis
          </Text>
          <MobileStockMenu />
        </Box>
      )}
      {isWatchlist && (
        <Box
          display={{ md: "none", base: "block" }}
          w="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection={"column"}
          color="#FFF"
          id="header-app"
        ></Box>
      )}
      {isScreener && (
        <Box
          display={{ md: "none", base: "block" }}
          w="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection={"column"}
          color="#FFF"
          id="header-app"
        ></Box>
      )}
    </Flex>
  );
}

export default MobileTopContent;
