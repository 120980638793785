import { Box, Button, Flex, Text } from "@chakra-ui/react";
import React from "react";

import NewCompanyBasicCard from "../../../components/Stocks/CompanyCard/NewCompanyBasicCard";
import NewMonkScore from "../../../components/Stocks/NewMonkScore";
import { singleStockMenu } from "../../../constants/menu";
import { IStock } from "../../../types/Stock";

type TProps = {
  currentIdx: number;
  setCurrentIdx: (value: React.SetStateAction<number>) => void;
  compData: IStock;
};

function LeftStocksSubView({
  currentIdx = 0,
  setCurrentIdx,
  compData,
}: TProps) {
  return (
    <Flex
      minW="240px"
      flexDirection="column"
      alignItems="center"
      gap="30px"
      display={{ base: "none", md: "flex" }}
    >
      <Box>
        <NewCompanyBasicCard compData={compData} />
      </Box>

      <Flex flexDirection="column" gap="10px" width="100%">
        {singleStockMenu.slice(0, 3).map((subItem, idx) => {
          const SubIcon = subItem.icon;

          if (subItem?.hidden) {
            return null;
          }

          function getIconColor() {
            if (subItem?.color && currentIdx === idx) {
              return subItem?.color;
            }

            if (currentIdx === idx) {
              return "#085096";
            }

            return "transparent";
          }

          return (
            <Button
              key={subItem.name}
              alignItems="center"
              justifyContent="flex-start"
              columnGap="10px"
              borderRadius="10px"
              backgroundColor={currentIdx === idx ? "#FFF" : "transparent"}
              px="10px"
              py="10px"
              width="100%"
              height="auto"
              onClick={() => setCurrentIdx(idx)}
            >
              <Box
                backgroundColor={getIconColor()}
                color={currentIdx === idx ? "#FFF" : "#999A9B"}
                p="4px"
                borderRadius="5px"
              >
                <SubIcon width="24px" height="24px" />
              </Box>
              <Text
                color="#1C1C1C"
                fontWeight="400"
                fontSize="14px"
                lineHeight="21px"
              >
                <Text display="inline-block" as={"span"}>
                  {subItem.title}
                </Text>
                {idx === 0 && <sup>®</sup>}
              </Text>
              {idx === 0 && (
                <Flex ml="auto" alignItems="center">
                  <NewMonkScore monkScore={compData?.monkScore} thin />
                </Flex>
              )}
            </Button>
          );
        })}
      </Flex>
    </Flex>
  );
}

export default React.memo(LeftStocksSubView);
