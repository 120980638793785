import { Box, Button, Text } from "@chakra-ui/react";
import { useState } from "react";

import { FiChevronRight } from "react-icons/fi";

import styles from "./styles.module.css";

const FIELDS = {
  sector: "Sector",
  industry: "Industry",
  ceo: "CEO",
  exchange: "Exchange",
  ipoDate: "IPO Date",
  website: "Website",
  description: "Description",
};

function ProfileCard({ field, value }: { field: string; value: string }) {
  const [open, setOpen] = useState(false);

  if (!value) {
    return null;
  }

  if (field === "description") {
    return (
      <Box
        borderRadius="20px"
        p="20px"
        backgroundColor={"#F9FAFB"}
        w={{ base: "100%", md: "unset" }}
      >
        <Text fontSize={"14px"} lineHeight={"21px"} fontWeight={"600"}>
          {FIELDS[field]}
        </Text>

        <Text fontSize={"14px"} lineHeight={"22px"} fontWeight={"400"}>
          <span className={!open ? styles.clamp : ""}>{value}</span>
        </Text>
        <Button
          variant="link"
          fontSize="14px"
          fontWeight={"400"}
          color="#0564B8"
          px="0"
          py="5px"
          onClick={() => setOpen((cur) => !cur)}
        >
          {!open ? "View more" : "Hide more"}
          <FiChevronRight
            style={{
              transform: open ? "rotate(-90deg)" : "rotate(90deg)",
              marginLeft: "4px",
            }}
          />
        </Button>
      </Box>
    );
  }

  return (
    <Box borderRadius="20px" p="20px" backgroundColor={"#F9FAFB"}>
      <Text fontSize={"14px"} lineHeight={"21px"} fontWeight={"600"}>
        {FIELDS[field]}
      </Text>

      <Text fontSize={"14px"} lineHeight={"22px"} fontWeight={"400"}>
        {field === "ipoDate" ? new Date(value)?.toDateString() : value}
      </Text>
    </Box>
  );
}

export default ProfileCard;
