export const sector = [
  "Basic Materials",
  "Communication Services",
  "Consumer Cyclical",
  "Consumer Defensive",
  "Energy",
  "Financial Services",
  "Healthcare",
  "Industrials",
  "Real Estate",
  "Technology",
  "Utilities",
];

export const sectorSelect = () => {
  return sector.map((v) => {
    return {
      label: v,
      value: v,
    };
  });
};
