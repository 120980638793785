import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ children, ...rest }) => {
  const isLoggedIn = localStorage.getItem("accessToken");
  let replacedStr = "";
  if (isLoggedIn) {
    replacedStr = isLoggedIn?.trim().replace('""', "");
  }

  return (
    <>
      {isLoggedIn && replacedStr !== "" ? (
        children
      ) : (
        <Navigate to="/" replace />
      )}
    </>
  );
};
