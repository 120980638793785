import mitt, { Emitter } from "mitt";
import { createContext } from "react";
import { Filter } from "../types/Screener";

type Events = {
  save: string;
  whenChangeFilter: Array<Filter>;
  whenFilterRemoved: number;
};

const emitter: Emitter<Events> = mitt<Events>();
emitter.on("save", (e) => {
  // console.log("Emit save")
});

export const EmitterContext = createContext(emitter);
