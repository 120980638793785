import { useEffect, useState } from "react";

const useBreakpoints = () => {
  const [device, setDevice] = useState(`mobile`);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  //

  useEffect(() => {
    if (typeof window === `undefined`) {
      return;
    }

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setDevice("mobile");
      } else if (window.innerWidth <= 1024) {
        setDevice("tablet");
      } else {
        setDevice("desktop");
      }
    };

    handleResize();

    window.addEventListener(`resize`, handleResize);

    return () => {
      window.removeEventListener(`resize`, handleResize);
    };
  }, []);

  //

  useEffect(() => {
    setIsDesktop(device === `desktop`);
  }, [device]);

  useEffect(() => {
    setIsMobile(device === `mobile`);
  }, [device]);

  useEffect(() => {
    setIsTablet(device === `tablet`);
  }, [device]);

  //

  return {
    device,
    isDesktop,
    isMobile,
    isTablet,
  };
};

export default useBreakpoints;
