import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { axiosInstance } from "../../context/api";
import { useFetchWatchlistDataQuery } from "../../services/watchlistPortfolioApi";
import { Watchlist, WatchlistOne, WatchlistStock } from "../../types/Watchlist";

type State = {
  watchlist: WatchlistOne | null;
  loading: boolean;
  stocks: Array<WatchlistStock>;
  removedStocks: Array<WatchlistStock>;
  error: boolean;
  optionsWatchlist: Array<{ label: string; value: string }>;
  optionsPortfolio: Array<{ label: string; value: string }>;
};

const initialState: State = {
  watchlist: null,
  stocks: [],
  removedStocks: [],
  loading: false,
  error: false,
  optionsWatchlist: [] as Array<{ label: string; value: string }>,
  optionsPortfolio: [] as Array<{ label: string; value: string }>,
};

export const fetchWatchlist = createAsyncThunk("watchlist/fetch", async () => {
  try {
    const { data, error } = await useFetchWatchlistDataQuery("watchlist", {});
    return data;
  } catch (error) {
    return [];
  }
});

export const fetchOne = createAsyncThunk(
  "watchlist/fetchBy",
  async ({ is, id }: { is: string; id: string | number }, thunkAPI) => {
    const response = await axiosInstance.get<WatchlistOne>(`${is}/${id}`);
    return response.data;
  },
);

export const fetchAllWatchlist = createAsyncThunk(
  "watchlist/fetchAll",
  async ({ is }: { is: "watchlist" | "portfolio" }, thunkAPI) => {
    const response = await axiosInstance.get<{
      data: Array<Watchlist>;
      success: boolean;
    }>(`${is}/`);
    return response.data.data;
  },
);

export const fetchAllPortfolio = createAsyncThunk(
  "watchlist/fetchAllPortfolio",
  async () => {
    const response = await axiosInstance.get<{
      data: Array<Watchlist>;
      success: boolean;
    }>(`portfolio/`);
    return response.data.data;
  },
);

const watchlistSlice = createSlice({
  name: "watchlist",
  initialState,
  reducers: {
    addCompany: (state, action: PayloadAction<WatchlistStock>) => {
      const actualStocks = state.stocks as Array<WatchlistStock>;
      actualStocks.push(action.payload);
      state.stocks = [...actualStocks];
    },
    removeCompany: (state, action: PayloadAction<WatchlistStock>) => {
      const id = action.payload.id;
      const actualStocks = state.stocks as Array<WatchlistStock>;

      const index = actualStocks.findIndex((stock) => stock.id.includes(id));

      if (index !== -1) {
        const removedStocks = state.removedStocks;
        removedStocks.push(actualStocks[index]);
        state.removedStocks = [...removedStocks];
        state.stocks.splice(index, 1);
        //state.stocks = [...actualStocks];
      }
    },

    setStocks: (state, action: PayloadAction<WatchlistStock[]>) => {
      state.stocks = [...action.payload];
    },
    cleanUpRemoved: (state) => {
      state.removedStocks = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWatchlist.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchWatchlist.fulfilled, (state, action) => {
        state.loading = false;
        /*if (action.payload) {
          state.watchlist = action.payload;
        }*/
      })
      .addCase(fetchWatchlist.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchOne.fulfilled, (state, action) => {
        state.watchlist = action.payload;
        state.stocks = action.payload.stocks;
        state.removedStocks = [];
        state.loading = false;
      })
      .addCase(fetchOne.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.stocks = [];
        state.removedStocks = [];
      })
      .addCase(fetchOne.pending, (state, action) => {
        state.error = false;
        state.loading = true;
        state.stocks = [];
        state.removedStocks = [];
      })
      .addCase(fetchAllWatchlist.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.optionsWatchlist = action.payload.map((watchlist) => ({
          label: watchlist.name,
          value: `${watchlist.id}`,
        }));
        state.loading = false;
      })
      .addCase(fetchAllWatchlist.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.optionsWatchlist = [];
      })
      .addCase(fetchAllWatchlist.pending, (state, action) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(fetchAllPortfolio.fulfilled, (state, action) => {
        state.optionsPortfolio = action.payload.map((portfolio) => ({
          label: portfolio.name,
          value: `${portfolio.id}`,
        }));
        state.loading = false;
      })
      .addCase(fetchAllPortfolio.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.optionsPortfolio = [];
      });
  },
});

export const { cleanUpRemoved, removeCompany, setStocks } =
  watchlistSlice.actions;
export const watchlist = (state) => state.watchlist.watchlist as WatchlistOne;
export const stocks = (state) =>
  state.watchlist.stocks as Array<WatchlistStock>;
export const loading = (state) => state.watchlist.loading as boolean;
export const error = (state) => state.watchlist.error as boolean;
export const removedStocks = (state) =>
  state.watchlist.removedStocks as Array<WatchlistStock>;
export const optionsWatchlist = (state) =>
  state.watchlist.optionsWatchlist as Array<{ label: string; value: string }>;
export const optionsPortfolio = (state) =>
  state.watchlist.optionsPortfolio as Array<{ label: string; value: string }>;
export default watchlistSlice.reducer;
