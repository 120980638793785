import { Box, Button, Flex, Text } from "@chakra-ui/react";
import RatioIcon from "../../../assets/svg/Ratioicon.svg";
import { ScoreTimeline } from "../../../types/Chart";
import { handleTitleSubtext } from "../../../utils/formatChartNumber";

interface IEntryChartsProps {
  name: string;
  data: ScoreTimeline[];
  useValue?: boolean;
  anchor?: string;
  onClick?: (name: string) => void;
  onGoTo?: (name: string) => void;
}

export function EntryBoxChart({
  name,
  data,
  useValue,
  anchor,
  onClick,
  onGoTo,
}: IEntryChartsProps) {
  const statusRating =
    data.length > 0
      ? useValue
        ? data[data.length - 1].value
        : data[data.length - 1].percentile
      : 0;

  return (
    <Box w="100%" gap="15px">
      <Flex
        my="10px"
        w={"100%"}
        h={"max-content"}
        gap="10px"
        overflowY={"auto"}
        justifyContent={"space-between"}
        overflow={"hidden"}
        maxHeight={"3em"}
      >
        <Box
          cursor={"pointer"}
          ml={{ base: "2px" }}
          display="flex"
          alignItems="center"
          onClick={(e) => {
            e.preventDefault();
            onClick && onClick(name);
            onGoTo && anchor && onGoTo(anchor);
          }}
        >
          <Flex
            w="50px"
            height={"50px"}
            display={{ base: "none", sm: "none", md: "flex" }}
          >
            <img
              src={RatioIcon}
              alt=""
              style={{
                borderRadius: "10%",
                height: "50px",
                width: "50px",
              }}
            />
          </Flex>

          <Flex
            flexDirection={"column"}
            alignItems={"flex-start"}
            justifyContent={"center"}
            w="100%"
          >
            <Button
              width={{ base: "70px", sm: "70px", md: "150px" }}
              fontSize={{ base: "8px", sm: "8px", md: "12px" }}
              lineHeight={"18px"}
              fontWeight={400}
              display={"flex"}
              alignItems={"center"}
              variant={"unstyled"}
              p="0"
              height={"auto"}
              justifyContent={"flex-start"}
              flexWrap={"wrap"}
              wordBreak={"break-all"}
              textDecoration="underline"
              textDecorationStyle={"dashed"}
              textDecorationColor={"#c9cacb"}
              textDecorationThickness={"from-font"}
              textUnderlineOffset={"4px"}
              columnGap={"4px"}
            >
              <Text noOfLines={1}>{name}</Text>
            </Button>
            <Text
              color="#999A9B"
              fontSize={{ base: "8px", sm: "8px", md: "12px" }}
              lineHeight={"18px"}
              fontWeight={400}
              display={"flex"}
              alignItems={"center"}
            >
              {handleTitleSubtext(statusRating)}
            </Text>
          </Flex>
        </Box>

        {/* <EntryChart data={data} useValue={useValue} /> */}

        <Flex alignItems={"center"}>
          <Text
            ml="5px"
            fontWeight={400}
            fontSize={"12px"}
            lineHeight={"18px"}
            color="#999A9B"
          >
            {Math.round(statusRating)}
          </Text>
          <Box
            ml="5px"
            height={11}
            width={11}
            backgroundColor={
              statusRating && statusRating >= 90
                ? "green"
                : statusRating && statusRating >= 70 && statusRating < 90
                  ? "#58CC02"
                  : statusRating && statusRating >= 50 && statusRating < 70
                    ? "#9BE067"
                    : statusRating && statusRating >= 20 && statusRating < 50
                      ? "#FFC800"
                      : statusRating && statusRating <= 20
                        ? "#FF4B4B"
                        : "#FF4B4B"
            }
            me={3}
            borderRadius={50}
          ></Box>
        </Flex>
      </Flex>
    </Box>
  );
}
