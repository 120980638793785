import { Box, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import React from "react";
import { useSearchParams } from "react-router-dom";

import { NewStockWithEditable } from "../../../components/Stocks/NewStockItem/NewStockWithEditable";
import { HeroBox } from "../../../components/common/Box/Hero";
import { leftSideBarMenu, submenu } from "../../../constants/menu";
import { HeroTopBar } from "../../../layout/TopBar/HeroTopBar";

type TProps = {
  currentIdx: number;
  stocks: {
    companyName: string;
    ticker: string;
    monkScore: number;
    image: string;
  }[];
};

function RightHomeSubView({ currentIdx = 0, stocks }: TProps) {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  let activeMenu = "analysis";

  if (tab && leftSideBarMenu.findIndex((item) => item.name === tab) === -1) {
    activeMenu = "analysis";
  } else if (tab) {
    activeMenu = tab;
  }

  const tabSubMenu = submenu[activeMenu as string];

  return (
    <Flex
      w="max-content"
      flexDirection="column"
      alignItems="center"
      gap="20px"
      backgroundColor="#FFF"
      px="40px"
      py="30px"
      borderRadius="24px"
      flexGrow="1"
    >
      <HeroBox>
        <HeroTopBar />
      </HeroBox>
      <Box w="100%">
        <Text
          fontSize="24px"
          fontWeight="600"
          lineHeight="36px"
          textAlign="left"
        >
          {tabSubMenu?.[currentIdx]?.title}
        </Text>

        <Text
          fontSize="16px"
          fontWeight="300"
          lineHeight="24px"
          textAlign="left"
        >
          {tabSubMenu?.[currentIdx]?.subtitle}
        </Text>
      </Box>
      <Grid
        templateColumns={{
          base: "repeat(1,1fr)",
          md: "repeat(1,1fr)",
          lg: "repeat(3, 1fr)",
        }}
        mt="15px"
        w={"100%"}
        h={"max-content"}
        flexWrap={"wrap"}
        gap="10px"
        overflowY={"auto"}
        alignItems={{ base: "center", md: "stretch" }}
        overflow={"hidden"}
        className="customGrid"
      >
        {stocks.map((stock, i) => (
          <GridItem
            key={i}
            style={{ height: "160px" }}
            width={"100%"}
            h="160px"
          >
            <NewStockWithEditable
              stock={stock}
              key={i}
              editMode={false}
              width="100%"
            />
          </GridItem>
        ))}
      </Grid>
    </Flex>
  );
}

export default React.memo(RightHomeSubView);
