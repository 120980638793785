import cn from "classnames";
import { Link } from "react-router-dom";
import { useAuthModal } from "../../../hooks/useAuth";
import styles from "./styles.module.css";

function CustomLink({
  to = "/",
  className = "",
  children,
  title = "",
  ariaLabel = "",
  withTransition = false,
  onClick = (e) => {},
  as = "link",
}) {
  const props = ariaLabel ? { "aria-label": ariaLabel } : {};
  const { goToLinkOrOpenModal } = useAuthModal();

  if (as === "button") {
    return (
      <button
        className={cn(styles.root, withTransition && styles.withTransition)}
        title={title}
        {...props}
        onClick={(e) => {
          const go = goToLinkOrOpenModal(to);
          if (go) {
            onClick(e);
          }
        }}
      >
        {children}
      </button>
    );
  }

  return (
    <Link
      to={to}
      className={cn(
        styles.root,
        withTransition && styles.withTransition,
        className,
      )}
      title={title}
      {...props}
      onClick={(e) => {
        const go = goToLinkOrOpenModal(to);
        if (go) {
          onClick(e);
        }
      }}
    >
      {children}
    </Link>
  );
}

export default CustomLink;
