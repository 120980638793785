import {
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { ReactComponent as PlusIcon } from "../../assets/svg/add.svg";
import { useCreateMutation } from "../../services/watchlistPortfolioApi";
import { CommonButton } from "../common/buttons/common";
import { CommonModal } from "./Common";

interface Props {
  isOpen: boolean;
  is: "watchlist" | "portfolio";
  onSaveSuccess: () => void;
  onOpen?: () => void;
  onClose: () => void;
}

export function CreateWatchlistModal({
  isOpen,
  onClose,
  is,
  onSaveSuccess,
}: Props) {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{
    name: string;
  }>();

  const [create, { isLoading: isCreating }] = useCreateMutation();

  const onSubmit = async (data) => {
    try {
      const result = await create({
        ...data,
        isWatchlist: is === "watchlist",
      }).unwrap();
      onSaveSuccess();
      reset();
      onClose();
    } catch (error: any) {
      console.log(error);
      toast({
        title: "Error creating",
        description: `Status: ${error.status}. ${error?.data?.message}. ${error?.stack ?? "Unknow stack trace"}`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <CommonModal
      modalContentCustomProps={{
        height: { base: "auto", lg: "400px" },
        padding: { lg: "40px, 20px, 40px, 20px" },
        gap: "20px",
      }}
      isOpen={isOpen}
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <Flex
        as={"form"}
        onSubmit={handleSubmit(onSubmit)}
        alignItems={"center"}
        flexDirection={"column"}
        height={"100%"}
        justifyContent={"space-around"}
      >
        <Flex alignItems={"center"} flexDirection={"column"}>
          <PlusIcon />
          <Text
            fontSize={"18px"}
            fontWeight={"600"}
            lineHeight={"27px"}
            align="center"
            fontFamily="Poppins"
            noOfLines={1}
            mb={2}
            mt={2}
          >
            {`Add a ${is}`}
          </Text>
          <Text
            fontWeight="300"
            fontSize="16px"
            lineHeight="24px"
            fontFamily="Poppins"
            noOfLines={1}
            mb={4}
          >
            {`Choose a name for your ${is}:`}
          </Text>
        </Flex>
        <FormControl isInvalid={Boolean(errors.name)}>
          <Input
            id="name"
            padding={"10px 10px 10px 20px"}
            gap={"8px"}
            height={"56px"}
            placeholder={`Name your ${is}`}
            _placeholder={{
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: "400",
            }}
            {...register("name", {
              required: "Name is required",
              minLength: {
                value: 3,
                message: "Name must be at least 3 characters",
              },
            })}
          />
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </FormControl>
        <CommonButton
          title={`Add ${is}`}
          customProps={{
            mt: 2,
            type: "submit",
            isLoading: isCreating,
            width: "100%",
            height: "56px",
            borderRadius: "20px",
          }}
        ></CommonButton>
      </Flex>
    </CommonModal>
  );
}
