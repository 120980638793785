import axios from "axios";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PaywallModal } from "../components/Modals/PaywallModal";
import {
  TooltipModal,
  TooltipModalContext,
} from "../components/Stocks/TooltipModal";
import { useLocalStorage } from "../hooks/useLocalStorage";
import DesktopContent from "../layout/MainContent/DesktopContent";
import LeftStocksSubView from "../subviews/LeftSubView/Stocks";
import RightStocksSubView from "../subviews/RightSubview/Stocks";

import { useDispatch, useSelector } from "react-redux";
import { ChartModal } from "../components/Modals/Chart";
import { MODAL_TYPES_ENUM } from "../constants/variables";
import MobileContent from "../layout/MainContent/MobileContent";
import { openModal } from "../store/slices/modal";
import {
  actualActiveChart,
  closeOpenActive,
  fetchAllCompanyRatios,
  fetchAllCompanyScores,
  fetchAllRatios,
  fetchOneTimeline,
  setStock,
} from "../store/slices/stock";
import { IIncome, IRevenue, IStock } from "../types/Stock";

interface AlphaVantageData {
  AnalystTargetPrice: string;
  DividendPerShare: string;
  DividendYield: string;
  MarketCapitalization: string;
  SharesOutstanding: string;
}

const Company = () => {
  const navigate = useNavigate();

  const { ticker, exchangeTicker } = useParams();
  const dispatch = useDispatch();
  const activeChartModal = useSelector(actualActiveChart);

  const {
    isOpen: isTooltipModalOpen,
    closeModal: closeTooltipModal,
    modalData: tooltipModalData,
  } = useContext(TooltipModalContext);

  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);

  const [visits, setVisits] = useLocalStorage("visits", 0);
  const [accessToken, setAccessToken] = useLocalStorage("accessToken", "");
  const [stockData, setStockData] = useState({} as IStock);
  const [revenueData, setRevenueData] = useState<IRevenue[]>([]);
  const [incomeData, setIncomeData] = useState<IIncome[]>([]);

  const [performance, setPerformance] = useState<string>("");
  const [stockCurrentPrice, setStockCurrentPrice] = useState<number>(0);
  const [showPositive, setShowPositive] = useState<boolean>(false);
  const [showNeutral, setShowNeutral] = useState<boolean>(false);
  const [showNegative, setShowNegative] = useState<boolean>(false);
  const [alphaData, setAlphaData] = useState({} as AlphaVantageData);
  const [currentMenuIdx, setCurrentMenuIdx] = useState(0);

  const logRef = useRef(false);

  useEffect(() => {
    if (accessToken === "" && visits >= 5) {
      window.scrollTo(0, 0);

      dispatch(openModal({ name: "User", link: "", type: "user" }));
    }

    setVisits((prev) => prev + 1);
  }, [accessToken]);

  useEffect(() => {
    if (accessToken) {
      setIsPaywallModalOpen(false);
    }
  }, [accessToken]);

  const getStocksData = useCallback(
    async (key: string) => {
      const authorization = accessToken.replace(/['"]+/g, "").trim();
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/stocks/${exchangeTicker}:${ticker}`,
          {
            headers: {
              token: authorization,
              authorization: authorization,
            },
          },
        );

        if (response.status === 200) {
          if (!response.data.success && response.data.isAnalysisBlocked) {
            dispatch(
              openModal({
                name: MODAL_TYPES_ENUM.pricing,
                link: `analysis-${response.data.limit}`,
                type: "pricing",
              }),
            );

            return;
          }

          setStockData(response.data?.data);
          new Promise(() => {
            dispatch(setStock(response.data?.data) as any);
            dispatch(
              fetchOneTimeline({
                fullTicker: `${exchangeTicker}:${ticker}`,
              }) as any,
            );
            dispatch(
              fetchAllCompanyRatios({
                fullTicker: `${exchangeTicker}:${ticker}`,
              }) as any,
            );
            dispatch(
              fetchAllCompanyScores({
                fullTicker: `${exchangeTicker}:${ticker}`,
              }) as any,
            );
            dispatch(
              fetchAllRatios({
                fullTicker: `${exchangeTicker}:${ticker}`,
              }) as any,
            );
          });
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [exchangeTicker, ticker, accessToken],
  );

  const getRevenueData = useCallback(
    async (key: string) => {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/charts/${exchangeTicker}:${ticker}/revenue-ltm`,
      );

      if (response.status === 200) {
        setRevenueData(response?.data?.data);
      } else {
        console.log(response);
      }
    },
    [exchangeTicker, ticker],
  );

  const getIncomeData = useCallback(
    async (key: string) => {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/stocks/${exchangeTicker}:${ticker}/income-statement`,
      );

      if (response.status === 200) {
        setIncomeData(response?.data?.data);
      } else {
        console.log(response);
      }
    },
    [exchangeTicker, ticker],
  );

  const setUserLog = useCallback(async () => {
    if (!accessToken) return;
    if (logRef.current) return;

    const authorization = accessToken.replace(/['"]+/g, "").trim();

    const resp = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/userlog/${exchangeTicker}:${ticker}`,
      {
        date: new Date().toISOString(),
      },
      {
        headers: {
          token: authorization,
          authorization: authorization,
        },
      },
    );

    if (resp.status === 401) {
      dispatch(openModal({ name: "User", link: "", type: "user" }));
    }

    if (resp.status === 200) {
      console.log("User log created");
      logRef.current = true;
    }
  }, [accessToken, logRef]);

  const setPerformanceText = useCallback(() => {
    if (stockData && stockData?.monkScore) {
      stockData?.monkScore >= 80 && setPerformance("extremely likely");
      stockData?.monkScore < 80 &&
        stockData?.monkScore >= 60 &&
        setPerformance("very likely");
      stockData?.monkScore < 60 &&
        stockData?.monkScore >= 40 &&
        setPerformance("likely");
      stockData?.monkScore < 40 &&
        stockData?.monkScore >= 20 &&
        setPerformance("unlikely");
      stockData?.monkScore < 20 && setPerformance("very unlikely");
    }
  }, [stockData]);

  const handleShowSections = useCallback(() => {
    if (stockData) {
      setShowPositive(
        stockData?.valueScore >= 60 ||
          stockData?.growthScore >= 60 ||
          stockData?.profitabilityScore >= 60 ||
          stockData?.transparencyScore >= 60 ||
          stockData?.momentumScore >= 60 ||
          stockData?.healthScore >= 60 ||
          stockData?.retributionScore >= 60,
      );
      setShowNeutral(
        (stockData?.valueScore >= 40 && stockData?.valueScore < 60) ||
          (stockData?.growthScore >= 40 && stockData?.growthScore < 60) ||
          (stockData?.profitabilityScore >= 40 &&
            stockData?.profitabilityScore < 60) ||
          (stockData?.transparencyScore >= 40 &&
            stockData?.transparencyScore < 60) ||
          (stockData?.momentumScore >= 40 && stockData?.momentumScore < 60) ||
          (stockData?.healthScore >= 40 && stockData?.healthScore < 60) ||
          (stockData?.retributionScore >= 40 &&
            stockData?.retributionScore < 60),
      );
      setShowNegative(
        stockData?.valueScore < 40 ||
          stockData?.growthScore < 40 ||
          stockData?.profitabilityScore < 40 ||
          stockData?.transparencyScore < 40 ||
          stockData?.momentumScore < 40 ||
          stockData?.healthScore < 40 ||
          stockData?.retributionScore < 40,
      );
    }
  }, [stockData]);

  useEffect(() => {
    if (ticker) {
      getStocksData(ticker);
      getRevenueData(ticker);
      getIncomeData(ticker);
    }
  }, [ticker, getRevenueData, getIncomeData, getStocksData]);

  useEffect(() => {
    if (stockData) {
      setPerformanceText();
      handleShowSections();
      const timer = setTimeout(setUserLog, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [handleShowSections, setPerformanceText, stockData]);

  return (
    <>
      <MobileContent>
        <DesktopContent>
          <LeftStocksSubView
            currentIdx={currentMenuIdx}
            setCurrentIdx={setCurrentMenuIdx}
            compData={stockData}
          />
          <RightStocksSubView
            currentIdx={0}
            compData={stockData}
            alphaData={alphaData}
            stockCurrentPrice={stockCurrentPrice}
            performance={performance}
          />
        </DesktopContent>
      </MobileContent>
      <TooltipModal
        isOpen={isTooltipModalOpen}
        onClose={closeTooltipModal}
        {...tooltipModalData}
      />
      <ChartModal
        activeChart={activeChartModal.active as any}
        isOpen={activeChartModal.open}
        onClose={() => dispatch(closeOpenActive())}
        type={activeChartModal.type as any}
      />
      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={() => setIsPaywallModalOpen(false)}
      />
    </>
  );
};

export default Company;
