import { createSlice } from "@reduxjs/toolkit";

type TModalAction = {
  type: string;
  payload: {
    name: string;
    link: string;
    type?: string;
  };
};

type TModal = {
  isOpen: boolean;
  name: string;
  link?: string;
  type?: string;
};

const initialState: TModal = {
  isOpen: false,
  name: "",
  link: "",
  // for opening locale & other modals
  type: "",
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action: TModalAction) => {
      if (action?.payload?.type) {
        state.type = action.payload?.type;
      }

      if (action.payload?.link) {
        state.link = action.payload?.link;
      }

      state.name = action.payload?.name;
      state.isOpen = true;
    },
    closeModal: (state) => {
      state.name = "";
      state.type = "";
      state.isOpen = false;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export const selectIsModalOpen = (state) => state.modal.isOpen;
export const selectModal = (state) => state.modal as TModal;

export default modalSlice.reducer;
