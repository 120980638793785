import { Box, Flex, Text } from "@chakra-ui/react";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import CustomLink from "../../components/common/CustomLink";
import { leftSideBarMenu, rightSideBarMenu } from "../../constants/menu";
import { MODAL_TYPES_ENUM } from "../../constants/variables";
import { openModal } from "../../store/slices/modal";
import styles from "../LeftSideBar/styles.module.css";
import classes from "./styles.module.css";
function Footer() {
  const dispatch = useDispatch();

  const topMenuElements = rightSideBarMenu?.map((item) => {
    const Icon = item.icon;
    return (
      <CustomLink
        key={item?.name}
        as="button"
        withTransition
        onClick={() =>
          dispatch(
            openModal({
              name: item?.title,
              link: item?.link,
              type: MODAL_TYPES_ENUM.iframe,
            }),
          )
        }
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          rowGap="2px"
          w={"64px"}
          height={"100%"}
        >
          <Icon color="inherit" />
          <Text fontSize="10px" lineHeight="15px" fontWeight="500">
            {item.title}
          </Text>
        </Flex>
      </CustomLink>
    );
  });

  const bottomMenuElements = leftSideBarMenu?.map((item) => {
    const Icon = item.icon;
    return (
      <CustomLink
        key={item?.name}
        to={item?.link}
        className={cn(
          styles.link,
          !item?.isComingSoon && styles.active,
          item?.isComingSoon && styles.disabled,
        )}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="flex-end"
          color="currentColor"
          rowGap="6px"
          w={"45px"}
          className={styles.wrapper}
          height={"100%"}
        >
          <Icon color="inherit" />
          <Text fontSize="10px" lineHeight="15px" fontWeight="500">
            {item.title}
          </Text>
        </Flex>
        {item?.isComingSoon && (
          <span className={cn(styles.soonBadge, styles.lower)}>Soon</span>
        )}
      </CustomLink>
    );
  });

  return (
    <>
      <Box
        as="footer"
        display="flex"
        flexDirection={{ base: "column", md: "row" }}
        alignItems="center"
        justifyContent="center"
        columnGap={"30px"}
        rowGap="20px"
        bottom="0"
        left="0"
        width="100%"
        height={{ base: "auto", md: "45px" }}
        pt={{ base: "20px", md: "unset" }}
        pb={{ base: "86px", md: "unset" }}
        backgroundColor="#04284B"
        fontFamily="Poppins"
        position="relative"
        zIndex={210}
        color="#FFF"
        fontSize="10px"
        lineHeight="15px"
        textAlign={"center"}
      >
        <Flex
          w={"100%"}
          display={{ base: "flex", md: "none" }}
          alignItems={"stretch"}
          justifyContent={"center"}
          gap={"20px"}
          pt={"20px"}
          px="10px"
        >
          {topMenuElements}
        </Flex>

        <Flex
          flexDirection={{ base: "column", md: "row" }}
          rowGap="20px"
          columnGap={"20px"}
          pl={{ md: "35px", base: "10px" }}
          pr={{ md: "20px", base: "10px" }}
        >
          <Flex
            flexDirection={"row"}
            flexWrap={"wrap"}
            alignItems="center"
            justifyContent="center"
            className={classes.textWrapper}
          >
            <Text>
              Our analysis is generic, not a personalized recommendation.
            </Text>
            &nbsp;
            <Text>No analysis tool is infallible.</Text>
            &nbsp;
            <Text>
              Read our{" "}
              <Link
                target="_blank"
                to="https://www.monk.st/disclaimer"
                style={{ textDecoration: "underline" }}
              >
                disclaimer
              </Link>{" "}
              to learn more.
            </Text>
            <Text>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <Link
                target="_blank"
                to="https://www.monk.st/legal"
                style={{ textDecoration: "underline" }}
              >
                Legal notice
              </Link>
            </Text>
            <Text>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <Link
                to="https://www.monk.st/privacy"
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                Privacy
              </Link>
            </Text>
          </Flex>

          <Flex
            flexDirection={"row"}
            flexWrap={"wrap"}
            alignItems="center"
            justifyContent="center"
          >
            <Text fontWeight="700">© 2023 MonkStreet.&nbsp;</Text>
            <Text fontWeight="700">All rights reserved.</Text>
          </Flex>
        </Flex>
      </Box>
      <Flex
        position={"fixed"}
        bottom={0}
        left={0}
        w={"100%"}
        display={{ base: "flex", md: "none" }}
        alignItems={"stretch"}
        justifyContent={"space-between"}
        gap={"16px"}
        pt={"10px"}
        px="40px"
        pb="10px"
        boxShadow={"0px -2px 2px 0px #0000001A"}
        backgroundColor="#04284B"
        zIndex={220}
      >
        {bottomMenuElements}
      </Flex>
    </>
  );
}

export default Footer;
