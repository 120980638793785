import domtoimage from "dom-to-image";
import html2canvas from "html2canvas";
import { iOSSafari } from "./navigator";
import { quickShare } from "./share";

export function imageIsMainlyWhite(
  imageSrc: string,
  threshold: number,
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.crossOrigin = "Anonymous";

    img.onload = function () {
      let canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      if (ctx) {
        ctx.drawImage(img, 0, 0, img.width, img.height);

        let whitePixels = 0;
        const imageData = ctx.getImageData(0, 0, img.width, img.height);
        const data = imageData.data;

        for (let i = 0; i < data.length; i += 4) {
          const red = data[i];
          const green = data[i + 1];
          const blue = data[i + 2];

          if (red > 200 && green > 200 && blue > 200) {
            whitePixels++;
          }
        }

        const totalPixels = img.width * img.height;
        const whitePercentage = (whitePixels / totalPixels) * 100;

        if (whitePercentage >= threshold) {
          resolve(true);
        } else {
          resolve(false);
        }
      }
    };

    img.onerror = function (err) {
      reject(err);
    };

    img.src = imageSrc;
  });
}

//automatic copy to the clipboard. Otherwise use onSuccess callback
export async function getScreenShot(
  id: string,
  onSuccess: (blob: Blob) => void,
) {
  const element = document.getElementById(id);

  if (element) {
    element.style.maxWidth = "100%";
    element.style.paddingBottom = "0px";
    element.style.paddingTop = "0px";
    return new Promise((resolve, reject) => {
      html2canvas(element).then(function (canvas) {
        canvas.toBlob(function (blob) {
          navigator.clipboard
            .write([
              new ClipboardItem(
                Object.defineProperty({}, blob?.type ?? "image/png", {
                  value: blob,
                  enumerable: true,
                }),
              ),
            ])
            .then(function () {
              onSuccess(blob as Blob);
            });
        });
      });
    });
  }
}

export function getScreenShotDom2Image(
  id: string,
  onSuccess?: (blob: Blob) => void,
  onError?: () => void,
  isCharts?: boolean,
) {
  let element = document.getElementById(id);
  let div = document.createElement("div");
  div.id = "ClonedDivElement";

  if (isCharts && element) {
    const auxiliarDiv = document.createElement("div");
    for (let index = 0; index < element.children.length; index++) {
      auxiliarDiv.appendChild(element.children[index].cloneNode(true));
    }
    if (window.screen.width < 600) {
      auxiliarDiv.style.display = "grid";
      auxiliarDiv.style.alignItems = "center";
    } else {
      auxiliarDiv.className = element.className;
      auxiliarDiv.style.maxWidth = "1000px";
      auxiliarDiv.style.width = "100%";
      div.style.backgroundColor = "#F9FAFB";
      div.style.width = "1000px";
    }
    div.style.backgroundColor = "#F9FAFB";
    //Hidden scrollbox
    const elements = auxiliarDiv.getElementsByClassName("no_scrollable_box");
    for (let index = 0; index < elements.length; index++) {
      elements[index].className = "scrollable_box_hidden";
      //document.body.removeChild(element[index])
    }
    div.appendChild(auxiliarDiv);
  } else if (element) {
    const auxiliarDiv = document.createElement("div");
    for (let index = 0; index < element.children.length; index++) {
      auxiliarDiv.appendChild(element.children[index].cloneNode(true));
    }
    auxiliarDiv.className = element.className;
    auxiliarDiv.style.maxWidth = "100% important";
    auxiliarDiv.style.transform = "scaleX(1.58) scaleY(1.58)";
    auxiliarDiv.style.width = "100%";
    auxiliarDiv.style.backgroundColor = "#F9FAFB";
    div.style.width = "580px";
    div.style.height = "320px";
    div.style.display = "grid";
    div.style.placeItems = "center";
    div.style.paddingTop = "20px";
    div.style.paddingBottom = "20px";
    div.appendChild(auxiliarDiv);
  }

  div.style.backgroundColor = "#F9FAFB";
  document.body.append(div);

  if (iOSSafari(navigator.userAgent)) {
    console.log("In safari navigator");
    domtoimage
      .toSvg(div, getUpsacalerImageAttributes(div, !isCharts))
      .then(function (dataUrl) {
        cleanUp();
        console.log("In safari navigator after convert to svg");
        toClipboardSafari(dataUrl, onSuccess, onError);
      });
  } else {
    domtoimage
      .toBlob(div, getUpsacalerImageAttributes(div, !isCharts))
      .then((blob: Blob) => {
        cleanUp();
        navigator.clipboard
          .write([
            new ClipboardItem({
              "image/png": blob,
            }),
          ])
          .then(() => {
            cleanUp();
            onSuccess && onSuccess(blob);
          })
          .catch((error) => {
            cleanUp();
            console.log(error);
            onError && onError();
          });
      })
      .catch((error) => {
        cleanUp();
        console.log(error);
        onError && onError();
      });
  }
}

export function toClipboardSafari(
  dataUrl,
  onSuccess?: (blob: Blob) => void,
  onError?: () => void,
) {
  const svg = dataUrl;
  const image = document.createElement("img");
  image.addEventListener("load", () => {
    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    canvas.getContext("2d")?.drawImage(image, 0, 0, image.width, image.height);
    const image2Clipboard = new ClipboardItem({
      "image/png": new Promise((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            resolve(blob as Blob);
          },
          "image/png",
          1.0,
        );
      }),
    });
    try {
      navigator.clipboard.write([image2Clipboard]);
      console.log("after clipboard navigator");
      cleanUp();
      onSuccess && onSuccess({} as Blob);
    } catch (error) {
      console.log(error);
      cleanUp();
      onError && onError();
    }
  });
  image.src = svg;
  var svgElement = document.createElement("div");
  svgElement.id = "CloneSvgElement";
  svgElement.style.display = "none";
  svgElement.appendChild(image);
  document.body.appendChild(svgElement);
}

function cleanUp() {
  new Promise((resolve, reject) => {
    let element = document.getElementById("CloneSvgElement");
    if (element) {
      document.body.removeChild(element);
    }
    let cloneNode = document.getElementById("ClonedDivElement");
    if (cloneNode) {
      console.log("Remove cloned div");
      document.body.removeChild(cloneNode);
    }
    resolve(true);
  });
}

export function shareContent({
  id,
  onSuccess,
  onError,
  isCharts,
}: {
  id: string;
  onSuccess?: () => void;
  onError?: () => void;
  isCharts?: boolean;
}) {
  let element = document.getElementById(id);
  let div = document.createElement("div");
  div.id = "ClonedDivElement";

  if (isCharts && element) {
    const auxiliarDiv = document.createElement("div");
    for (let index = 0; index < element.children.length; index++) {
      auxiliarDiv.appendChild(element.children[index].cloneNode(true));
    }
    if (window.screen.width < 600) {
      auxiliarDiv.style.display = "grid";
      auxiliarDiv.style.alignItems = "center";
    } else {
      auxiliarDiv.className = element.className;
      auxiliarDiv.style.maxWidth = "1000px";
      auxiliarDiv.style.width = "100%";
      div.style.backgroundColor = "#F9FAFB";
      div.style.width = "1000px";
    }
    div.style.backgroundColor = "#F9FAFB";
    //Hidden scrollbox
    const elements = auxiliarDiv.getElementsByClassName("no_scrollable_box");
    for (let index = 0; index < elements.length; index++) {
      elements[index].className = "scrollable_box_hidden";
    }
    div.appendChild(auxiliarDiv);
  } else if (element) {
    const auxiliarDiv = document.createElement("div");
    for (let index = 0; index < element.children.length; index++) {
      auxiliarDiv.appendChild(element.children[index].cloneNode(true));
    }
    auxiliarDiv.className = element.className;
    auxiliarDiv.style.maxWidth = "100% important";
    auxiliarDiv.style.transform = "scaleX(1.58) scaleY(1.58)";
    //auxiliarDiv.style.maxWidth = "385px"
    auxiliarDiv.style.width = "100%";
    auxiliarDiv.style.backgroundColor = "#F9FAFB";
    div.style.width = "580px";
    div.style.height = "320px";
    div.style.display = "grid";
    div.style.placeItems = "center";
    div.style.padding = "20px";
    div.appendChild(auxiliarDiv);
  }
  div.style.backgroundColor = "#F9FAFB";
  document.body.append(div);

  domtoimage
    .toSvg(div, getUpsacalerImageAttributes(div, !isCharts))
    .then(function (dataUrl) {
      cleanUp();
      const svg = dataUrl;
      const image = document.createElement("img");
      image.addEventListener("load", async () => {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        canvas
          .getContext("2d")
          ?.drawImage(image, 0, 0, image.width, image.height);
        const dataUrl = canvas.toDataURL();
        const blob = await (await fetch(dataUrl)).blob();
        const filesArray = [
          new File([blob], "monkshare.png", { type: blob.type }),
        ];
        const shareData = {
          files: filesArray,
        };

        quickShare(
          shareData,
          () => {
            cleanUp();
            onSuccess && onSuccess();
          },
          () => {
            cleanUp();
            onError && onError();
          },
        );
      });

      image.src = svg;
      var svgElement = document.createElement("div");
      svgElement.id = "CloneSvgElement";
      svgElement.style.display = "none";
      svgElement.appendChild(image);
      document.body.appendChild(svgElement);
    });
}

function getUpsacalerImageAttributes(domNode, isMonkScore?: boolean) {
  let scale = 2;

  return {
    width: (domNode.clientWidth ?? 0) * scale,
    height: (domNode.clientHeight ?? 0) * scale,
    style: {
      transform: `scale(${scale})`,
      transformOrigin: "top left",
      display: "grid",
      imageResolution: "300dpi",
    },
    quality: 1.0,
  };
}
