import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IChart, ICompanyRatios, ScoresTimeline } from "../types/Chart";
import { IChartData, ShareholderRetribution } from "../types/Core";

export const chartApi = createApi({
  reducerPath: "chartData",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}/api/v1/`,
  }),
  endpoints: (builder) => ({
    fetchChartData: builder.query<IChart, string>({
      query: (chartType) => `charts/${chartType}`,
      transformResponse: (response: { data: IChart }, meta, arg) =>
        response.data,
    }),
    fetchNetDebtToEquity: builder.query<IChartData[], string>({
      query: (fullTicker) => `charts/${fullTicker}/net-debt-to-equity`,
      transformResponse: (response: { data: IChartData[] }, meta, arg) =>
        response.data,
    }),
    fetchInterestCoverage: builder.query<IChartData[], string>({
      query: (fullTicker) => `charts/${fullTicker}/interest-coverage`,
      transformResponse: (response: { data: IChartData[] }, meta, arg) =>
        response.data,
    }),
    fetchSloanRatio: builder.query<IChartData[], string>({
      query: (fullTicker) => `charts/${fullTicker}/sloan-ratio`,
      transformResponse: (response: { data: IChartData[] }, meta, arg) =>
        response.data,
    }),
    fetchExternalFinancingRatio: builder.query<IChartData[], string>({
      query: (fullTicker) => `charts/${fullTicker}/external-financing-ratio`,
      transformResponse: (response: { data: IChartData[] }, meta, arg) =>
        response.data,
    }),
    fetchROIC: builder.query<IChartData[], string>({
      query: (fullTicker) => `charts/${fullTicker}/roic`,
      transformResponse: (response: { data: IChartData[] }, meta, arg) =>
        response.data,
    }),
    fetchROE: builder.query<IChartData[], string>({
      query: (fullTicker) => `charts/${fullTicker}/roe`,
      transformResponse: (response: { data: IChartData[] }, meta, arg) =>
        response.data,
    }),
    fetchGrossProfitsToAssets: builder.query<IChartData[], string>({
      query: (fullTicker) => `charts/${fullTicker}/gross-profits-to-assets`,
      transformResponse: (response: { data: IChartData[] }, meta, arg) =>
        response.data,
    }),
    fetchPriceToCashflow: builder.query<IChartData[], string>({
      query: (fullTicker) => `charts/${fullTicker}/price-to-cashflow`,
      transformResponse: (response: { data: IChartData[] }, meta, arg) =>
        response.data,
    }),
    fetchPriceToEarnings: builder.query<IChartData[], string>({
      query: (fullTicker) => `charts/${fullTicker}/price-to-earnings`,
      transformResponse: (response: { data: IChartData[] }, meta, arg) =>
        response.data,
    }),
    fetchPriceToSales: builder.query<IChartData[], string>({
      query: (fullTicker) => `charts/${fullTicker}/price-to-sales`,
      transformResponse: (response: { data: IChartData[] }, meta, arg) =>
        response.data,
    }),
    fetchNetPayoutYield: builder.query<IChartData[], string>({
      query: (fullTicker) => `charts/${fullTicker}/net-payout-yield`,
      transformResponse: (response: { data: IChartData[] }, meta, arg) =>
        response.data,
    }),
    fetchFCFLtm: builder.query<IChartData[], string>({
      query: (fullTicker) => `charts/${fullTicker}/fcf-ltm`,
      transformResponse: (response: { data: IChartData[] }, meta, arg) =>
        response.data,
    }),
    fetchRevenueLtm: builder.query<IChartData[], string>({
      query: (fullTicker) => `charts/${fullTicker}/revenue-ltm`,
      transformResponse: (response: { data: IChartData[] }, meta, arg) =>
        response.data,
    }),
    fetchNetIncomeLtm: builder.query<IChartData[], string>({
      query: (fullTicker) => `charts/${fullTicker}/netincome-ltm`,
      transformResponse: (response: { data: IChartData[] }, meta, arg) =>
        response.data,
    }),
    fetchShareholderRetribution: builder.query<ShareholderRetribution, string>({
      query: (fullTicker) => `charts/${fullTicker}/shareholder-retribution`,
      transformResponse: (
        response: {
          dividendYieldData: IChartData[];
          debtPaydownYieldData: IChartData[];
          buybackRatioData: IChartData[];
        },
        meta,
        arg,
      ) => ({
        dividendYieldData: response.dividendYieldData.map((item) => ({
          ...item,
          value: item.value * 100,
        })),
        debtPaydownYieldData: response.debtPaydownYieldData.map((item) => ({
          ...item,
          value: item.value * 100,
        })),
        buybackRatioData: response.buybackRatioData.map((item) => ({
          ...item,
          value: item.value * 100,
        })),
      }),
    }),
    fetchCompanyRatios: builder.query<ICompanyRatios[], string>({
      query: (fullTicker) => `charts/${fullTicker}/all-ratios`,
      transformResponse: (response: { data: ICompanyRatios[] }, meta, arg) =>
        response.data,
    }),
    fetchMostRecentRatio: builder.query<ICompanyRatios | null, string>({
      query: (fullTicker) => `charts/${fullTicker}/all-ratios`,
      transformResponse: (
        response: { mostRecentData: ICompanyRatios | null },
        meta,
        arg,
      ) => response.mostRecentData || null,
    }),
    fetchCompanyScoresTimeline: builder.query<ScoresTimeline[], string>({
      query: (fullTicker) => `charts/${fullTicker}/monkscoretimeline`,
      transformResponse: (response: { data: ScoresTimeline[] }, meta, arg) =>
        response.data,
    }),
  }),
});

export const {
  useFetchChartDataQuery,
  useFetchNetDebtToEquityQuery,
  useFetchExternalFinancingRatioQuery,
  useFetchROICQuery,
  useFetchROEQuery,
  useFetchGrossProfitsToAssetsQuery,
  useFetchPriceToCashflowQuery,
  useFetchPriceToSalesQuery,
  useFetchNetPayoutYieldQuery,
  useFetchPriceToEarningsQuery,
  useFetchShareholderRetributionQuery,
  useFetchInterestCoverageQuery,
  useFetchSloanRatioQuery,
  useFetchFCFLtmQuery,
  useFetchNetIncomeLtmQuery,
  useFetchRevenueLtmQuery,
  useFetchCompanyRatiosQuery,
  useFetchMostRecentRatioQuery,
  useFetchCompanyScoresTimelineQuery,
} = chartApi;
