import { createStandaloneToast } from "@chakra-ui/react";

const { ToastContainer, toast } = createStandaloneToast();

export const showErrorToast = (title: string, description: string) => {
  toast({
    title: title,
    description: description,
    status: "error",
    duration: 9000,
    isClosable: true,
  });
};

export const showSuccessToast = (title: string, description: string) => {
  toast({
    title: title,
    description: description,
    status: "success",
    duration: 9000,
    isClosable: true,
  });
};

export const CustomToastContainer = ToastContainer;
