import { Box, Flex, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import Accordion from "../components/Stocks/Accordion";
import DesktopContent from "../layout/MainContent/DesktopContent";
import MobileContent from "../layout/MainContent/MobileContent";

const Disclaimer = () => {
  return (
    <>
      <MobileContent>
        <DesktopContent>
          <Flex width="375px" flexDirection={"column"} ml="auto" mr="auto">
            <Box color="white" columnGap={1} position="relative" mb="40px">
              {/* <Box
                position='absolute'
                right={3}
                top={3}
                style={{ zIndex: 100 }}
              >
                <SigninSignup />
              </Box> */}
              <Text
                fontSize="32px"
                fontWeight="500"
                lineHeight="35px"
                color="#000"
                pl={1}
              >
                <FormattedMessage id="disclaimer_title" />
              </Text>
            </Box>

            <Box px={2} pt="6">
              <Accordion
                titleId="disclaimer_title1"
                descriptionId="disclaimer_description1"
              />

              <Accordion
                titleId="disclaimer_title2"
                descriptionId="disclaimer_description2"
              />
            </Box>
          </Flex>
        </DesktopContent>
      </MobileContent>
    </>
  );
};

export default Disclaimer;
