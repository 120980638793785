import { useDispatch } from "react-redux";
import { openModal as openStoreModal } from "../store/slices/modal";

const pagesWithAuth = ["/portfolio", "/watchlist", "/screener", "/profile"];
export function useAuthModal() {
  const dispatch = useDispatch();
  const goToLinkOrOpenModal = (link: string) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken && pagesWithAuth.includes(link)) {
      dispatch(openStoreModal({ name: "User", link: "", type: "user" }));
      return false;
    }

    return true;
  };

  return { goToLinkOrOpenModal };
}
