import { Box, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { IStock } from "../../../types/Stock";
import CardAccordian from "../CardAccordian";
import GrowthChartCard from "../CompanyCard/GrowthScoreChartCard";
import HealthChartCard from "../CompanyCard/HealthScoreChartCard";
import MomentumCard from "../CompanyCard/MomentumCard";
import ProfitabilityChartCard from "../CompanyCard/ProfitabilityScoreChartCard";
import ShareholderChartCard from "../CompanyCard/ShareholderScoreChart";
import TransparencyCard from "../CompanyCard/TransparencyCard";
import ValuationChartCard from "../CompanyCard/ValuationScoreChartCard";

interface NeutralSectionProps {
  fullTicker: string;
  stockData: IStock;
  lastUpdate: string;
}

interface SequenceList {
  key: string;
  cardType: string;
  score: number;
}

const NewNeutralSection = ({
  fullTicker,
  stockData,
  lastUpdate,
}: NeutralSectionProps) => {
  const [sequence, setSequence] = useState<SequenceList[]>([]);

  const handleSequenceOfCards = useCallback(() => {
    const valuesArr = [
      {
        key: "ValuationCard",
        cardType: "Valuation",
        score: stockData?.valueScore,
      },
      {
        key: "HealthCard",
        cardType: "Health",
        score: stockData?.healthScore,
      },
      {
        key: "GrowthCard",
        cardType: "Growth",
        score: stockData?.growthScore,
      },
      {
        key: "ProfitabilityCard",
        cardType: "Profitability",
        score: stockData?.profitabilityScore,
      },
      {
        key: "ShareholderCard",
        cardType: "Investor Friendliness",
        score: stockData?.retributionScore,
      },
    ];

    const sortedArr = valuesArr.sort((a, b) => (a.score > b.score ? -1 : 1));
    const filteredArr = sortedArr.filter(
      (val: { score: number }, index: number) => {
        return val.score >= 40 && val.score < 60;
      },
    );

    setSequence(filteredArr);
  }, [stockData]);

  useEffect(() => {
    handleSequenceOfCards();
  }, [handleSequenceOfCards]);

  if (sequence && sequence.length === 0) {
    return null;
  }

  return (
    <Box w={"100%"} mb="60px">
      <Text
        fontSize="24px"
        fontWeight={600}
        lineHeight="36px"
        fontFamily="Poppins"
        mb={5}
        ms={1}
      >
        <FormattedMessage id="neutral_title" />
        <Text
          fontSize="16px"
          fontWeight={300}
          lineHeight="24px"
          fontFamily="Poppins"
        >
          <FormattedMessage
            id="neutral_subtext"
            defaultMessage={`The following factors make ${stockData?.companyName} more likely to perform in line with the market.`}
            values={{ name: stockData && stockData?.companyName }}
          />
        </Text>
      </Text>

      {sequence &&
        sequence.length > 0 &&
        sequence.map((value: SequenceList, index: number) => {
          return (
            <Box
              w="100%"
              display="flex"
              justifyContent="center"
              mb={3}
              key={index}
              id={`${value?.key}`}
            >
              <CardAccordian
                cardName={value.cardType}
                statusRating={value.score}
              >
                {value?.key === "HealthCard" ? (
                  <HealthChartCard />
                ) : value?.key === "GrowthCard" ? (
                  <GrowthChartCard />
                ) : value?.key === "TransparencyCard" ? (
                  <TransparencyCard fullTicker={fullTicker} />
                ) : value?.key === "ProfitabilityCard" ? (
                  <ProfitabilityChartCard />
                ) : value?.key === "ShareholderCard" ? (
                  <ShareholderChartCard />
                ) : value?.key === "ValuationCard" ? (
                  <ValuationChartCard />
                ) : (
                  value?.key === "MomentumCard" && (
                    <MomentumCard fullTicker={fullTicker} />
                  )
                )}
                <Box mt={2}></Box>
              </CardAccordian>
            </Box>
          );
        })}
    </Box>
  );
};

export default NewNeutralSection;
