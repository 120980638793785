import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useScaleCompanyRatio } from "../../../hooks/useData";
import {
  SupportedChartOption,
  useDataChart,
} from "../../../hooks/useDataChart";
import { actualStock, openActiveChart } from "../../../store/slices/stock";
import CompanyMetaRow from "../../../subviews/RightSubview/Stocks/CompanyMetaRow";
import { IStock } from "../../../types/Stock";
import {
  handleGrowthBadgeValueDifference,
  handleTitleSubtext,
} from "../../../utils/formatChartNumber";
import GrowthBadge from "../../common/badges/GrowthBadge";
import { EntryBoxChart } from "../EntryChart/Box";
import GenericChart from "../GenericChartScores/GenericChart";
import "./MonkScoreChartCard.css";

interface ProfitabilityScoreCardProps {
  stockData: IStock;
  fullTicker: string;
  companyName: string;
  score: number;
}

const ratioDict = {
  ROIC: "returnOnInvestedCapital",
  ROE: "returnOnEquity",
  ROA: "returnOnAssets",
  "GP/Assets": "grossProfitToAssets",
};

const ProfitabilityChartCard = () => {
  const stock = useSelector(actualStock);
  const dispatch = useDispatch();

  const { data, getChartSanitizedData: getChartProfitability } = useDataChart(
    SupportedChartOption.profitabilityScoreTimeline,
  );

  const onClickChart = (name: string) => {
    setTimeout(() => {
      dispatch(
        openActiveChart({
          type: "profitability",
          active: name,
        }) as any,
      );
    }, 100);
  };

  const sign = "pts";

  const oneYearMetric = () => {
    if (data) {
      const profitability = getChartProfitability();
      return Math.round(
        profitability.length > 0
          ? profitability[profitability.length - 1].percentile
          : 0,
      );
    }
    return 0;
  };

  const oneYearMetricDifference = useMemo(() => {
    if (data) {
      const profitability = getChartProfitability();
      return handleGrowthBadgeValueDifference(profitability, "percentile");
    }
    return 0;
  }, [data, getChartProfitability]);

  const lastUpdate = new Date(stock?.lastScoreUpdateDate || Date.now())
    ?.toLocaleDateString()
    ?.replaceAll("/", "-")
    ?.split("-")
    .reverse()
    .join("-");

  const { chartData } = useScaleCompanyRatio();

  const intl = useIntl();

  return (
    <Box
      w="100%"
      h="auto"
      m="auto"
      display={"flex"}
      flexDirection={"column"}
      mb="10px"
    >
      <Text as={"p"} fontSize={"14px"} lineHeight={"21px"} fontWeight={700}>
        <FormattedMessage
          id="factors_generic"
          values={{
            name: stock?.companyName ?? "",
            quality: handleTitleSubtext(oneYearMetric())?.toLowerCase(),
            card: "profitability",
          }}
        />{" "}
        <Text as="span" fontSize={"14px"} lineHeight={"21px"} fontWeight={400}>
          compared to its peers.
        </Text>
      </Text>

      <Box bgColor="#F9FAFB" id="ProfitabilityScoreCard">
        <Box
          position="relative"
          my={"10px"}
          backgroundColor={"#FFF"}
          borderRadius={"20px"}
          pt="20px"
          pb="2px"
          display="flex"
          flexDirection="column"
          gap="20px"
        >
          <Flex flexDirection={"column"} gap="20px" px="20px">
            <Flex flexDirection={"column"}>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap="10px"
              >
                <Text
                  as={"p"}
                  fontSize={"24px"}
                  lineHeight={"36px"}
                  fontWeight={500}
                  display={"flex"}
                  alignItems={"baseline"}
                >
                  {Math.round(oneYearMetric())}
                  <Text as={"p"} fontSize={"12px"}>
                    /100
                  </Text>
                </Text>

                <Button
                  width={"auto"}
                  fontSize={"14px"}
                  lineHeight={"21px"}
                  fontWeight={400}
                  display={"flex"}
                  alignItems={"center"}
                  variant={"unstyled"}
                  p="0"
                  height={"auto"}
                  justifyContent={"flex-end"}
                  flexWrap={"wrap"}
                  wordBreak={"break-all"}
                  textDecoration="none"
                  columnGap={"4px"}
                >
                  <Text display={"flex"} alignItems={"center"}>
                    <FormattedMessage
                      id="evolution"
                      values={{
                        name: "Profitability",
                      }}
                    />{" "}
                  </Text>
                </Button>
              </Flex>

              <Box mb="25px">
                <GrowthBadge
                  vector={oneYearMetricDifference > 0 ? "up" : "down"}
                  meta={`${
                    oneYearMetricDifference > 0 ? "+" : ""
                  }${oneYearMetricDifference.toFixed(2)}${sign} last 1 year`}
                />
              </Box>
            </Flex>

            <Box>
              <GenericChart data={getChartProfitability()} />
            </Box>
          </Flex>

          <Box px="10px">
            {stock && (
              <CompanyMetaRow
                compData={stock}
                lastUpdate={lastUpdate}
                idElementForScreenshot={`ProfitabilityScoreCard`}
                isChart
                styledBox
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box w="100%" my="10px">
        <Text
          id="message-container"
          as={"p"}
          fontSize={"14px"}
          lineHeight={"21px"}
        >
          <FormattedMessage id="factor_below_prefix" />
          <FormattedMessage
            id="factor_metric_company"
            values={{
              name: stock?.companyName ?? "",
            }}
          >
            {(txt) => <span className="highlight">{txt}</span>}
          </FormattedMessage>
          <FormattedMessage
            id="factor_subfix"
            values={{
              metric_name: intl.formatMessage({ id: "profitability_name" }),
            }}
          />
        </Text>
      </Box>

      <Box bgColor="#F9FAFB" id="ProfitabilityCardRatios">
        <Box
          my={"10px"}
          backgroundColor={"#FFF"}
          borderRadius={"20px"}
          pt="20px"
          pb="2px"
          display={"flex"}
          flexDirection={"column"}
        >
          <Box px="20px">
            <Text
              my={"20px"}
              fontWeight={600}
              fontSize={"16px"}
              lineHeight={"24px"}
            >
              Marging Percentiles
            </Text>
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="Gross Margin"
              data={chartData("Gross Margin")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="Operating Margin"
              data={chartData("Operating Margin")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="Net Profit Margin"
              data={chartData("Net Profit Margin")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="FCF Margin"
              data={chartData("FCF Margin")}
            />

            <Text
              my={"20px"}
              fontWeight={600}
              fontSize={"16px"}
              lineHeight={"24px"}
            >
              Return Percentiles
            </Text>
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="ROIC"
              data={chartData("ROIC")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="ROE"
              data={chartData("ROE")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="ROA"
              data={chartData("ROA")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="ROCE"
              data={chartData("ROCE")}
            />
          </Box>

          <Box px="10px">
            {stock && (
              <CompanyMetaRow
                compData={stock}
                lastUpdate={lastUpdate}
                idElementForScreenshot={`ProfitabilityCardRatios`}
                isChart
                styledBox
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfitabilityChartCard;
