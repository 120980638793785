import { Box, Text } from "@chakra-ui/react";

import { IStock } from "../../../../types/Stock";

function CompanyHeader({ compData }: { compData: IStock }) {
  return (
    <Box w="100%">
      <Text fontSize="24px" fontWeight="600" lineHeight="36px" textAlign="left">
        <Text display={{ base: "none", md: "inline-block" }}>
          {compData?.companyName}’s&nbsp;
        </Text>
        MonkScore
        <sup style={{ fontSize: "14px" }}>®</sup>
      </Text>

      <Text fontSize="16px" fontWeight="300" lineHeight="24px" textAlign="left">
        How likely is {compData?.companyName} to beat the market?
      </Text>
    </Box>
  );
}

export default CompanyHeader;
