import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, Divider, Text, VStack } from "@chakra-ui/react";
import React from "react";

interface SubscriptionData {
  createMemberDate: string;
  formattedSubscriptionPlan: string;
  subscriptionEndDate?: string;
}
interface SubscriptionDetailsProps {
  onChangePlan: () => void;
  onManagePaymentMethod: () => void;
  onViewPaymentHistory: () => void;
  onCancelMembership: () => void;
  subscriptionData?: SubscriptionData;
}

/**
 * SubscriptionDetails component displays the user's subscription details
 * and provides options to manage their plan, payment method, view payment history,
 * or cancel their membership.
 *
 * @param {Object} props - Component props.
 * @param {function} props.onChangePlan - Callback function triggered when the "Change plan" button is clicked.
 * @param {function} props.onManagePaymentMethod - Callback function triggered when the "Manage payment method" button is clicked.
 * @param {function} props.onViewPaymentHistory - Callback function triggered when the "View payment history" button is clicked.
 * @param {function} props.onCancelMembership - Callback function triggered when the "Cancel Membership" button is clicked.
 * @returns {JSX.Element} The rendered SubscriptionDetails component.
 */
const SubscriptionDetails: React.FC<SubscriptionDetailsProps> = ({
  onChangePlan,
  onManagePaymentMethod,
  onViewPaymentHistory,
  onCancelMembership,
  subscriptionData,
}) => {
  /**
   * Renders a button with the provided label and onClick handler.
   *
   * @param {string} label - The text to display on the button.
   * @param {function} onClick - The function to call when the button is clicked.
   * @returns {JSX.Element} The rendered button with a divider below it.
   */
  const renderButton = (label: string, onClick: () => void) => (
    <>
      <Button
        variant="link"
        onClick={onClick}
        justifyContent="space-between"
        textAlign="left"
        color="black"
        rightIcon={<ChevronRightIcon />}
        width="100%"
        _hover={{ textDecoration: "none" }}
      >
        {label}
      </Button>
      <Divider />
    </>
  );

  const formatData = (dateString: string) => {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const month = date.toLocaleString("en-US", { month: "long" });
    const year = date.getUTCFullYear();

    return `${day} ${month} ${year}`;
  };

  return (
    <Box
      maxWidth="400px"
      borderWidth="1px"
      borderRadius="lg"
      p={6}
      pt={12}
      position="relative"
    >
      <Box
        bg="blue.600"
        color="white"
        p={2}
        my={2}
        borderRadius="md"
        borderLeftRadius={0}
        position="absolute"
        left={0}
        top={0}
      >
        <Text fontSize="sm">
          Member since{" "}
          {subscriptionData ? subscriptionData.createMemberDate : "N/A"}
        </Text>
        {/* Todo with Stripe */}
      </Box>
      <VStack spacing={4} align="stretch">
        <Text fontWeight="bold" fontSize="xl">
          {/* Todo with Stripe */}
          Plan:{" "}
          {subscriptionData
            ? subscriptionData.formattedSubscriptionPlan
            : "N/A"}
          {/* Todo with Stripe */}
        </Text>
        <Text>
          Next payment:{" "}
          {subscriptionData?.subscriptionEndDate
            ? formatData(subscriptionData.subscriptionEndDate)
            : "N/A"}
        </Text>
        {renderButton("Change plan", onChangePlan)}
        {renderButton("Manage payment method", onManagePaymentMethod)}
        {renderButton("View payment history", onViewPaymentHistory)}
        <Button
          colorScheme="red"
          variant="outline"
          onClick={onCancelMembership}
          color="#8a4542"
        >
          Cancel Membership
        </Button>
      </VStack>
    </Box>
  );
};

export default SubscriptionDetails;
