import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useScaleCompanyRatio } from "../../../hooks/useData";
import {
  SupportedChartOption,
  useDataChart,
} from "../../../hooks/useDataChart";
import { actualStock, openActiveChart } from "../../../store/slices/stock";
import CompanyMetaRow from "../../../subviews/RightSubview/Stocks/CompanyMetaRow";
import {
  handleGrowthBadgeValueDifference,
  handleTitleSubtext,
} from "../../../utils/formatChartNumber";
import GrowthBadge from "../../common/badges/GrowthBadge";
import { EntryBoxChart } from "../EntryChart/Box";
import GenericChart from "../GenericChartScores/GenericChart";
import "./MonkScoreChartCard.css";

const GrowthChartCard = () => {
  const stock = useSelector(actualStock);

  const { data, getChartSanitizedData: getChartGrowth } = useDataChart(
    SupportedChartOption.growtScoreTimeline,
  );

  const oneYearMetric = () => {
    if (data) {
      const growt = getChartGrowth();
      return Math.round(growt.length > 0 ? growt[growt.length - 1].value : 0);
    }
    return 0;
  };

  const oneYearMetricDifference = useMemo(() => {
    if (data) {
      const growt = getChartGrowth();
      return handleGrowthBadgeValueDifference(growt, "value");
    }
    return 0;
  }, [data, getChartGrowth]);

  const dispatch = useDispatch();

  const onClickChart = (name: string) => {
    setTimeout(() => {
      dispatch(
        openActiveChart({
          type: "growth",
          active: name,
        }) as any,
      );
    }, 100);
  };

  const sign = "pts";

  const lastUpdate = new Date(stock?.lastScoreUpdateDate || Date.now())
    ?.toLocaleDateString()
    ?.replaceAll("/", "-")
    ?.split("-")
    .reverse()
    .join("-");

  const { chartData } = useScaleCompanyRatio();

  const intl = useIntl();

  return (
    <Box w="100%" h="auto" m="auto" display={"flex"} flexDirection={"column"}>
      <Text as={"p"} fontSize={"14px"} lineHeight={"21px"} fontWeight={700}>
        <FormattedMessage
          id="factors_generic"
          values={{
            name: stock?.companyName ?? "",
            quality: handleTitleSubtext(oneYearMetric())?.toLowerCase(),
            card: "growth",
          }}
        />{" "}
        <Text as="span" fontSize={"14px"} lineHeight={"21px"} fontWeight={400}>
          compared to its peers.
        </Text>
      </Text>

      <Box bgColor="#F9FAFB" id="GrowthScoreCard">
        <Box
          position="relative"
          my={"10px"}
          backgroundColor={"#FFF"}
          borderRadius={"20px"}
          pt="20px"
          pb="2px"
          display="flex"
          flexDirection="column"
          gap="20px"
        >
          <Flex flexDirection={"column"} gap="20px" px="20px">
            <Flex flexDirection={"column"}>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap="10px"
              >
                <Text
                  as={"p"}
                  fontSize={"24px"}
                  lineHeight={"36px"}
                  fontWeight={500}
                  display={"flex"}
                  alignItems={"baseline"}
                >
                  {Math.round(oneYearMetric())}
                  <Text as={"p"} fontSize={"12px"}>
                    /100
                  </Text>
                </Text>

                <Button
                  width={"auto"}
                  fontSize={"14px"}
                  lineHeight={"21px"}
                  fontWeight={400}
                  display={"flex"}
                  alignItems={"center"}
                  variant={"unstyled"}
                  p="0"
                  height={"auto"}
                  justifyContent={"flex-end"}
                  flexWrap={"wrap"}
                  wordBreak={"break-all"}
                  textDecoration="none"
                  columnGap={"4px"}
                >
                  <Text display={"flex"} alignItems={"center"}>
                    <FormattedMessage
                      id="evolution"
                      values={{
                        name: "Growth",
                      }}
                    />{" "}
                  </Text>
                </Button>
              </Flex>

              <Box mb="20px">
                <GrowthBadge
                  vector={oneYearMetricDifference > 0 ? "up" : "down"}
                  meta={`${
                    oneYearMetricDifference > 0 ? "+" : ""
                  }${oneYearMetricDifference.toFixed(2)}${sign} last 1 year`}
                />
              </Box>
            </Flex>

            <Box>
              <GenericChart data={getChartGrowth()} />
            </Box>
          </Flex>

          <Box px="10px">
            {stock && (
              <CompanyMetaRow
                compData={stock}
                lastUpdate={lastUpdate}
                idElementForScreenshot={`GrowthScoreCard`}
                isChart
                styledBox
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box w="100%" my="10px">
        <Text
          id="message-container"
          as={"p"}
          fontSize={"14px"}
          lineHeight={"21px"}
        >
          <FormattedMessage id="factor_below_prefix" />
          <FormattedMessage
            id="factor_metric_company"
            values={{
              name: stock?.companyName ?? "",
            }}
          >
            {(txt) => <span className="highlight">{txt}</span>}
          </FormattedMessage>
          <FormattedMessage
            id="factor_subfix"
            values={{
              metric_name: intl.formatMessage({ id: "growth_name" }),
            }}
          />
        </Text>
      </Box>

      <Box bgColor="#F9FAFB" id="GrowthCardRatios">
        <Box
          my={"10px"}
          backgroundColor={"#FFF"}
          borderRadius={"20px"}
          pt="20px"
          pb="2px"
          display={"flex"}
          flexDirection={"column"}
        >
          <Box px="20px">
            <Text
              my={"10px"}
              fontWeight={600}
              fontSize={"16px"}
              lineHeight={"24px"}
            >
              <FormattedMessage id="growth_magnitude_percentiles" />
            </Text>
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="Revenue Growth"
              data={chartData("Revenue Growth")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="Oper. Income Growth"
              data={chartData("Operating Income Growth")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="Oper. Cash Flow Growth"
              data={chartData("Operating Cash Flow Growth")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="Cap. Exp. Growth"
              data={chartData("Capital Expenditures Growth")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="Free Cash Flow Growth"
              data={chartData("Free Cash Flow Growth")}
            />
          </Box>

          <Box px="20px">
            <Text
              my={"10px"}
              fontWeight={600}
              fontSize={"16px"}
              lineHeight={"24px"}
            >
              <FormattedMessage id="growth_quality_percentiles" />
            </Text>
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="Growth Trend"
              data={chartData("Growth Trend")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="Growth Predictability"
              data={chartData("Growth Predictability")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="Growth Sustainability"
              data={chartData("Growth Sustainability")}
            />
            <EntryBoxChart
              onClick={onClickChart}
              useValue
              name="Momentum"
              data={chartData("Momentum")}
            />
          </Box>

          <Box px="10px">
            {stock && (
              <CompanyMetaRow
                compData={stock}
                lastUpdate={lastUpdate}
                idElementForScreenshot={`GrowthCardRatios`}
                isChart
                styledBox
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GrowthChartCard;
