import dayjs from "dayjs";
import { useIntl } from "react-intl";
import { ScoreTimeline } from "../types/Chart";

export function FormatChartNumber({ payload }: { payload: number }) {
  const intl = useIntl();
  if (Math.abs(payload) < 10) {
    return intl.formatNumber(payload, {
      notation: "compact",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }
  return intl.formatNumber(payload, {
    notation: "compact",
    maximumSignificantDigits: 3,
    minimumSignificantDigits: 3,
    maximumFractionDigits: 2,
    minimumFractionDigits: 1,
  });
}

export const handleTitleSubtext = (value: number) => {
  if (value < 20) {
    return "Very Poor";
  } else if (value >= 20 && value < 40) {
    return "Poor";
  } else if (value >= 40 && value < 65) {
    return "Good";
  } else if (value >= 65 && value < 90) {
    return "Very Good";
  } else if (value >= 90) {
    return "Excellent";
  }
};

export const handleMonkScoreTitleSubtext = (value: number) => {
  if (value < 20) {
    return "very unlikely to beat market";
  } else if (value >= 20 && value < 40) {
    return "unlikely to beat market";
  } else if (value >= 40 && value < 65) {
    return "somewhat likely to beat market";
  } else if (value >= 65 && value < 90) {
    return "very likely to beat market";
  } else if (value >= 90) {
    return "extremely likely to beat market";
  }
};

export const handleValuationTitleSubtext = (value: number) => {
  if (value >= 90) {
    return "valuation is very cheap";
  } else if (value >= 65 && value < 90) {
    return "valuation is cheap";
  } else if (value >= 40 && value < 65) {
    return "valuation is reasonably priced";
  } else if (value >= 20 && value < 40) {
    return "valuation is expensive";
  } else {
    return "valuation is very expensive";
  }
};
export const handleShareholderTitleSubText = (value: number) => {
  if (value >= 90) {
    return "provides exceptional capital returns via dividends and buybacks";
  } else if (value >= 65 && value < 90) {
    return "provides strong capital returns via dividends and buybacks";
  } else if (value >= 40 && value < 65) {
    return "provides moderate capital returns via dividends and buybacks";
  } else if (value >= 20 && value < 40) {
    return "provides limited capital returns via dividends and buybacks";
  } else {
    return "provides minimal capital returns via dividends and buybacks";
  }
};

export const handleGrowthBadgeValueDifference = (
  scores: ScoreTimeline[],
  keyValue: string,
) => {
  if (scores.length === 0) {
    return 0;
  }
  let i = scores.length - 1;
  let indexOfValueChange = 0;
  const startDateCompare = dayjs(scores[i].processedAsOfDate);

  while (i > 0) {
    const monthDifference = startDateCompare.diff(
      dayjs(scores[i].processedAsOfDate),
      "month",
    );
    if (monthDifference > 11) {
      indexOfValueChange = i;
      break;
    }
    i--;
  }
  return (
    scores[indexOfValueChange][keyValue] - scores[scores.length - 1][keyValue]
  );
};
