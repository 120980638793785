// TODO: refactor to config
export const monkScoreToColor = (monkScore: number) => {
  return monkScore >= 90
    ? "#357A01"
    : monkScore >= 70
      ? "#58CC02"
      : monkScore >= 50
        ? "#9BE067"
        : monkScore >= 20
          ? "#FFC800"
          : "#FF4B4B";
};

export const monkScoreToText = (monkScore: number) => {
  return monkScore >= 90
    ? "EXCELLENT"
    : monkScore >= 70
      ? "VERY GOOD"
      : monkScore >= 50
        ? "GOOD"
        : monkScore >= 20
          ? "MEDIOCRE"
          : "BAD";
};

export const MONK_SCORE_ENUM = {
  EXCELLENT: ["very likely", "beat the market"],
  "VERY GOOD": ["likely", "beat the market"],
  GOOD: ["likely", "behave in line with the market"],
  MEDIOCRE: ["likely", "underperform the market"],
  BAD: ["very likely", "underperform the market"],
};

// SCALES GUIDE:
// - 80-100 EXCELLENT: very likely to beat the market
// - 60-80 VERY GOOD: likely to beat the market
// - 40-60 GOOD: likely to behave in line with the market
// - 20-40 MEDIOCRE: likely to underperform the market
// - 0-20 BAD: very likely to underperform the market
