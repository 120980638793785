import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, InputGroup } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useClickOutsideWithBuffer } from "../../../hooks/useClicks";
import { FilterMenu } from "../menu/FilterMenu";

interface TextPropertyInputProps {
  propertyName: string;
  onChangeProperty: (property: string) => void;
}
export function TextPropertyInput(props: TextPropertyInputProps) {
  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef();
  useClickOutsideWithBuffer(ref, 50, () => setShowMenu(false));
  /*useOutsideClick({
        ref: ref as any,
        handler: () => setShowMenu(false),
      })*/
  useEffect(() => {
    return () => {
      setShowMenu(false);
    };
  }, []);

  return (
    <>
      <InputGroup
        w="max-content"
        h="40px"
        cursor={"pointer"}
        px={0}
        sx={{
          "&::after": {
            content: `''`,
            position: "absolute",
            top: "50%",
            right: 0,
            width: "15px",
            height: "1px",
            transform: "translateX(100%)",
            background: "gray.500",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#fff",
            border: "1px solid #E9EAEB",
            borderRadius: "5px",
            padding: "0 10px",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              width: "max-content",
              maxWidth: "max-content",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "20px",
              color: "#085096",
            }}
          >
            {props.propertyName}
          </Box>
          <ChevronDownIcon
            w={6}
            h={5}
            zIndex={500}
            cursor={"pointer"}
            m={0}
            p={0}
            color="blue.300"
            onClick={(e) => {
              e.preventDefault();
              setShowMenu(true);
            }}
          />
        </Box>
      </InputGroup>

      <>
        {showMenu && (
          <Box
            ref={ref as any}
            mt={60}
            mr={10}
            w="auto"
            maxW="220px"
            position={"absolute"}
            zIndex={1000}
            borderRadius={"8px"}
          >
            <FilterMenu
              onChangeMenu={(property: string) => {
                props.onChangeProperty(property);
                setShowMenu(false);
              }}
            />
          </Box>
        )}
      </>
    </>
  );
}
