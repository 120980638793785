import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { createContext, FC, ReactNode, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import partyPopper from "../../assets/image/party-popper.png";
import googleIcon from "../../assets/svg/google_icon.svg";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import SigninSignupModal from "../Auth/SigninSignupModal";
import TermConditionModal from "../Auth/Term&conditionModal";

export interface PaywallModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface PaywallModalContextType {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

export const PaywallModalContext = createContext<PaywallModalContextType>({
  isOpen: false,
  openModal: () => {},
  closeModal: () => {},
});

export const PaywallModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <PaywallModalContext.Provider value={{ isOpen, openModal, closeModal }}>
      {children}
    </PaywallModalContext.Provider>
  );
};

export const PaywallModal: FC<PaywallModalProps> = ({
  isOpen: isPaywallModalOpen,
  onClose: onClosePaywallModal,
}) => {
  const [signinClicked, setSigninClicked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [accessToken, setAccessToken] = useLocalStorage("accessToken", "");
  const [openTCModal, setOpenTCModal] = useState(false);
  const [userInfo, setUserInfo] = useState({ status: 0, data: { email: "" } });
  const [TCChecked, setTCChecked] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [modalAccessMethod, setModalAccessMethod] = useState("");
  const [userMessage, setUserMessage] = useState({ msg: "", color: "" });

  const handleSignin = (cmd) => {
    setSigninClicked(cmd);
    setModalAccessMethod("signin");
    setShowForm(false);
    setOpenModal(true);
  };

  const handleSignupWithCredentials = () => {
    setSigninClicked(false);
    setModalAccessMethod("signup");
    setShowForm(true);
    setOpenModal(true);
  };

  const handleGoogleSignup = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInforesp = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        },
      );
      setModalAccessMethod("handleGoogleSignup");
      setUserInfo(userInforesp);
      setOpenTCModal(!TCChecked && true);
    },
  });

  const handleGoogleSignupCheck = async () => {
    if (userInfo?.status === 200 && TCChecked) {
      setTCChecked(false);
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/checkUserExist`,
        { email: userInfo?.data?.email },
      );
      if (
        response?.status === 200 &&
        response?.data?.message ===
          "You already have an existing account - USING_EMAIL "
      ) {
        setUserMessage({
          msg: "You already have an existing account - USING_EMAIL",
          color: "red",
        });
        setSigninClicked(true);
        setUserMessage({
          msg: "You already have an existing account - please signin using Email & Password",
          color: "red",
        });
      } else if (
        response?.status === 200 &&
        response?.data?.message ===
          "You already have an existing account - GOOGLE "
      ) {
        setShowForm(false);
        setSigninClicked(true);
        setUserMessage({
          msg: "You already have an existing account - please signin using GOOGLE",
          color: "red",
        });
      } else if (
        response?.status === 200 &&
        response?.data?.message === "Account does not exist"
      ) {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/signUp`,
          {
            email: userInfo?.data?.email,
            socialId: "GOOGLE",
            flag: "GOOGLE",
          },
        );
        if (
          response?.status === 200 &&
          response?.data?.message === "signup successfully"
        ) {
          const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/signUp`,
            {
              email: userInfo?.data?.email,
              socialId: "GOOGLE",
              flag: "GOOGLE",
            },
          );
          if (response?.status === 200) {
            setUserMessage({
              msg: "Signup successfully please login",
              color: "green",
            });
            setShowForm(false);
            setSigninClicked(false);
          } else {
            console.log(response);
          }
        } else {
          console.log(response);
        }
      }
    }
  };

  const fetchLoginStatus = (status) => {
    status && setAccessToken(status || "");
  };

  useEffect(() => {
    if (accessToken) setOpenModal(false);
  }, [accessToken]);

  useEffect(() => {
    if (TCChecked && modalAccessMethod === "handleGoogleSignup") {
      handleGoogleSignupCheck();
    }
  }, [TCChecked]);

  return (
    <>
      <Modal
        isOpen={isPaywallModalOpen}
        onClose={onClosePaywallModal}
        size="md"
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay sx={{ background: "#3a83c9a8" }} />
        <ModalContent bg="#F9FaFB" borderRadius="20px" my={140} mx={5} py={4}>
          <ModalHeader
            px="6"
            pt="6"
            pb="4"
            display="flex"
            alignItems="center"
            w="full"
          >
            <img
              style={{ margin: "0 auto", width: 100, height: 100 }}
              src={partyPopper}
              alt="Tada"
            />
          </ModalHeader>
          <ModalBody
            px="10"
            fontFamily="Poppins"
            fontSize="sm"
            lineHeight="24px"
            pb="0"
            pt="0"
          >
            <Text
              fontFamily="Poppins"
              fontStyle="normal"
              fontWeight="500"
              fontSize="18px"
              lineHeight="24px"
              textAlign="center"
              color="#021425"
            >
              <FormattedMessage
                id="paywall_title"
                values={{
                  span: (chunks: ReactNode) => (
                    <Box as="span" fontWeight="700">
                      {chunks}
                    </Box>
                  ),
                }}
              />
            </Text>

            <Text
              fontFamily="Poppins"
              fontStyle="normal"
              fontWeight="400"
              fontSize="16px"
              lineHeight="24px"
              textAlign="center"
              color="#021425"
              mt="5"
            >
              <FormattedMessage id="paywall_line1" />
            </Text>

            <Text
              fontFamily="Poppins"
              fontWeight="500"
              fontSize="16px"
              lineHeight="24px"
              textAlign="center"
              color="#021425"
              mt="6"
            >
              <FormattedMessage id="paywall_line2" />
            </Text>
          </ModalBody>

          <ModalFooter
            display="flex"
            flexDir="column"
            pt={0}
            gap={"20px"}
            mt={0}
          >
            <Text
              fontSize="14px"
              fontFamily="Poppins"
              lineHeight="20px"
              textAlign="center"
              fontWeight={400}
              color={userMessage?.color}
              mb={2}
            >
              {userMessage?.msg}
            </Text>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontWeight={500}
              fontSize="18px"
              lineHeight="27px"
              py="15px"
              px="20px"
              borderRadius="10px"
              backgroundColor="white"
              boxShadow="0px 2px 3px rgba(0, 0, 0, 0.25)"
              cursor="pointer"
              onClick={() => handleGoogleSignup()}
            >
              <img src={googleIcon} alt="Google" />
              <Text ms={4} fontFamily="Poppins">
                <FormattedMessage id="continueWithGoogle" />
              </Text>
            </Box>

            <Box
              display={"flex"}
              height={"24px"}
              alignItems="center"
              justifyContent="center"
              gap={"4px"}
              mt={0}
            >
              <Text
                fontSize="16px"
                fontWeight={400}
                lineHeight="24px"
                textAlign="center"
                fontFamily="Poppins"
                color="#4D5461"
                cursor="pointer"
                mt={0}
              >
                <FormattedMessage id={"or"} />
              </Text>
              <Text
                fontSize="16px"
                fontWeight={400}
                lineHeight="24px"
                textAlign="center"
                fontFamily="Poppins"
                color="#0A64BC"
                cursor="pointer"
                onClick={() => handleSignupWithCredentials()}
              >
                <FormattedMessage id={"signupWithEmailPasswordPaywall"} />
              </Text>
            </Box>
            <Box
              display={"flex"}
              height={"24px"}
              alignItems="center"
              justifyContent="center"
              gap={"4px"}
              mt={0}
            >
              <Text
                fontSize="16px"
                fontWeight={400}
                lineHeight="24px"
                textAlign="center"
                fontFamily="Poppins"
                color="#4D5461"
                cursor="pointer"
                mt={0}
              >
                <FormattedMessage id={"alreadyHavingAccount"} />
              </Text>
              <Text
                fontSize="16px"
                fontWeight={500}
                lineHeight="24px"
                textAlign="center"
                fontFamily="Poppins"
                color="#0A64BC"
                cursor="pointer"
                onClick={() => handleSignin(true)}
                mt={0}
              >
                <FormattedMessage id={"signinWithEmailBtn"} />
              </Text>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <SigninSignupModal
        key={modalAccessMethod}
        openModal={openModal}
        signinClicked={signinClicked}
        setOpenModal={setOpenModal}
        setSigninClicked={setSigninClicked}
        fetchLoginStatus={fetchLoginStatus}
        onClose={onClosePaywallModal}
        directSignupWithCredentials={showForm}
      />
      <TermConditionModal
        TCChecked={TCChecked}
        setTCChecked={setTCChecked}
        isOpen={openTCModal}
        setIsOpen={setOpenTCModal}
      />
    </>
  );
};
