import { Box, Flex, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { CommonButton } from "../common/buttons/common";
import { TextEditable } from "../common/Text/TextEditable";
import { AddCompanyModal } from "../Modals/AddCompany";
import { DeleteWatchlistModal } from "../Modals/DeleteWatchlist";
import { NoneCompany } from "./None";
interface Props {
  name: string;
  editMode?: boolean;
  onClickAddCompany?: () => void;
  onClickEditCompany?: () => void;
  onClickSaveButton?: () => void;
  onSaveName?: (newValue: string) => void;
  is: "watchlist" | "portfolio";
  onDelete?: () => void;
  renderEmpty?: boolean;
}
export function ActionsWatchList({
  name,
  editMode = false,
  is,
  onClickAddCompany,
  onClickEditCompany,
  onClickSaveButton,
  onSaveName,
  onDelete,
  renderEmpty = false,
}: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenAddCompany,
    onClose: onCloseAddCompany,
    onOpen: onOpenAddCompany,
  } = useDisclosure();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  return (
    <>
      {renderEmpty && !editMode ? (
        <NoneCompany
          onClick={() => {
            onOpenAddCompany();
            onClickAddCompany && onClickAddCompany();
          }}
        />
      ) : (
        <Flex
          w="max-content"
          flexDirection="column"
          alignItems="start"
          backgroundColor="#FFF"
          flexGrow="2"
          fontFamily={"Poppins"}
          mt={{ base: "-10px", md: "0px" }}
          ml={{ md: "-20px" }}
        >
          <TextEditable
            small={!isLargerThan768}
            defaultValue={name}
            onChange={(nextValue) => {
              onSaveName && onSaveName(nextValue);
            }}
            onDelete={() => {
              onOpen();
            }}
          ></TextEditable>
          <Flex
            w="100%"
            flexDirection={{ base: "column", md: "row" }}
            mt={{ base: "10px", md: "10px" }}
            ml={{ base: "10px", md: "20px" }}
          >
            <CommonButton
              title="Add a company +"
              customProps={{
                onClick: () => {
                  onOpenAddCompany();
                  onClickAddCompany && onClickAddCompany();
                },
                width: "200px !important",
                height: "44px !important",
                borderRadius: "15px !important",
                padding: "10px, 20px, 10px, 20px",
                gap: "10px",
                isDisabled: editMode,
              }}
              textProps={{
                fontSize: "12px",
                fontWeight: "600",
                lineHeight: "24px",
              }}
            />
            <Box w="15px" mb="20px" />
            {editMode ? (
              <CommonButton
                lightMonkButton
                title="Save!"
                customProps={{
                  onClick: () => {
                    onClickSaveButton && onClickSaveButton();
                  },
                  width: "200px !important",
                  height: "44px !important",
                  borderRadius: "15px !important",
                  padding: "10px, 20px, 10px, 20px",
                  gap: "10px",
                }}
                textProps={{
                  fontSize: "12px",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
              />
            ) : (
              <CommonButton
                lightMonkButton
                title="Edit companies"
                customProps={{
                  onClick: () => {
                    onClickEditCompany && onClickEditCompany();
                  },
                  width: "200px !important",
                  height: "44px !important",
                  borderRadius: "15px !important",
                  padding: "10px, 20px, 10px, 20px",
                  gap: "10px",
                }}
                textProps={{
                  fontSize: "12px",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
              />
            )}
          </Flex>
        </Flex>
      )}
      <DeleteWatchlistModal
        is={is}
        isOpen={isOpen}
        onClose={onClose}
        name={name}
        onDelete={() => {
          onDelete && onDelete();
          onClose();
        }}
      />
      <AddCompanyModal
        is={is}
        isOpen={isOpenAddCompany}
        onClose={onCloseAddCompany}
        onSaveSuccess={() => {}}
      />
    </>
  );
}
