import { Button, Text } from "@chakra-ui/react";
import { ReactComponent as ArrowIcon } from "../../../../assets/svg/arrow-outward.svg";
import { ReactComponent as OutlineInfoIcon } from "../../../../assets/svg/outline-info.svg";
import { ICompanyRatios } from "../../../../types/Chart";
import { IStock } from "../../../../types/Stock";
import { FormatChartNumber } from "../../../../utils/formatChartNumber";
import {
  formatNumberToDecimalPlaces,
  prepareMarketCapMark,
  prepareMarketCapSym,
  prepareMarketCapValue,
  prepareVolatilityMark,
} from "../../../../utils/Math";
import styles from "./styles.module.css";

export interface ICompanyData {
  id: number;
  name: string;
  slug: string;
  withIcon: boolean;
  value: string;
  currency: string;
  infoType: "size" | "growth";
  meta: string;
  vector: null | "up" | "down";
}

interface IProps {
  itemData: ICompanyData;
  compData: IStock;
  companyRatios: ICompanyRatios[] | undefined;
  onClick: (chart: string) => void;
}

const getGrowth = (nums: number[]) => {
  const firstYear = nums[0];
  const lastYear = nums[4] || 0;
  if (lastYear === 0) return 0;
  return Number(
    formatNumberToDecimalPlaces(
      ((firstYear - lastYear) * 100) / Math.abs(lastYear),
    ),
  );
};

function CompanyDataItem({
  itemData,
  compData,
  onClick,
  companyRatios,
}: IProps) {
  const getValue = () => {
    if (!companyRatios) return 0;

    switch (itemData?.slug) {
      case "revenue":
        return (
          prepareMarketCapValue(companyRatios[0].RevenueLTM || 0) +
          prepareMarketCapSym(companyRatios[0].RevenueLTM || 0)
        );

      case "mktCapUSD":
        return (
          prepareMarketCapValue(compData?.[itemData?.slug]) +
          prepareMarketCapSym(compData?.[itemData?.slug])
        );

      case "volatility":
        return formatNumberToDecimalPlaces(compData.beta);

      case "operating_income":
        return (
          prepareMarketCapValue(companyRatios[0].OperatingIncomeLTM || 0) +
          prepareMarketCapSym(companyRatios[0].OperatingIncomeLTM || 0)
        );

      case "net_income":
        return (
          prepareMarketCapValue(companyRatios[0].NetIncomeLTM || 0) +
          prepareMarketCapSym(companyRatios[0].NetIncomeLTM || 0)
        );

      case "free_cash_flow":
        return (
          prepareMarketCapValue(companyRatios[0].FCFLTM || 0) +
          prepareMarketCapSym(companyRatios[0].FCFLTM || 0)
        );

      default:
        return 0;
    }
  };

  const getMark = () => {
    if (!companyRatios) return 0;
    switch (itemData?.slug) {
      case "revenue":
        return getGrowth(companyRatios.map((val) => val.RevenueLTM || 0));

      case "mktCapUSD":
        return prepareMarketCapMark(compData?.[itemData?.slug]);

      case "volatility":
        return prepareVolatilityMark(compData.beta);

      case "operating_income":
        return getGrowth(
          companyRatios.map((val) => val.OperatingIncomeLTM || 0),
        );

      case "net_income":
        return getGrowth(companyRatios.map((val) => val.NetIncomeLTM || 0));

      case "free_cash_flow":
        return getGrowth(companyRatios.map((val) => val.FCFLTM || 0));
      default:
        return "";
    }
  };

  const getCurrency = () => {
    if (!companyRatios) return "USD";

    return companyRatios[0].reportedCurrency;
  };

  return (
    <li className={styles.itemWrapper}>
      <Text
        fontSize={"12px"}
        fontWeight={"300"}
        lineHeight={"12px"}
        width={"-webkit-fit-content"}
        color={"#A9AAAB"}
        display="flex"
        alignItems={"center"}
        height={"24px"}
      >
        {itemData?.name}&nbsp;
        {itemData?.withIcon && (
          <Button
            onClick={() => onClick(itemData?.name)}
            variant="ghost"
            padding={0}
            w={"max-content"}
            minW={"unset"}
            minH={"unset"}
            h={"unset"}
          >
            <OutlineInfoIcon style={{ color: "#0564B8" }} />
          </Button>
        )}
      </Text>

      <Text
        fontSize={"18px"}
        fontWeight={"500"}
        lineHeight={"18px"}
        display="flex"
        alignItems={"flex-end"}
        height={"18px"}
      >
        {itemData?.slug && getValue()}
        &nbsp;
        {itemData?.currency && (
          <Text fontSize={"9px"} fontWeight={"500"} lineHeight={"12px"}>
            {/* {itemData?.currency} */}
            {itemData?.currency === "NAN" ? getCurrency() : compData?.currency}
          </Text>
        )}
      </Text>

      {itemData?.infoType === "size" ? (
        <Text
          borderRadius={"999px"}
          px="5px"
          py="2px"
          backgroundColor={"#0564B833"} //#58CC02
          fontSize={"10px"}
          fontWeight={"300"}
          lineHeight={"12px"}
          color={"#0564B8"}
          width={"max-content"}
          display="flex"
          alignItems={"center"}
        >
          <OutlineInfoIcon />
          &nbsp;{getMark()}&nbsp;
        </Text>
      ) : (
        <Text
          borderRadius={"999px"}
          px="4px"
          py="2px"
          backgroundColor={
            !isNaN(Number(getMark())) && Number(getMark()) > 0
              ? "#58CC0233"
              : "#FF4B4B33"
          }
          color={
            !isNaN(Number(getMark())) && Number(getMark()) > 0
              ? "#58CC02"
              : "#FF4B4B"
          }
          fontSize={"10px"}
          fontWeight={"300"}
          lineHeight={"10px"}
          width={"-webkit-fit-content"}
          display="flex"
          alignItems={"center"}
        >
          {!isNaN(Number(getMark())) && Number(getMark()) > 0 ? (
            <ArrowIcon />
          ) : (
            <ArrowIcon style={{ transform: "rotateX(180deg)" }} />
          )}
          &nbsp;{FormatChartNumber({ payload: Number(getMark()) })}% last 1
          year&nbsp;
        </Text>
      )}
    </li>
  );
}

export default CompanyDataItem;
