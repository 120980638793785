import { Box, Flex, Text } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import {
  actualRatios,
  actualRecentCompanyRatios,
  actualStock,
} from "../../../store/slices/stock";
import CompanyMetaRow from "../../../subviews/RightSubview/Stocks/CompanyMetaRow";
import { ICompanyRatios } from "../../../types/Chart";
import { roundNumber } from "../../../utils/Math";
import { ratioDictSign } from "../../../utils/ratios";
import GrowthBadge from "../../common/badges/GrowthBadge";
import CandlestickChart from "../CandlestickChart/CandleStickChart";
import TooltipModal from "../tooltipModal/tooltipModal";

interface HealthCardProps {
  activeChart: string;
}

const getDataByYear = (
  data: ICompanyRatios[] | undefined,
  year: number,
  key:
    | "netDebtToEquity"
    | "interestCoverage"
    | "externalFinancingRatio"
    | "probabilityOfDefault"
    | "quickRatio"
    | "currentRatio",
) => {
  if (!data || data.length < year * 4) return 0;
  return data
    .slice(4 * year, 4 + 4 * year)
    .reduce((prev, curr) => prev + (curr[key] || 0), 0);
};

const getBarsData = (
  data: ICompanyRatios[] | undefined,
  key:
    | "netDebtToEquity"
    | "interestCoverage"
    | "externalFinancingRatio"
    | "probabilityOfDefault"
    | "quickRatio"
    | "currentRatio",
) => {
  if (!data) return [0, 0, 0];
  return [
    ((getDataByYear(data, 0, key) - getDataByYear(data, 1, key)) * 100) /
      Math.abs(getDataByYear(data, 1, key)),
    ((getDataByYear(data, 1, key) - getDataByYear(data, 2, key)) * 100) /
      Math.abs(getDataByYear(data, 2, key)),
    ((getDataByYear(data, 2, key) - getDataByYear(data, 3, key)) * 100) /
      Math.abs(getDataByYear(data, 3, key)),
  ];
};

const shortNameDict = {
  "Net Debt to Equity": "netDebtToEquity",
  "Interest Coverage": "interestCoverage",
  "External Financing Ratio": "externalFinancingRatio",
  "Probability of Default": "probabilityOfDefault",
  "Quick Ratio": "quickRatio",
  "Current Ratio": "currentRatio",
  "Cash Conversion Cycle": "cashConversionCycle",
  "Asset Growth": "assetGrowth",
  "Percent Accruals": "percentAccruals",
};

const HealthCard = ({ activeChart }: HealthCardProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const companyRatios = useSelector(actualRecentCompanyRatios);

  const mostRecentRatio = useSelector(actualRatios);
  const stock = useSelector(actualStock);

  const lastUpdate = new Date(stock?.lastScoreUpdateDate || Date.now())
    ?.toLocaleDateString()
    ?.replaceAll("/", "-")
    ?.split("-")
    .reverse()
    .join("-");

  const getThisYearValue = (activeChart) => {
    if (companyRatios && companyRatios.length > 0) {
      switch (activeChart) {
        case "Net Debt to Equity": {
          if (companyRatios[0].netDebtToEquity) {
            return companyRatios[0].netDebtToEquity;
          }
          return 0;
        }
        case "Interest Coverage": {
          if (companyRatios[0].interestCoverage) {
            return companyRatios[0].interestCoverage;
          }
          return 0;
        }
        case "External Financing Ratio": {
          if (companyRatios[0].externalFinancingRatio) {
            return companyRatios[0].externalFinancingRatio;
          }
          return 0;
        }
        case "Probability of Default": {
          if (companyRatios[0].probabilityOfDefault) {
            return companyRatios[0].probabilityOfDefault;
          }
          return 0;
        }
        case "Quick Ratio": {
          if (companyRatios[0].quickRatio) {
            return companyRatios[0].quickRatio;
          }
          return 0;
        }
        case "Current Ratio": {
          if (companyRatios[0].currentRatio) {
            return companyRatios[0].currentRatio;
          }
          return 0;
        }
        case "Cash Conversion Cycle": {
          if (companyRatios[0].cashConversionCycle) {
            return companyRatios[0].cashConversionCycle;
          }
          return 0;
        }
        case "Asset Growth": {
          if (companyRatios[0].assetGrowth) {
            return companyRatios[0].assetGrowth;
          }
          return 0;
        }
        case "Percent Accruals": {
          if (companyRatios[0].percentAccruals) {
            return companyRatios[0].percentAccruals;
          }
          return 0;
        }
      }
    }

    return 0;
  };

  const oneYearMetric = useCallback(() => {
    const value = getBarsData(companyRatios, shortNameDict[activeChart])[0];
    return Number.isNaN(value) ? 0 : value;
  }, [activeChart, companyRatios]);

  const getChartData = useCallback(() => {
    if (companyRatios) {
      const ratioData =
        mostRecentRatio == null
          ? companyRatios
          : [mostRecentRatio, ...companyRatios];
      return ratioData
        .map((val, i) => ({
          time: i,
          period: val.period,
          value: val[shortNameDict[activeChart as string]] || 0,
          yearQ: `${val.calendarYear}`,
          date: new Date(i === 0 ? val.ratiosUpdateDate : val.statementsDate)
            ?.toLocaleDateString()
            ?.replaceAll("/", "-")
            ?.split("-")
            .reverse()
            .join("-"),
        }))
        .reverse();
    }
    return [];
  }, [activeChart, companyRatios, mostRecentRatio]);

  const sign = ratioDictSign[activeChart] ?? "%";

  return (
    <Box bgColor="#FFF" w="100%" h="100%">
      <Box
        position="relative"
        my={"10px"}
        backgroundColor={"#FFF"}
        borderRadius={"20px"}
        id="HealthCard"
      >
        <Flex
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          gap="10px"
          ml="10px"
        >
          <Text
            as={"p"}
            fontSize={"24px"}
            lineHeight={"36px"}
            fontWeight={500}
            display={"flex"}
            alignItems={"flex-end"}
          >
            {`${roundNumber(Number.parseFloat(getThisYearValue(activeChart).toString()))}`}
            <Text fontSize={"12px"} lineHeight={"28px"}>
              {sign}
            </Text>
          </Text>
        </Flex>

        <Box mb="25px" ml="10px">
          <GrowthBadge
            vector={oneYearMetric() > 0 ? "up" : "down"}
            meta={`${oneYearMetric() > 0 ? "+" : ""}${oneYearMetric()?.toFixed(
              2,
            )} ${sign === "%" ? "bps" : "%"} last 1 year`}
          />
        </Box>

        <Box>
          <CandlestickChart
            data={getChartData()}
            activeChart={activeChart}
            altColor={activeChart === "External Financing"}
          />
        </Box>

        <CompanyMetaRow
          compData={stock}
          lastUpdate={lastUpdate}
          idElementForScreenshot={`HealthCardRatios`}
          isChart
          styledBox
        />
      </Box>

      <TooltipModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        activeChart={activeChart}
      />
    </Box>
  );
};

export default HealthCard;
