import { Button, Text } from "@chakra-ui/react";
import { NewShareIcon } from "../../icons/ShareIcon";

function MonkStreetButton({
  lightMonkButton,
  onClick,
  isSafari,
}: {
  lightMonkButton: boolean;
  onClick?: () => void;
  isSafari?: boolean;
}) {
  return (
    <Button
      minWidth={"80px"}
      maxWidth={"80px"}
      h={"30px"}
      p={"10px"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      fontSize={"10px"}
      fontWeight={700}
      backgroundColor={lightMonkButton ? "#FFF" : "#085096"}
      color={lightMonkButton ? "#085096" : "#FFF"}
      colorScheme={lightMonkButton ? "gray" : "blue"}
      onClick={onClick}
    >
      {isSafari ? (
        <>
          <NewShareIcon />
          <Text ml="5px">SHARE</Text>
        </>
      ) : (
        <>
          <svg
            width="10"
            height="12"
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.52941 9.6C3.20588 9.6 2.92902 9.4826 2.69882 9.2478C2.46863 9.013 2.35333 8.7304 2.35294 8.4V1.2C2.35294 0.87 2.46823 0.5876 2.69882 0.3528C2.92941 0.118 3.20627 0.0004 3.52941 0H8.82353C9.14706 0 9.42412 0.1176 9.6547 0.3528C9.88529 0.588 10.0004 0.8704 10 1.2V8.4C10 8.73 9.8849 9.0126 9.6547 9.2478C9.42451 9.483 9.14745 9.6004 8.82353 9.6H3.52941ZM3.52941 8.4H8.82353V1.2H3.52941V8.4ZM1.17647 12C0.852941 12 0.576078 11.8826 0.345882 11.6478C0.115686 11.413 0.000392157 11.1304 0 10.8V2.4H1.17647V10.8H7.64706V12H1.17647Z"
              fill="currentColor"
            />
          </svg>
          <Text ml="5px">COPY</Text>
        </>
      )}
    </Button>
  );
}

export default MonkStreetButton;
