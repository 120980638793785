import { Box, Text } from "@chakra-ui/react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import TooltipModal from "../../../../components/Stocks/tooltipModal/tooltipModal";
import { useFetchCompanyRatiosQuery } from "../../../../services/chartApi";
import { IStock } from "../../../../types/Stock";
import CompanyDataItem, { ICompanyData } from "./CompanyDataItem";
import { data } from "./mockData";
import styles from "./styles.module.css";

function CompanyDataList({ compData }: { compData: IStock }) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeChart, setActiveChart] = useState("");
  const { data: companyRatios } = useFetchCompanyRatiosQuery(compData?.ticker);

  return (
    <>
      <Text
        fontSize="24px"
        fontWeight={600}
        lineHeight="36px"
        fontFamily="Poppins"
        mb={"30px"}
        display={{ base: "block", md: "none" }}
        px={{ base: "20px", md: "40px" }}
      >
        <FormattedMessage
          id="key_performance_title"
          defaultMessage={`Key Performance Indicators`}
        />
        <Text
          fontSize="16px"
          fontWeight={300}
          lineHeight="24px"
          fontFamily="Poppins"
        >
          <FormattedMessage
            id="positive_subtext"
            defaultMessage={`${compData?.companyName} basic facts at a glance:`}
            values={{
              name: compData?.companyName,
            }}
          />
        </Text>
      </Text>
      {/* <Box
        width={"100%"}
        overflowX={"scroll"}
        display={"flex"}
        gap={"10px"}
        scrollBehavior={"auto"}
      > */}
      <Box width={"100%"} pl={{ base: "20px", md: "40px" }}>
        <ul className={styles.list}>
          {data.map((item) => (
            <CompanyDataItem
              itemData={item as ICompanyData}
              compData={compData}
              companyRatios={companyRatios}
              key={item?.id}
              onClick={(chart: string) => {
                setActiveChart(chart);
                setIsOpen(true);
              }}
            />
          ))}
        </ul>
      </Box>
      {/* </Box> */}
      <TooltipModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        activeChart={activeChart}
      />
      {/* </Box> */}
    </>
  );
}

export default CompanyDataList;
