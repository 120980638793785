import {
  Box,
  chakra,
  Checkbox,
  CircularProgress,
  SkeletonCircle,
  SkeletonText,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ColumnDef, SortingFn } from "@tanstack/react-table";
import { useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DownloadIcon } from "../../../assets/svg/fi_download.svg";
import { ReactComponent as AddIcon } from "../../../assets/svg/fi_plus.svg";
import { ActionsScreener } from "../../../components/Box/ActionsScreener";
import { MonkBox, MonkBoxLigth } from "../../../components/Box/MonkBox";
import { ActiveButton } from "../../../components/common/buttons/active";
import {
  AddButton,
  CommonButton,
} from "../../../components/common/buttons/common";
import { FilterMenu } from "../../../components/common/menu/FilterMenu";
import { TextInput } from "../../../components/common/Text/TextInput";
import { Add2WatchlistModal } from "../../../components/Modals/Add2Watchlist";
import { FilterViewObject } from "../../../components/Screener/Filter";
import {
  CircleMonkScore,
  NewMonkScoreTable,
} from "../../../components/Stocks/NewMonkScore";
import { StockTableItem } from "../../../components/Stocks/StockItemTable";
import { MonkTable } from "../../../components/Table/MonkTable";
import { EmitterContext } from "../../../context/emiter";
import { usePromisedToast } from "../../../hooks/usePromisedToast";
import {
  useDeleteMutation,
  useUpdateMutation,
} from "../../../services/screenerApi";
import {
  actualScreener as storeScreener,
  addFilter,
  applyFilters,
  dataSkeleton as storeDataSkeleton,
  downloadFilters,
  error as storeError,
  fetchOne,
  filters as storeFilters,
  filterTableResult as storeTableResult,
  loading as storeLoading,
  removeFilter,
  selectedTickers as storeSelectedTickers,
  setSelection,
  total as storeTotal,
  updateFilter,
} from "../../../store/slices/screener";
import {
  fetchAllPortfolio,
  fetchAllWatchlist,
} from "../../../store/slices/watchlist";
import {
  buttonMediumProps,
  textMediumButtonProps,
  textSmallProps,
} from "../../../theme/theme";
import { Filter, ScreenerFilterResult } from "../../../types/Screener";
import { IStock } from "../../../types/Stock";
import { scrollToTop } from "../../../utils/navigator";
import { isValidFilters } from "../../../utils/select";

interface ScreenerOverviewProps {
  id: string | number;
  onEditName: () => void;
}
const ActiveButtonBox = chakra(ActiveButton);

export function ScreenerOverview({ id, onEditName }: ScreenerOverviewProps) {
  const emmiter = useContext(EmitterContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(storeLoading);
  const error = useSelector(storeError);
  const screener = useSelector(storeScreener);
  const filters = useSelector(storeFilters);
  const total = useSelector(storeTotal);
  const tableData = useSelector(storeTableResult);
  const tableDataSkeleton = useSelector(storeDataSkeleton);
  const tickers = useSelector(storeSelectedTickers);
  const intl = useIntl();
  const { wrapperPromise } = usePromisedToast();
  const { isOpen: isOpenWatchlist, onClose, onOpen } = useDisclosure();
  const [isWatchlist, setIsWatchlist] = useState(true);
  const [showBoxList, setShowBoxList] = useState<string[]>([]);
  const [fullListSelected, setFullListSelected] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [sort, setSort] = useState([]);
  const [update] = useUpdateMutation();

  const [mutate] = useDeleteMutation();

  const sortStatusFn: SortingFn<ScreenerFilterResult> = (
    rowA,
    rowB,
    _columnId,
  ) => {
    const nameA = rowA.original.CompanyProfile.companyName;
    const nameB = rowB.original.CompanyProfile.companyName;
    if (nameA < nameB) {
      return -1;
    }
    if (nameB < nameA) {
      return 1;
    }
    return 0;
  };

  const [isAddingAction, setIsAddingAction] = useState(false);
  const [queryFilterMenu, setQueryFilterMenu] = useState("");

  useEffect(() => {
    dispatch(
      applyFilters({
        filters: filters,
        page: pagination.page,
        pageSize: pagination.pageSize,
        isOr: !isAllSelected,
        sortingBy: sort,
      }) as any,
    );
  }, [sort]);

  useEffect(() => {
    emmiter.on("whenChangeFilter", async (filtersEvent: Array<Filter>) => {
      if (filtersEvent && isValidFilters(filtersEvent)) {
        dispatch(
          applyFilters({
            filters: filtersEvent,
            page: pagination.page,
            pageSize: pagination.pageSize,
            isOr: !isAllSelected,
            sortingBy: sort,
          }) as any,
        );
      }
    });
    emmiter.on("whenFilterRemoved", async (index: number) => {
      const temporalFilters = [...filters];
      temporalFilters.splice(index, 1);
      dispatch(
        applyFilters({
          filters: temporalFilters,
          page: pagination.page,
          pageSize: pagination.pageSize,
          isOr: !isAllSelected,
          sortingBy: sort,
        }) as any,
      );
    });
    return () => {
      emmiter.off("whenChangeFilter");
      emmiter.off("whenFilterRemoved");
    };
  }, [dispatch, emmiter, filters, pagination.page, pagination.pageSize]);

  const columns = useMemo<ColumnDef<ScreenerFilterResult, any>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <Box>
            <Checkbox
              {...{
                isChecked: table.getIsAllRowsSelected(),
                isIndeterminate: table.getIsSomeRowsSelected(),
                onChange: (event) => {
                  if (event.target.checked) {
                    setFullListSelected(true);
                    setShowBoxList(
                      table
                        .getGroupedSelectedRowModel()
                        .rows.map(
                          (item) => item.original.CompanyProfile.companyName,
                        ),
                    );
                  } else {
                    setFullListSelected(false);
                    setShowBoxList([]);
                  }
                  const handler = table.getToggleAllRowsSelectedHandler();
                  handler(event);
                },
              }}
              sx={{
                "& .chakra-checkbox__control": {
                  width: "20px",
                  height: "20px",
                  borderRadius: "6px",
                  border: "1px",
                  borderColor: "#C9CACB",
                  outline: "none",
                },
              }}
            />
          </Box>
        ),
        cell: ({ row }) => (
          <Box>
            <Checkbox
              {...{
                isChecked: row.getIsSelected(),
                isDisabled: !row.getCanSelect(),
                isIndeterminate: row.getIsSomeSelected(),
                onChange: (event) => {
                  const handler = row.getToggleSelectedHandler();
                  handler(event);
                  const selectedBox = (
                    row.getAllCells()[1].getValue() as IStock
                  ).companyName;
                  if (event.target.checked) {
                    setShowBoxList([
                      ...showBoxList.filter(
                        (item) => !item.includes(selectedBox),
                      ),
                      selectedBox,
                    ]);
                  } else {
                    setShowBoxList(
                      showBoxList.filter((item) => !item.includes(selectedBox)),
                    );
                  }
                },
              }}
              sx={{
                "& .chakra-checkbox__control": {
                  width: "20px",
                  height: "20px",
                  borderRadius: "6px",
                  border: "1px",
                  borderColor: "#C9CACB",
                  outline: "none",
                },
              }}
            />
          </Box>
        ),
      },
      {
        accessorKey: "CompanyProfile",
        id: "CompanyProfile.companyName",
        cell: (info) => (
          <StockTableItem
            companyName={info.getValue().companyName}
            ticker={info.getValue().ticker}
            country={info.getValue().country}
          />
        ),
        sortUndefined: "last", //force undefined values to the end
        sortDescFirst: true,
        header: (props) => (
          <Text
            cursor={"pointer"}
            fontWeight={700}
            fontSize={"12px"}
            lineHeight={"15px"}
            fontFamily={"Poppins"}
            color="#333333"
          >
            Company
          </Text>
        ),
        sortingFn: sortStatusFn,
      },
      {
        accessorFn: (row) =>
          Math.round(row.mostRecentScore.monkScorePercentile),
        id: "mostRecentScore.monkScorePercentile",
        cell: (info) => <NewMonkScoreTable monkScore={info.getValue()} />,
        sortUndefined: "last", //force undefined values to the end
        sortDescFirst: true,
        header: () => (
          <Text
            cursor={"pointer"}
            fontWeight={700}
            fontSize={"12px"}
            lineHeight={"15px"}
            fontFamily={"Poppins"}
            color="#333333"
          >
            MonkScore®️
          </Text>
        ),
      },
      {
        accessorFn: (row) => Math.round(row.mostRecentScore.ValuePercentile),
        id: "mostRecentScore.ValuePercentile",
        cell: (info) => <CircleMonkScore monkScore={info.getValue()} />,
        sortUndefined: "last", //force undefined values to the end
        sortDescFirst: true,
        header: () => (
          <Text
            cursor={"pointer"}
            fontWeight={700}
            fontSize={"12px"}
            lineHeight={"15px"}
            fontFamily={"Poppins"}
            color="#333333"
          >
            Value
          </Text>
        ),
      },
      {
        accessorFn: (row) => Math.round(row.mostRecentScore.GrowthPercentile),
        id: "mostRecentScore.GrowthPercentile",
        cell: (info) => <CircleMonkScore monkScore={info.getValue()} />,
        sortUndefined: "last", //force undefined values to the end
        sortDescFirst: true,
        header: () => (
          <Text
            cursor={"pointer"}
            fontWeight={700}
            fontSize={"12px"}
            lineHeight={"15px"}
            fontFamily={"Poppins"}
            color="#333333"
          >
            Growth
          </Text>
        ),
      },
      {
        accessorFn: (row) =>
          Math.round(row.mostRecentScore.ProfitabilityPercentile),
        id: "mostRecentScore.ProfitabilityPercentile",
        cell: (info) => <CircleMonkScore monkScore={info.getValue()} />,
        sortUndefined: "last", //force undefined values to the end
        sortDescFirst: true,
        header: () => (
          <Text
            cursor={"pointer"}
            fontWeight={700}
            fontSize={"12px"}
            lineHeight={"15px"}
            fontFamily={"Poppins"}
            color="#333333"
          >
            Profitability
          </Text>
        ),
      },
      {
        accessorFn: (row) => Math.round(row.mostRecentScore.HealthPercentile),
        id: "mostRecentScore.HealthPercentile",
        cell: (info) => <CircleMonkScore monkScore={info.getValue()} />,
        sortUndefined: "last", //force undefined values to the end
        sortDescFirst: true,
        header: () => (
          <Text
            cursor={"pointer"}
            fontWeight={700}
            fontSize={"12px"}
            lineHeight={"15px"}
            fontFamily={"Poppins"}
            color="#333333"
          >
            Health
          </Text>
        ),
      },
      {
        accessorFn: (row) =>
          Math.round(row.mostRecentScore.InvestorFriendlinessPercentile),
        id: "mostRecentScore.InvestorFriendlinessPercentile",
        cell: (info) => <CircleMonkScore monkScore={info.getValue()} />,
        sortUndefined: "last", //force undefined values to the end
        sortDescFirst: true,
        header: () => (
          <Text
            cursor={"pointer"}
            fontWeight={700}
            fontSize={"12px"}
            lineHeight={"15px"}
            fontFamily={"Poppins"}
            color="#333333"
          >
            Investor Friendliness
          </Text>
        ),
      },
    ],
    [showBoxList, fullListSelected],
  );

  const columnsSkeleton = useMemo<ColumnDef<ScreenerFilterResult, any>[]>(
    () => [
      {
        accessorKey: "CompanyProfile",
        cell: (info) => (
          <Box padding="6" boxShadow="lg" bg="white">
            <SkeletonCircle size="20px" />
            <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="2" />
          </Box>
        ),
        header: (props) => (
          <Text
            fontWeight={700}
            fontSize={"12px"}
            lineHeight={"15px"}
            fontFamily={"Poppins"}
            color="#333333"
          >
            Company
          </Text>
        ),
      },
      {
        accessorFn: (row) => row.mostRecentScore.monkScoreValue,
        id: "monkScore",
        cell: (info) => (
          <CircularProgress
            size={"20px"}
            isIndeterminate={true}
            value={40}
            color="green.400"
          />
        ),
        header: () => (
          <Text
            fontWeight={700}
            fontSize={"12px"}
            lineHeight={"15px"}
            fontFamily={"Poppins"}
            color="#333333"
          >
            MonkScore
          </Text>
        ),
      },
      {
        accessorFn: (row) => row.mostRecentScore.ValueValue,
        id: "value",
        cell: (info) => (
          <CircularProgress
            size={"20px"}
            isIndeterminate={true}
            value={40}
            color="green.400"
          />
        ),
        header: () => (
          <Text
            fontWeight={700}
            fontSize={"12px"}
            lineHeight={"15px"}
            fontFamily={"Poppins"}
            color="#333333"
          >
            Value
          </Text>
        ),
      },
      {
        accessorFn: (row) => row.mostRecentScore.GrowthValue,
        id: "growthValue",
        cell: (info) => (
          <CircularProgress
            size={"20px"}
            isIndeterminate={true}
            value={40}
            color="green.400"
          />
        ),
        header: () => (
          <Text
            fontWeight={700}
            fontSize={"12px"}
            lineHeight={"15px"}
            fontFamily={"Poppins"}
            color="#333333"
          >
            Growth
          </Text>
        ),
      },
      {
        accessorFn: (row) => row.mostRecentScore.ProfitabilityValue,
        id: "ProfitabilityValue",
        cell: (info) => (
          <CircularProgress
            size={"20px"}
            isIndeterminate={true}
            value={40}
            color="green.400"
          />
        ),
        header: () => (
          <Text
            fontWeight={700}
            fontSize={"12px"}
            lineHeight={"15px"}
            fontFamily={"Poppins"}
            color="#333333"
          >
            Profitability
          </Text>
        ),
      },
      {
        accessorFn: (row) => row.mostRecentScore.HealthValue,
        id: "HealthValue",
        cell: (info) => (
          <CircularProgress
            size={"20px"}
            isIndeterminate={true}
            value={40}
            color="green.400"
          />
        ),
        header: () => (
          <Text
            fontWeight={700}
            fontSize={"12px"}
            lineHeight={"15px"}
            fontFamily={"Poppins"}
            color="#333333"
          >
            Health
          </Text>
        ),
      },
      {
        accessorFn: (row) => row.mostRecentScore.InvestorFriendlinessValue,
        id: "InvestorFriendlinessValue",
        cell: (info) => (
          <CircularProgress
            size={"20px"}
            isIndeterminate={true}
            value={40}
            color="green.400"
          />
        ),
        header: () => (
          <Text
            fontWeight={700}
            fontSize={"12px"}
            lineHeight={"15px"}
            fontFamily={"Poppins"}
            color="#333333"
          >
            Investor Friendliness
          </Text>
        ),
      },
    ],
    [],
  );

  const onSaveName = async (value: string) => {
    update({
      id: id as string,
      name: value,
    })
      .unwrap()
      .then(() => {
        onEditName();
      });
  };

  const onSaveChanges = async (value: string) => {
    update({
      id: id as string,
      name: value,
      filters: [...filters],
    })
      .unwrap()
      .then(() => {
        dispatch(fetchOne({ id }) as any);
      });
  };

  const onDelete = async () => {
    mutate({
      id: id as string,
    })
      .unwrap()
      .then(() => {
        onEditName();
        navigate(`/screener`, {
          state: {
            isDeleting: true,
          },
        });
      });
  };

  useEffect(() => {
    dispatch(fetchOne({ id }) as any);
    dispatch(fetchAllWatchlist({ is: "watchlist" }) as any);
    dispatch(fetchAllPortfolio() as any);
  }, [dispatch, id]);

  useEffect(() => {
    if (screener) {
      const temporalFilters = [...filters];
      dispatch(
        applyFilters({
          filters: temporalFilters,
          page: pagination.page,
          pageSize: pagination.pageSize,
          isOr: !isAllSelected,
          sortingBy: sort,
        }) as any,
      );
    }
  }, [dispatch, screener, pagination]);

  const download = () => {
    dispatch(
      downloadFilters({
        filters: screener.filters,
        limit: 100,
      }) as any,
    );
  };

  return (
    <Box w="100%" h="100%" mb={"15px"} mt={{ base: "0px", md: "30px" }}>
      {!loading && !error && (
        <ActionsScreener
          name={screener?.name ?? ""}
          onSaveName={onSaveName}
          onClickSaveButton={(value: string) => {
            wrapperPromise(onSaveChanges(value), {
              titleSuccess: intl.formatMessage({ id: "titleSave" }),
              titleError: intl.formatMessage({ id: "errorTitle" }),
              titlePending: intl.formatMessage({ id: "loadingTitle" }),
              descriptionSuccess: intl.formatMessage({
                id: "saveFilters",
              }),
              descriptionPending: intl.formatMessage({ id: "saveLoading" }),
              descriptionError: intl.formatMessage({ id: "errorDescription" }),
            });
          }}
          onDelete={() => {
            wrapperPromise(onDelete(), {
              titleSuccess: intl.formatMessage({ id: "titleDelete" }),
              titleError: intl.formatMessage({ id: "errorTitle" }),
              titlePending: intl.formatMessage({ id: "loadingTitle" }),
              descriptionSuccess: intl.formatMessage({
                id: "descriptionDelete",
              }),
              descriptionPending: intl.formatMessage({ id: "saveLoading" }),
              descriptionError: intl.formatMessage({ id: "errorDescription" }),
            });
          }}
        />
      )}
      <Box
        w="550px"
        h="56px"
        alignItems={"center"}
        display={"flex"}
        borderRadius={"20px !important"}
        padding={"10px !important"}
        gap={"15px !important"}
        bgColor={"#F9FAFB"}
      >
        <Text
          ml="20px"
          my={"18px"}
          {...textSmallProps}
          color={"rgba(0, 0, 0, 1)"}
        >
          Show me companies that match
        </Text>
        <ActiveButtonBox
          text="All"
          isActive={isAllSelected}
          mx={2}
          onActive={() => {
            setIsAllSelected(true);
            setTimeout(() => {
              const temporalFilters = [...filters];
              if (screener) {
                dispatch(
                  applyFilters({
                    filters: temporalFilters,
                    page: pagination.page,
                    pageSize: pagination.pageSize,
                    isOr: false,
                    sortingBy: sort,
                  }) as any,
                );
              }
            }, 1000);
          }}
        />
        <ActiveButtonBox
          text="Any"
          isActive={!isAllSelected}
          mx={2}
          onActive={() => {
            setIsAllSelected(false);
            setTimeout(() => {
              const temporalFilters = [...filters];
              if (screener) {
                dispatch(
                  applyFilters({
                    filters: temporalFilters,
                    page: pagination.page,
                    pageSize: pagination.pageSize,
                    isOr: true,
                    sortingBy: sort,
                  }) as any,
                );
              }
            }, 1000);
          }}
        />
        <Text my={"18px"} {...textSmallProps} color={"rgba(0, 0, 0, 1)"}>
          of the below criterion.
        </Text>
      </Box>

      {filters.map((f, index) => {
        return (
          <FilterViewObject
            onChangeFilterParams={(f) => {
              dispatch(updateFilter({ index, filter: f }) as any);
              const temporalFilters = [...filters];
              temporalFilters[index] = f;
              emmiter.emit("whenChangeFilter", [...temporalFilters]);
            }}
            filter={f}
            onRemove={() => {
              emmiter.emit("whenFilterRemoved", index);
              dispatch(removeFilter({ index }) as any);
            }}
          />
        );
      })}

      {!isAddingAction && <AddButton onClick={() => setIsAddingAction(true)} />}

      {isAddingAction && (
        <TextInput
          onChange={(v) => setQueryFilterMenu(v)}
          onClickClose={() => {
            setIsAddingAction(false);
            setQueryFilterMenu("");
          }}
          renderOverflow={(setShowMenu) => {
            return (
              <Box
                w="100%"
                mt={"-5px"}
                ml={2}
                position={"absolute"}
                zIndex={1000}
              >
                <FilterMenu
                  filterText={queryFilterMenu}
                  onChangeMenu={(property) => {
                    const stringProperties = [
                      "Sector",
                      "Industry",
                      "Country",
                      "Exchange",
                      "sector",
                      "industry",
                      "country",
                      "exchange",
                      "watchlist",
                      "portfolio",
                      "Watchlist",
                      "Portfolio",
                    ];
                    const isString = stringProperties.includes(property);
                    const newFilter: Filter = {
                      propertyName: property,
                      type: isString ? "string" : "number",
                      operator: isString ? "iof" : "eq",
                      value: 0,
                      amount: "million",
                    };
                    dispatch(addFilter(newFilter) as any);
                    setShowMenu(false);
                    setIsAddingAction(false);
                  }}
                />
              </Box>
            );
          }}
        ></TextInput>
      )}

      {(showBoxList.length > 0 || fullListSelected) && (
        <MonkBox>
          <CommonButton
            icon={<AddIcon color={"inherit"} width="20px" height="20px" />}
            title="Add to watchlist"
            lightMonkButton
            customProps={{
              ...buttonMediumProps,
              onClick: () => {
                setIsWatchlist(true);
                onOpen();
              },
            }}
            textProps={textMediumButtonProps}
          />
          <CommonButton
            icon={<AddIcon color={"inherit"} width="20px" height="20px" />}
            title="Add to portfolio"
            lightMonkButton
            customProps={{
              ...buttonMediumProps,
              onClick: () => {
                setIsWatchlist(false);
                onOpen();
              },
            }}
            textProps={textMediumButtonProps}
          />
        </MonkBox>
      )}
      <MonkTable
        page={pagination.page}
        onPaginate={({ pageIndex, pageSize }) => {
          setPagination({
            page: pageIndex <= 1 ? 1 : pageIndex - 1,
            pageSize,
          });
          scrollToTop();
        }}
        pageSize={pagination.pageSize}
        total={total}
        columns={loading ? columnsSkeleton : columns}
        data={loading ? tableDataSkeleton : tableData}
        onRowSelect={(selection) => {
          // console.log(selection);
          dispatch(setSelection(selection));
        }}
        filters={filters}
        onSort={(sort) => {
          // console.log(sort);
        }}
        sort={sort}
        setSort={setSort}
      >
        <MonkBoxLigth>
          <CommonButton
            icon={
              <Box w={"20px"} h={"20px"} mr={2}>
                <DownloadIcon color={"#FFFFFF"} />
              </Box>
            }
            title="Download in CSV"
            customProps={{ ...buttonMediumProps, onClick: download }}
            textProps={textMediumButtonProps}
          />
          <Text {...textSmallProps} color={"#C9CACB"}>
            Downlads the first 100 companies.
          </Text>
        </MonkBoxLigth>
      </MonkTable>
      {isOpenWatchlist && (
        <Add2WatchlistModal
          isOpen={isOpenWatchlist}
          is={isWatchlist ? "watchlist" : "portfolio"}
          onClose={onClose}
          tickers={tickers}
        ></Add2WatchlistModal>
      )}
    </Box>
  );
}
