import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Monkscore from "../../../assets/svg/monkScore.svg";

import { ReactComponent as OutlineInfoIcon } from "../../../assets/svg/outline-info.svg";
import { IStock } from "../../../types/Stock";

import useBreakpoints from "../../../hooks/useBreakpoints";
import CompanyMetaRow from "../../../subviews/RightSubview/Stocks/CompanyMetaRow";
import { monkScoreToText } from "../../../utils/monkScore";
import GrowthBadge from "../../common/badges/GrowthBadge";
import YearGrowthBadge from "../../common/badges/YearGrowthBadge";
import NewMonkScore from "../NewMonkScore";
import TooltipModal from "../tooltipModal/tooltipModal";
import { data } from "./mockData";
import { MonkScoreChart } from "./MonkScoreChart";
import "./styles.module.css";

interface MonkscoreCardProps {
  compData: IStock;
  performance: string;
  lastUpdate: string;
}

const tabs = [
  {
    id: 1,
    name: "evolution",
    title: "Evolution",
  },
  {
    id: 2,
    name: "composition",
    title: "Composition",
  },
  {
    id: 3,
    name: "industry",
    title: "Industry",
  },
];

const NewMonkScoreCard = ({
  compData,
  performance,
  lastUpdate,
}: MonkscoreCardProps) => {
  const { isDesktop } = useBreakpoints();
  const [index, setIndex] = useState(0);
  const [activeTabIdx, setActiveTabIdx] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [activeChart, setActiveChart] = useState<string>("FCF");

  useEffect(() => {
    setIndex(isDesktop ? 0 : 1);
  }, [isDesktop]);

  // temporary hidden section
  return null;

  return (
    <Box
      bgColor="#F9FAFB"
      className="parent_card_height"
      p="10px"
      mb="60px"
      width={"100%"}
    >
      <Accordion
        allowMultiple
        backgroundColor="#F9FAFB"
        borderRadius="20px"
        p={"10px"}
        width={"100%"}
        index={[index]}
      >
        <AccordionItem border="none">
          {({ isExpanded }) => (
            <>
              <h2 onClick={() => setIndex(index === 0 ? -1 : 0)}>
                <AccordionButton
                  display="flex"
                  justifyContent="space-between"
                  _hover={{ bgColor: "none" }}
                  p={0}
                >
                  <Flex w={"100%"}>
                    <img
                      src={Monkscore}
                      alt=""
                      style={{
                        borderRadius: "10%",
                        height: "48px",
                        width: "48px",
                      }}
                    />
                    <Box
                      pl="12px"
                      display="flex"
                      flexDir="column"
                      justifyContent="center"
                    >
                      <Text
                        fontSize="18px"
                        fontWeight="600"
                        lineHeight="26px"
                        textAlign="start"
                      >
                        MonkScore®
                      </Text>
                      {compData?.monkScore && (
                        <Text
                          fontSize="12px"
                          fontWeight="400"
                          lineHeight="20px"
                          textAlign={"left"}
                          textTransform={"capitalize"}
                        >
                          {monkScoreToText(compData?.monkScore)?.toLowerCase()}
                          {/* <FormattedMessage
                          id='monkscore_subTitle'
                          values={{ name: compData && compData.companyName }}
                        /> */}
                        </Text>
                      )}
                    </Box>
                    <Flex ml="auto" alignItems="center" columnGap={"10px"}>
                      <NewMonkScore monkScore={compData?.monkScore} thin />
                      {isExpanded ? (
                        <IoIosArrowUp fontSize="18px" color="#999A9B" />
                      ) : (
                        <IoIosArrowDown fontSize="18px" color="#999A9B" />
                      )}
                    </Flex>
                  </Flex>
                </AccordionButton>
              </h2>
              {isExpanded && (
                <Flex flexDir={"column"} gap={"20px"} width={"100%"}>
                  <Box mt={5} width="100%">
                    <Text>
                      {compData?.companyName}{" "}
                      <Text as={"span"} fontWeight={"600"}>
                        outperforms 94% of its competitors
                      </Text>
                      &nbsp; in terms of growth when measured using ratios most
                      predictive of future returns.
                    </Text>
                  </Box>

                  <Flex flexDir={"column"} gap={"10px"} width={"100%"}>
                    <Flex
                      justifyContent={"stretch"}
                      gap={"10px"}
                      p="6px"
                      bg={"#FFF"}
                    >
                      {tabs.map((tab, idx) => (
                        <Button
                          key={tab?.id}
                          colorScheme="white"
                          w={"100%"}
                          color={activeTabIdx === idx ? "#1C1C1C" : "#999A9B"}
                          backgroundColor={
                            activeTabIdx === idx ? "#F9FAFB" : "transparent"
                          }
                          fontSize={"12px"}
                          lineHeight={"18px"}
                          textAlign={"center"}
                          fontWeight={"400"}
                          onClick={() => setActiveTabIdx(idx)}
                        >
                          {tab?.title}
                        </Button>
                      ))}
                    </Flex>

                    {/* CHART */}
                    <Flex
                      flexDir={"column"}
                      p={"20px"}
                      borderRadius={"20px"}
                      backgroundColor={"#FFF"}
                      width={"100%"}
                    >
                      <Flex
                        justifyContent={"space-between"}
                        alignItems={"flex-start"}
                        gap="10px"
                      >
                        <Text
                          as={"p"}
                          fontSize={"24px"}
                          lineHeight={"36px"}
                          fontWeight={500}
                          display={"flex"}
                          alignItems={"flex-end"}
                        >
                          12.3bn
                          <Text fontSize={"12px"} lineHeight={"28px"}>
                            &nbsp;{compData?.currency}
                          </Text>
                        </Text>
                        <Button
                          width={"auto"}
                          fontSize={"14px"}
                          lineHeight={"21px"}
                          fontWeight={400}
                          display={"flex"}
                          alignItems={"center"}
                          variant={"unstyled"}
                          p="0"
                          height={"auto"}
                          justifyContent={"flex-end"}
                          flexWrap={"wrap"}
                          wordBreak={"break-all"}
                          textDecoration="underline"
                          textDecorationStyle={"dashed"}
                          textDecorationColor={"#c9cacb"}
                          textDecorationThickness={"from-font"}
                          textUnderlineOffset={"4px"}
                          columnGap={"4px"}
                          onClick={() => setIsOpen(true)}
                        >
                          <Text>MonkScore®</Text>

                          <Text display={"flex"} alignItems={"center"}>
                            {tabs[activeTabIdx]?.title}{" "}
                            <OutlineInfoIcon
                              style={{
                                textDecoration: "none",
                                marginLeft: "6px",
                              }}
                            />
                          </Text>
                        </Button>
                      </Flex>

                      <GrowthBadge vector="up" meta="+8.40% last 1 year" />

                      {/* chart here */}
                      <Box mb={"10px"} width={"100%"} height={"auto"}>
                        <MonkScoreChart />
                      </Box>

                      <Box
                        w={{ md: "300px", lg: "400px", xl: "100%" }}
                        // width={{
                        //   base: 'calc(100vw - 140px)',
                        //   md: '600px',
                        //   lg: '100%',
                        // }}
                        className="scrollable_box"
                      >
                        <Flex
                          as={"ul"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                          gap={"16px"}
                        >
                          {data?.map((item) => (
                            <YearGrowthBadge
                              key={item?.id}
                              year={item?.year}
                              value={item?.value}
                            />
                          ))}
                        </Flex>
                      </Box>
                    </Flex>

                    {/* ROW */}
                    <CompanyMetaRow
                      compData={compData}
                      lastUpdate={lastUpdate}
                      lightMonkButton
                    />
                  </Flex>
                </Flex>
              )}
            </>
          )}
        </AccordionItem>
      </Accordion>

      <TooltipModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        activeChart={activeChart}
      />
    </Box>
  );
};

export default NewMonkScoreCard;
