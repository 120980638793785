import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import {
  ChakraStylesConfig,
  Select,
  useChakraSelectProps,
} from "chakra-react-select";
import { motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { ReactComponent as PlusIcon } from "../../assets/svg/add.svg";
import {
  useAddCompaniesMutation,
  useFetchWatchlistDataQuery,
} from "../../services/watchlistPortfolioApi";
import { Watchlist } from "../../types/Watchlist";
import { containerVariants } from "../../utils/framerVariants";
import { CommonButton } from "../common/buttons/common";
import { CommonModal } from "./Common";
import { ContinueNavigation } from "./ContinueNavigation";

//is ticker is undefined allow multiple tickers
interface Props {
  companyName?: string;
  ticker?: string;
  isOpen: boolean;
  is: "watchlist" | "portfolio";
  onOpen?: () => void;
  onClose: () => void;
  tickers?: Array<string>;
}

export function Add2WatchlistModal({
  companyName,
  ticker,
  isOpen,
  onClose,
  is,
  tickers = [],
}: Props) {
  const {
    isFetching,
    isError,
    currentData = [],
    refetch,
  } = useFetchWatchlistDataQuery(is, {
    pollingInterval: 60000,
    refetchOnMountOrArgChange: true,
  });

  const [selectedOptions, setSelectedOptions] = useState<Watchlist | null>(
    null,
  );
  const [added, setAdded] = useState(false);
  const toast = useToast();

  const [options, setOptions] = useState<
    Array<{ label: string; value: Watchlist }>
  >([]);

  const selectProps = useChakraSelectProps({
    isMulti: false,
    value: selectedOptions,
    onChange: (newValue) => {
      console.log("On change,:", newValue);
      setSelectedOptions(newValue as Watchlist);
    },
    className: "customSelect",
  });

  const chakraStyles: ChakraStylesConfig = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      background: state.isFocused ? "blue.100" : "transparent",
    }),
    loadingIndicator: (provided, state) => ({
      ...provided,
    }),
  };

  const [addCompany, { isLoading: isAdding }] = useAddCompaniesMutation();

  const addedCompanyPromise = useCallback(async () => {
    try {
      console.log("On added company:", selectedOptions as any);
      await addCompany({
        id: (selectedOptions as any).value.id,
        isWatchlist: is === "watchlist",
        companyIds: [],
        tickers: ticker ? [ticker as string] : tickers,
      }).unwrap();
      setAdded(true);
      return Promise.resolve(true);
    } catch (error) {
      setAdded(false);
      return Promise.reject(true);
    }
  }, [addCompany, is, selectedOptions, ticker, tickers]);

  useEffect(() => {
    const options = currentData.map((w) => {
      return {
        label: w.name,
        value: w,
      };
    });
    setOptions([...options]);
  }, [currentData]);

  return (
    <CommonModal
      modalContentCustomProps={{
        height: { base: "auto", lg: "380px" },
        padding: { lg: "40px, 20px, 40px, 20px" },
        gap: "20px",
      }}
      isOpen={isOpen}
      onClose={onClose}
    >
      {added && (
        <ContinueNavigation
          companyName={(selectedOptions as any)?.label}
          is={is}
          id={(selectedOptions as any).value.id}
          name={(selectedOptions as any).value.name}
          onClose={onClose}
        />
      )}

      {!added && (
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          as={motion.div}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <Flex alignItems={"center"} flexDirection={"column"}>
            <PlusIcon />
            <Text
              fontSize={{ base: "14px", lg: "18px", xl: "18px" }}
              fontWeight={"600"}
              lineHeight={"27px"}
              align="center"
              fontFamily="Poppins"
              mb={2}
              mt={2}
              noOfLines={2}
            >
              {`Add ${companyName ? companyName : "multiples companies"} to one of your ${is}s `}
            </Text>
            <Text
              fontWeight="400"
              fontSize="14px"
              lineHeight="21px"
              fontFamily="Poppins"
              noOfLines={1}
              mb={4}
            >
              {`Choose the ${is} you want to add it to:`}
            </Text>
          </Flex>
          <Box width={"100%"} color="#085096">
            <Select
              {...selectProps}
              placeholder={`Choose a ${is}`}
              chakraStyles={chakraStyles as any}
              options={options as any}
            />
          </Box>
          <CommonButton
            title={`Add to my ${is}`}
            customProps={{
              isLoading: isAdding,
              mt: 2,
              width: "100%",
              onClick: () => {
                if (selectedOptions != null) {
                  toast.promise(addedCompanyPromise() as any, {
                    success: {
                      title: "Company added",
                      description: `Looks great you added ${companyName ? companyName : "many companies"}`,
                    },
                    error: {
                      title: ":) Upss!!!",
                      description: "Something wrong.",
                    },
                    loading: {
                      title: "Pending",
                      description: "Adding company",
                    },
                  });
                }
              },
            }}
          ></CommonButton>
        </Flex>
      )}
    </CommonModal>
  );
}
