import {
  Badge,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

export function PaymentHistory({ data }: any) {
  return (
    <TableContainer>
      <Table variant="simple">
        <TableCaption>Payment History</TableCaption>
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Type</Th>
            <Th>Payment Method</Th>
            <Th isNumeric>Amount</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item: any, key: any) => (
            <Tr key={key}>
              <Td>{item.date}</Td>
              <Td>{item.type}</Td>
              <Td>{item.paymentMethod}</Td>
              <Td isNumeric>${item.amount}</Td>
              <Td>
                <Badge
                  colorScheme={
                    item.status === "Completed"
                      ? "green"
                      : item.status === "Pending"
                        ? "yellow"
                        : "red"
                  }
                >
                  {item.status}
                </Badge>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
