import { Skeleton, Stack } from "@chakra-ui/react";

export function ButtonsSkeleton() {
  return (
    <Stack>
      {[1, 2, 3].map((v, i) => {
        return <Skeleton width={"200px"} height={"50px"} key={i}></Skeleton>;
      })}
    </Stack>
  );
}
