export const billingPlans = {
  pro: {
    title: "Pro",
    name: "pro",
    price: {
      monthlyStandard: 108,
      monthlyDiscounted: 54,
      yearlyStandard: 72,
      yearlyDiscounted: 36,
      withMonthlyDiscount: true,
      withYearlyDiscount: true,
      yearlyTotal: 432,
    },
    summary: "For top investors who don’t want to miss any opportunity.",
    optionsList: [
      "Unlimited analysis",
      "Unlimited companies in portfolio & watchlist, with alerts",
      "Science-based fundamental screener",
      "Priority support",
    ],
    bestValue: false,
  },
  advanced: {
    title: "Advanced",
    name: "advanced",
    price: {
      monthlyStandard: 36,
      monthlyDiscounted: 18,
      yearlyStandard: 24,
      yearlyDiscounted: 12,
      withMonthlyDiscount: true,
      withYearlyDiscount: true,
      yearlyTotal: 144,
    },
    summary:
      "For serious investors who want to build and maintain a market beating portfolio of exceptional companies.",
    optionsList: [
      "500 analysis per month",
      "100 companies in portfolio, with alerts",
      "100 companies in watchlist, with alerts",
      "Science-based fundamental screener",
    ],
    bestValue: true,
  },
  basic: {
    title: "Basic",
    name: "basic",
    price: {
      monthlyStandard: 24,
      monthlyDiscounted: 16,
      yearlyStandard: 16,
      yearlyDiscounted: 8,
      withMonthlyDiscount: true,
      withYearlyDiscount: true,
      yearlyTotal: 96,
    },
    summary: "For new investors who want to start a market beating portfolio.",
    optionsList: [
      "50 analysis per month",
      "10 companies in portfolio, with alerts",
    ],
    bestValue: false,
  },
  free: {
    title: "Free",
    name: "free",
    price: {
      monthlyStandard: 0,
      monthlyDiscounted: 0,
      yearlyStandard: 0,
      yearlyDiscounted: 0,
      monthlyDiscount: null,
      withMonthlyDiscount: false,
      withYearlyDiscount: false,
      yearlyTotal: null,
    },
    summary: "For casual users who want to check a few companies.",
    optionsList: ["5 analysis per month"],
    bestValue: false,
  },
};
