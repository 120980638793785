import {
  Box,
  Grid,
  GridItem,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { useFetchPeerStockDataQuery } from "../../services/stockApi";
import { IStock } from "../../types/Stock";
import { NewPhoneStockEditable } from "./NewStockItem/NewPhoneStockEditable";
import { NewStockWithEditable } from "./NewStockItem/NewStockWithEditable";

interface AlternativeSectionProps {
  stockData: IStock;
}

const AlternativeSection = ({ stockData }: AlternativeSectionProps) => {
  const { data: alternatives } = useFetchPeerStockDataQuery(stockData?.symbol);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  return (
    <Box w={"100%"} mb="60px">
      <Text
        fontSize="24px"
        fontWeight={600}
        lineHeight="36px"
        fontFamily="Poppins"
        mb={4}
        ms={1}
      >
        <FormattedMessage
          id="alternative_title"
          defaultMessage="Alternatives"
        />
        <Text
          fontSize="16px"
          fontWeight={300}
          lineHeight="24px"
          fontFamily="Poppins"
        >
          <FormattedMessage
            id="alternative_subtext"
            defaultMessage={`These are some excellent alternatives to ${stockData?.companyName}`}
            values={{ name: stockData?.companyName }}
          />
        </Text>
      </Text>

      {isLargerThan768 ? (
        <Grid
          templateColumns={{
            base: "repeat(1,1fr)",
            md: "repeat(1,1fr)",
            lg: "repeat(3, 1fr)",
          }}
          mt="15px"
          w={"100%"}
          h={"max-content"}
          flexWrap={"wrap"}
          gap="10px"
          overflowY={"auto"}
          alignItems={{ base: "center", md: "stretch" }}
          overflow={"hidden"}
          className="customGrid"
        >
          {alternatives?.map((stock, i) => {
            return (
              <GridItem
                key={i}
                style={{ height: "160px" }}
                width={"100%"}
                h="160px"
              >
                <NewStockWithEditable
                  stock={stock}
                  width="100%"
                  editMode={false}
                />
              </GridItem>
            );
          })}
        </Grid>
      ) : (
        <Stack
          mt={2}
          spacing={2}
          display={{ base: "block", md: "none" }}
          as={"div"}
        >
          {alternatives?.map((stock, i) => (
            <Box key={i} style={{ width: "100%" }}>
              <NewPhoneStockEditable stock={stock} editMode={false} />
            </Box>
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default AlternativeSection;
