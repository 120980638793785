import { Box, Flex, Text } from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { LogoDark, LogoWhite } from "../../../../components/Logo";
import NewCompanyBasicCard from "../../../../components/Stocks/CompanyCard/NewCompanyBasicCard";
import MonkStreetButton from "../../../../components/common/buttons/MonkStreetButton";
import { useScreenShots, useSharedDom } from "../../../../hooks/useScreenShots";
import { IStock } from "../../../../types/Stock";
import { iOSSafari } from "../../../../utils/navigator";

function CompanyMetaRow({
  compData,
  lastUpdate,
  lightMonkButton = false,
  idElementForScreenshot,
  isChart,
  styledBox,
}: {
  compData: IStock;
  lastUpdate: string;
  lightMonkButton?: boolean;
  idElementForScreenshot?: string;
  isChart?: boolean;
  styledBox?: boolean;
}) {
  const [showLogo, setShowLogo] = useState<boolean>(false);
  const { getScreenShot } = useScreenShots(
    idElementForScreenshot || "stock-ref-card",
    isChart,
  );
  const { share } = useSharedDom(
    idElementForScreenshot || "stock-ref-card",
    isChart,
  );
  const [isSafari, _] = useState<boolean>(iOSSafari(navigator.userAgent));

  function onClickCopyButton() {
    setShowLogo(true);
    setTimeout(() => {
      if (isSafari) {
        share();
      } else {
        getScreenShot();
      }
      cleanUpCopy();
    }, 500);
  }

  function cleanUpCopy() {
    setTimeout(() => {
      setShowLogo(false);
    }, 4000);
  }

  useEffect(() => {
    if (showLogo) {
    }
  }, [showLogo]);

  const boxStyle = {
    //mb: "10px",
    px: 4,
    py: 3,
    borderRadius: "20px",
    backgroundColor: "#F9FAFB",
  };

  const boxStyleFn = styledBox ? boxStyle : {};

  return (
    <Box {...boxStyleFn}>
      <Flex gap="0px" alignItems="center" justifyContent={"space-between"}>
        <NewCompanyBasicCard compData={compData} small />
        <Flex
          gap={"2px"}
          flexWrap={"wrap"}
          alignItems={"center"}
          justifyContent={"center"}
          // maxW={'70px'}
          h={"24px"}
        >
          <Text
            fontWeight="500"
            textAlign={{ base: "center", md: "center" }}
            fontSize="8px"
            lineHeight="12px"
            color="#999A9B"
            letterSpacing="-2%"
            w={"max-content"}
          >
            Last updated:
          </Text>

          <Text
            fontWeight="500"
            textAlign={{ base: "center", md: "center" }}
            fontSize="8px"
            lineHeight="12px"
            color="#999A9B"
            letterSpacing="-2%"
            w={"max-content"}
            // h={'10px'}
          >
            {lastUpdate}
          </Text>
        </Flex>
        {showLogo ? (
          <Box color="#6ca2d6">
            {lightMonkButton ? <LogoWhite /> : <LogoDark />}
          </Box>
        ) : (
          <MonkStreetButton
            lightMonkButton={lightMonkButton}
            onClick={onClickCopyButton}
            isSafari={isSafari}
          />
        )}
      </Flex>
    </Box>
  );
}

export default CompanyMetaRow;
