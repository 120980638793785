export const industries = [
  {
    Name: "Advertising Agencies",
  },
  {
    Name: "Aerospace & Defense",
  },
  {
    Name: "Agricultural - Machinery",
  },
  {
    Name: "Agricultural Farm Products",
  },
  {
    Name: "Agricultural Inputs",
  },
  {
    Name: "Airlines",
  },
  {
    Name: "Apparel - Footwear & Accessories",
  },
  {
    Name: "Apparel - Manufacturers",
  },
  {
    Name: "Apparel - Retail",
  },
  {
    Name: "Asset Management",
  },
  {
    Name: "Asset Management - Global",
  },
  {
    Name: "Auto - Dealerships",
  },
  {
    Name: "Auto - Manufacturers",
  },
  {
    Name: "Auto - Parts",
  },
  {
    Name: "Banks - Diversified",
  },
  {
    Name: "Banks - Regional",
  },
  {
    Name: "Beverages - Alcoholic",
  },
  {
    Name: "Beverages - Non-Alcoholic",
  },
  {
    Name: "Beverages - Wineries & Distilleries",
  },
  {
    Name: "Biotechnology",
  },
  {
    Name: "Business Equipment & Supplies",
  },
  {
    Name: "Chemicals",
  },
  {
    Name: "Chemicals - Specialty",
  },
  {
    Name: "Coal",
  },
  {
    Name: "Communication Equipment",
  },
  {
    Name: "Computer Hardware",
  },
  {
    Name: "Conglomerates",
  },
  {
    Name: "Construction",
  },
  {
    Name: "Construction Materials",
  },
  {
    Name: "Consulting Services",
  },
  {
    Name: "Consumer Electronics",
  },
  {
    Name: "Copper",
  },
  {
    Name: "Discount Stores",
  },
  {
    Name: "Diversified Utilities",
  },
  {
    Name: "Drug Manufacturers - General",
  },
  {
    Name: "Drug Manufacturers - Specialty & Generic",
  },
  {
    Name: "Education & Training Services",
  },
  {
    Name: "Electrical Equipment & Parts",
  },
  {
    Name: "Electronic Gaming & Multimedia",
  },
  {
    Name: "Engineering & Construction",
  },
  {
    Name: "Entertainment",
  },
  {
    Name: "Financial - Capital Markets",
  },
  {
    Name: "Financial - Credit Services",
  },
  {
    Name: "Financial - Data & Stock Exchanges",
  },
  {
    Name: "Financial - Mortgages",
  },
  {
    Name: "Food Confectioners",
  },
  {
    Name: "Food Distribution",
  },
  {
    Name: "Furnishings",
  },
  {
    Name: "Gambling",
  },
  {
    Name: "Gold",
  },
  {
    Name: "Grocery Stores",
  },
  {
    Name: "Hardware",
  },
  {
    Name: "Home Improvement",
  },
  {
    Name: "Household & Personal Products",
  },
  {
    Name: "Independent Power Producers",
  },
  {
    Name: "Industrial - Distribution",
  },
  {
    Name: "Industrial - Infrastructure Operations",
  },
  {
    Name: "Industrial - Machinery",
  },
  {
    Name: "Industrial - Pollution & Treatment Controls",
  },
  {
    Name: "Industrial Materials",
  },
  {
    Name: "Information Technology Services",
  },
  {
    Name: "Insurance - Brokers",
  },
  {
    Name: "Insurance - Diversified",
  },
  {
    Name: "Insurance - Life",
  },
  {
    Name: "Insurance - Property & Casualty",
  },
  {
    Name: "Insurance - Reinsurance",
  },
  {
    Name: "Insurance - Specialty",
  },
  {
    Name: "Integrated Freight & Logistics",
  },
  {
    Name: "Internet Content & Information",
  },
  {
    Name: "Leisure",
  },
  {
    Name: "Luxury Goods",
  },
  {
    Name: "Manufacturing - Tools & Accessories",
  },
  {
    Name: "Marine Shipping",
  },
  {
    Name: "Medical - Care Facilities",
  },
  {
    Name: "Medical - Devices",
  },
  {
    Name: "Medical - Diagnostics & Research",
  },
  {
    Name: "Medical - Distribution",
  },
  {
    Name: "Medical - Healthcare Information Services",
  },
  {
    Name: "Medical - Healthcare Plans",
  },
  {
    Name: "Medical - Instruments & Supplies",
  },
  {
    Name: "Medical - Pharmaceuticals",
  },
  {
    Name: "Oil & Gas Drilling",
  },
  {
    Name: "Oil & Gas Equipment & Services",
  },
  {
    Name: "Oil & Gas Exploration & Production",
  },
  {
    Name: "Oil & Gas Integrated",
  },
  {
    Name: "Oil & Gas Midstream",
  },
  {
    Name: "Oil & Gas Refining & Marketing",
  },
  {
    Name: "Packaged Foods",
  },
  {
    Name: "Packaging & Containers",
  },
  {
    Name: "Paper",
  },
  {
    Name: "Personal Products & Services",
  },
  {
    Name: "Railroads",
  },
  {
    Name: "Real Estate - Services",
  },
  {
    Name: "Regulated Electric",
  },
  {
    Name: "Regulated Gas",
  },
  {
    Name: "Regulated Water",
  },
  {
    Name: "REIT - Diversified",
  },
  {
    Name: "REIT - Healthcare Facilities",
  },
  {
    Name: "REIT - Hotel & Motel",
  },
  {
    Name: "REIT - Industrial",
  },
  {
    Name: "REIT - Mortgage",
  },
  {
    Name: "REIT - Office",
  },
  {
    Name: "REIT - Residential",
  },
  {
    Name: "REIT - Retail",
  },
  {
    Name: "REIT - Specialty",
  },
  {
    Name: "Renewable Utilities",
  },
  {
    Name: "Rental & Leasing Services",
  },
  {
    Name: "Residential Construction",
  },
  {
    Name: "Restaurants",
  },
  {
    Name: "Security & Protection Services",
  },
  {
    Name: "Semiconductors",
  },
  {
    Name: "Software - Application",
  },
  {
    Name: "Software - Infrastructure",
  },
  {
    Name: "Solar",
  },
  {
    Name: "Specialty Business Services",
  },
  {
    Name: "Specialty Retail",
  },
  {
    Name: "Staffing & Employment Services",
  },
  {
    Name: "Steel",
  },
  {
    Name: "Technology Distributors",
  },
  {
    Name: "Telecommunications Services",
  },
  {
    Name: "Tobacco",
  },
  {
    Name: "Travel Lodging",
  },
  {
    Name: "Travel Services",
  },
  {
    Name: "Trucking",
  },
  {
    Name: "Uranium",
  },
  {
    Name: "Waste Management",
  },
];

export const industriesSelect = () => {
  return industries.map((v) => {
    return {
      label: v.Name,
      value: v.Name,
    };
  });
};
