import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { getScreenShotDom2Image, shareContent } from "../utils/image";

export function useScreenShots(elementId: string, isChart?: boolean) {
  const toast = useToast();
  const [blob, setBlob] = useState<Blob>();

  const getScreenShot = () => {
    getScreenShotDom2Image(
      elementId,
      (blobResult) => {
        setBlob(blobResult);
        toast({
          title: `Copy to clipboard`,
          status: "info",
        });
      },
      () => {
        toast({
          title: `Error on copy to clipboard`,
          status: "error",
          isClosable: true,
        });
      },
      isChart,
    );
  };

  return {
    blob,
    getScreenShot,
  };
}

export function useSharedDom(elementId: string, isChart?: boolean) {
  const toast = useToast();

  const share = () => {
    shareContent({
      id: elementId,
      onError: () => {
        toast({
          title: `Error on share`,
          description: "Reload page if the error persist",
          status: "error",
          isClosable: true,
        });
      },
      isCharts: isChart,
    });
  };

  return {
    share,
  };
}
