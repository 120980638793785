import { Text } from "@chakra-ui/react";

import { ReactComponent as ArrowIcon } from "../../../../assets/svg/arrow-outward.svg";

type TProps = {
  vector: string;
  meta: string;
  large?: boolean;
};

function GrowthBadge({ vector, meta, large = false }: TProps) {
  return (
    <Text
      borderRadius={"999px"}
      px={large ? "6px" : "4px"}
      py={large ? "4px" : "2px"}
      backgroundColor={vector === "up" ? "#58CC0233" : "#FF4B4B33"}
      color={vector === "up" ? "#58CC02" : "#FF4B4B"}
      fontSize={"10px"}
      fontWeight={"300"}
      lineHeight={"10px"}
      width={"max-content"}
      display="flex"
      alignItems={"center"}
    >
      {vector === "up" ? (
        <ArrowIcon />
      ) : (
        <ArrowIcon style={{ transform: "rotateX(180deg)" }} />
      )}
      &nbsp;{meta}&nbsp;
    </Text>
  );
}

export default GrowthBadge;
