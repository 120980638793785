import { useCallback, useEffect, useState } from "react";

export function useClickOutsideWithBuffer(
  elementRef,
  buffer = 10,
  onClickOutside,
) {
  const [isOutside, setIsOutside] = useState(false);

  const handleClick = useCallback(
    (event) => {
      if (
        elementRef &&
        elementRef.current &&
        !elementRef.current.contains(event.target)
      ) {
        const { left, top, right, bottom } =
          elementRef.current.getBoundingClientRect();
        const clickX = event.clientX;
        const clickY = event.clientY;

        if (
          clickX < left - buffer ||
          clickX > right + buffer ||
          clickY < top - buffer ||
          clickY > bottom + buffer
        ) {
          setIsOutside(true);
          if (onClickOutside) onClickOutside();
        }
      }
    },
    [buffer, elementRef, onClickOutside],
  );

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
  }, [elementRef, buffer, handleClick]);

  return isOutside;
}
