import { useEffect, useState } from "react";
import { imageIsMainlyWhite } from "../utils/image";

export function useMainlyWhite(imageSrc: string, threshold: number) {
  const [isMainlyWhite, setIsMainlyWhite] = useState(false);

  useEffect(() => {
    imageIsMainlyWhite(imageSrc, threshold)
      .then((value: boolean) => {
        setIsMainlyWhite(value);
      })
      .catch((_) => {
        setIsMainlyWhite(false);
      });
  }, [imageSrc, threshold]);

  return {
    isMainlyWhite,
  };
}
