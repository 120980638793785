export const country = [
  {
    Code: "AD",
    Country: "Andorra",
  },
  {
    Code: "AE",
    Country: "United Arab Emirates",
  },
  {
    Code: "AF",
    Country: "Afghanistan",
  },
  {
    Code: "AG",
    Country: "Antigua and Barbuda",
  },
  {
    Code: "AI",
    Country: "Anguilla",
  },
  {
    Code: "AL",
    Country: "Albania",
  },
  {
    Code: "AM",
    Country: "Armenia",
  },
  {
    Code: "AO",
    Country: "Angola",
  },
  {
    Code: "AQ",
    Country: "Antarctica",
  },
  {
    Code: "AR",
    Country: "Argentina",
  },
  {
    Code: "AS",
    Country: "American Samoa",
  },
  {
    Code: "AT",
    Country: "Austria",
  },
  {
    Code: "AU",
    Country: "Australia",
  },
  {
    Code: "AW",
    Country: "Aruba",
  },
  {
    Code: "AX",
    Country: "Åland Islands",
  },
  {
    Code: "AZ",
    Country: "Azerbaijan",
  },
  {
    Code: "BA",
    Country: "Bosnia and Herzegovina",
  },
  {
    Code: "BB",
    Country: "Barbados",
  },
  {
    Code: "BD",
    Country: "Bangladesh",
  },
  {
    Code: "BE",
    Country: "Belgium",
  },
  {
    Code: "BF",
    Country: "Burkina Faso",
  },
  {
    Code: "BG",
    Country: "Bulgaria",
  },
  {
    Code: "BH",
    Country: "Bahrain",
  },
  {
    Code: "BI",
    Country: "Burundi",
  },
  {
    Code: "BJ",
    Country: "Benin",
  },
  {
    Code: "BL",
    Country: "Saint Barthélemy",
  },
  {
    Code: "BM",
    Country: "Bermuda",
  },
  {
    Code: "BN",
    Country: "Brunei Darussalam",
  },
  {
    Code: "BO",
    Country: "Bolivia, Plurinational State of",
  },
  {
    Code: "BQ",
    Country: "Bonaire, Sint Eustatius and Saba",
  },
  {
    Code: "BR",
    Country: "Brazil",
  },
  {
    Code: "BS",
    Country: "Bahamas",
  },
  {
    Code: "BT",
    Country: "Bhutan",
  },
  {
    Code: "BV",
    Country: "Bouvet Island",
  },
  {
    Code: "BW",
    Country: "Botswana",
  },
  {
    Code: "BY",
    Country: "Belarus",
  },
  {
    Code: "BZ",
    Country: "Belize",
  },
  {
    Code: "CA",
    Country: "Canada",
  },
  {
    Code: "CC",
    Country: "Cocos (Keeling) Islands",
  },
  {
    Code: "CD",
    Country: "Congo, Democratic Republic of the",
  },
  {
    Code: "CF",
    Country: "Central African Republic",
  },
  {
    Code: "CG",
    Country: "Congo",
  },
  {
    Code: "CH",
    Country: "Switzerland",
  },
  {
    Code: "CI",
    Country: "Côte d'Ivoire",
  },
  {
    Code: "CK",
    Country: "Cook Islands",
  },
  {
    Code: "CL",
    Country: "Chile",
  },
  {
    Code: "CM",
    Country: "Cameroon",
  },
  {
    Code: "CN",
    Country: "China",
  },
  {
    Code: "CO",
    Country: "Colombia",
  },
  {
    Code: "CR",
    Country: "Costa Rica",
  },
  {
    Code: "CU",
    Country: "Cuba",
  },
  {
    Code: "CV",
    Country: "Cabo Verde",
  },
  {
    Code: "CW",
    Country: "Curaçao",
  },
  {
    Code: "CX",
    Country: "Christmas Island",
  },
  {
    Code: "CY",
    Country: "Cyprus",
  },
  {
    Code: "CZ",
    Country: "Czechia",
  },
  {
    Code: "DE",
    Country: "Germany",
  },
  {
    Code: "DJ",
    Country: "Djibouti",
  },
  {
    Code: "DK",
    Country: "Denmark",
  },
  {
    Code: "DM",
    Country: "Dominica",
  },
  {
    Code: "DO",
    Country: "Dominican Republic",
  },
  {
    Code: "DZ",
    Country: "Algeria",
  },
  {
    Code: "EC",
    Country: "Ecuador",
  },
  {
    Code: "EE",
    Country: "Estonia",
  },
  {
    Code: "EG",
    Country: "Egypt",
  },
  {
    Code: "EH",
    Country: "Western Sahara",
  },
  {
    Code: "ER",
    Country: "Eritrea",
  },
  {
    Code: "ES",
    Country: "Spain",
  },
  {
    Code: "ET",
    Country: "Ethiopia",
  },
  {
    Code: "FI",
    Country: "Finland",
  },
  {
    Code: "FJ",
    Country: "Fiji",
  },
  {
    Code: "FK",
    Country: "Falkland Islands (Malvinas)",
  },
  {
    Code: "FM",
    Country: "Micronesia, Federated States of",
  },
  {
    Code: "FO",
    Country: "Faroe Islands",
  },
  {
    Code: "FR",
    Country: "France",
  },
  {
    Code: "GA",
    Country: "Gabon",
  },
  {
    Code: "GB",
    Country: "United Kingdom of Great Britain and Northern Ireland",
  },
  {
    Code: "GD",
    Country: "Grenada",
  },
  {
    Code: "GE",
    Country: "Georgia",
  },
  {
    Code: "GF",
    Country: "French Guiana",
  },
  {
    Code: "GG",
    Country: "Guernsey",
  },
  {
    Code: "GH",
    Country: "Ghana",
  },
  {
    Code: "GI",
    Country: "Gibraltar",
  },
  {
    Code: "GL",
    Country: "Greenland",
  },
  {
    Code: "GM",
    Country: "Gambia",
  },
  {
    Code: "GN",
    Country: "Guinea",
  },
  {
    Code: "GP",
    Country: "Guadeloupe",
  },
  {
    Code: "GQ",
    Country: "Equatorial Guinea",
  },
  {
    Code: "GR",
    Country: "Greece",
  },
  {
    Code: "GS",
    Country: "South Georgia and the South Sandwich Islands",
  },
  {
    Code: "GT",
    Country: "Guatemala",
  },
  {
    Code: "GU",
    Country: "Guam",
  },
  {
    Code: "GW",
    Country: "Guinea-Bissau",
  },
  {
    Code: "GY",
    Country: "Guyana",
  },
  {
    Code: "HK",
    Country: "Hong Kong",
  },
  {
    Code: "HM",
    Country: "Heard Island and McDonald Islands",
  },
  {
    Code: "HN",
    Country: "Honduras",
  },
  {
    Code: "HR",
    Country: "Croatia",
  },
  {
    Code: "HT",
    Country: "Haiti",
  },
  {
    Code: "HU",
    Country: "Hungary",
  },
  {
    Code: "ID",
    Country: "Indonesia",
  },
  {
    Code: "IE",
    Country: "Ireland",
  },
  {
    Code: "IL",
    Country: "Israel",
  },
  {
    Code: "IM",
    Country: "Isle of Man",
  },
  {
    Code: "IN",
    Country: "India",
  },
  {
    Code: "IO",
    Country: "British Indian Ocean Territory",
  },
  {
    Code: "IQ",
    Country: "Iraq",
  },
  {
    Code: "IR",
    Country: "Iran, Islamic Republic of",
  },
  {
    Code: "IS",
    Country: "Iceland",
  },
  {
    Code: "IT",
    Country: "Italy",
  },
  {
    Code: "JE",
    Country: "Jersey",
  },
  {
    Code: "JM",
    Country: "Jamaica",
  },
  {
    Code: "JO",
    Country: "Jordan",
  },
  {
    Code: "JP",
    Country: "Japan",
  },
  {
    Code: "KE",
    Country: "Kenya",
  },
  {
    Code: "KG",
    Country: "Kyrgyzstan",
  },
  {
    Code: "KH",
    Country: "Cambodia",
  },
  {
    Code: "KI",
    Country: "Kiribati",
  },
  {
    Code: "KM",
    Country: "Comoros",
  },
  {
    Code: "KN",
    Country: "Saint Kitts and Nevis",
  },
  {
    Code: "KP",
    Country: "Korea, Democratic People's Republic of",
  },
  {
    Code: "KR",
    Country: "Korea, Republic of",
  },
  {
    Code: "KW",
    Country: "Kuwait",
  },
  {
    Code: "KY",
    Country: "Cayman Islands",
  },
  {
    Code: "KZ",
    Country: "Kazakhstan",
  },
  {
    Code: "LA",
    Country: "Lao People's Democratic Republic",
  },
  {
    Code: "LB",
    Country: "Lebanon",
  },
  {
    Code: "LC",
    Country: "Saint Lucia",
  },
  {
    Code: "LI",
    Country: "Liechtenstein",
  },
  {
    Code: "LK",
    Country: "Sri Lanka",
  },
  {
    Code: "LR",
    Country: "Liberia",
  },
  {
    Code: "LS",
    Country: "Lesotho",
  },
  {
    Code: "LT",
    Country: "Lithuania",
  },
  {
    Code: "LU",
    Country: "Luxembourg",
  },
  {
    Code: "LV",
    Country: "Latvia",
  },
  {
    Code: "LY",
    Country: "Libya",
  },
  {
    Code: "MA",
    Country: "Morocco",
  },
  {
    Code: "MC",
    Country: "Monaco",
  },
  {
    Code: "MD",
    Country: "Moldova, Republic of",
  },
  {
    Code: "ME",
    Country: "Montenegro",
  },
  {
    Code: "MF",
    Country: "Saint Martin (French part)",
  },
  {
    Code: "MG",
    Country: "Madagascar",
  },
  {
    Code: "MH",
    Country: "Marshall Islands",
  },
  {
    Code: "MK",
    Country: "North Macedonia",
  },
  {
    Code: "ML",
    Country: "Mali",
  },
  {
    Code: "MM",
    Country: "Myanmar",
  },
  {
    Code: "MN",
    Country: "Mongolia",
  },
  {
    Code: "MO",
    Country: "Macao",
  },
  {
    Code: "MP",
    Country: "Northern Mariana Islands",
  },
  {
    Code: "MQ",
    Country: "Martinique",
  },
  {
    Code: "MR",
    Country: "Mauritania",
  },
  {
    Code: "MS",
    Country: "Montserrat",
  },
  {
    Code: "MT",
    Country: "Malta",
  },
  {
    Code: "MU",
    Country: "Mauritius",
  },
  {
    Code: "MV",
    Country: "Maldives",
  },
  {
    Code: "MW",
    Country: "Malawi",
  },
  {
    Code: "MX",
    Country: "Mexico",
  },
  {
    Code: "MY",
    Country: "Malaysia",
  },
  {
    Code: "MZ",
    Country: "Mozambique",
  },
  {
    Code: "NA",
    Country: "Namibia",
  },
  {
    Code: "NC",
    Country: "New Caledonia",
  },
  {
    Code: "NE",
    Country: "Niger",
  },
  {
    Code: "NF",
    Country: "Norfolk Island",
  },
  {
    Code: "NG",
    Country: "Nigeria",
  },
  {
    Code: "NI",
    Country: "Nicaragua",
  },
  {
    Code: "NL",
    Country: "Netherlands, Kingdom of the",
  },
  {
    Code: "NO",
    Country: "Norway",
  },
  {
    Code: "NP",
    Country: "Nepal",
  },
  {
    Code: "NR",
    Country: "Nauru",
  },
  {
    Code: "NU",
    Country: "Niue",
  },
  {
    Code: "NZ",
    Country: "New Zealand",
  },
  {
    Code: "OM",
    Country: "Oman",
  },
  {
    Code: "PA",
    Country: "Panama",
  },
  {
    Code: "PE",
    Country: "Peru",
  },
  {
    Code: "PF",
    Country: "French Polynesia",
  },
  {
    Code: "PG",
    Country: "Papua New Guinea",
  },
  {
    Code: "PH",
    Country: "Philippines",
  },
  {
    Code: "PK",
    Country: "Pakistan",
  },
  {
    Code: "PL",
    Country: "Poland",
  },
  {
    Code: "PM",
    Country: "Saint Pierre and Miquelon",
  },
  {
    Code: "PN",
    Country: "Pitcairn",
  },
  {
    Code: "PR",
    Country: "Puerto Rico",
  },
  {
    Code: "PS",
    Country: "Palestine, State of",
  },
  {
    Code: "PT",
    Country: "Portugal",
  },
  {
    Code: "PW",
    Country: "Palau",
  },
  {
    Code: "PY",
    Country: "Paraguay",
  },
  {
    Code: "QA",
    Country: "Qatar",
  },
  {
    Code: "RE",
    Country: "Réunion",
  },
  {
    Code: "RO",
    Country: "Romania",
  },
  {
    Code: "RS",
    Country: "Serbia",
  },
  {
    Code: "RU",
    Country: "Russian Federation",
  },
  {
    Code: "RW",
    Country: "Rwanda",
  },
  {
    Code: "SA",
    Country: "Saudi Arabia",
  },
  {
    Code: "SB",
    Country: "Solomon Islands",
  },
  {
    Code: "SC",
    Country: "Seychelles",
  },
  {
    Code: "SD",
    Country: "Sudan",
  },
  {
    Code: "SE",
    Country: "Sweden",
  },
  {
    Code: "SG",
    Country: "Singapore",
  },
  {
    Code: "SH",
    Country: "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    Code: "SI",
    Country: "Slovenia",
  },
  {
    Code: "SJ",
    Country: "Svalbard and Jan Mayen",
  },
  {
    Code: "SK",
    Country: "Slovakia",
  },
  {
    Code: "SL",
    Country: "Sierra Leone",
  },
  {
    Code: "SM",
    Country: "San Marino",
  },
  {
    Code: "SN",
    Country: "Senegal",
  },
  {
    Code: "SO",
    Country: "Somalia",
  },
  {
    Code: "SR",
    Country: "Suriname",
  },
  {
    Code: "SS",
    Country: "South Sudan",
  },
  {
    Code: "ST",
    Country: "Sao Tome and Principe",
  },
  {
    Code: "SV",
    Country: "El Salvador",
  },
  {
    Code: "SX",
    Country: "Sint Maarten (Dutch part)",
  },
  {
    Code: "SY",
    Country: "Syrian Arab Republic",
  },
  {
    Code: "SZ",
    Country: "Eswatini",
  },
  {
    Code: "TC",
    Country: "Turks and Caicos Islands",
  },
  {
    Code: "TD",
    Country: "Chad",
  },
  {
    Code: "TF",
    Country: "French Southern Territories",
  },
  {
    Code: "TG",
    Country: "Togo",
  },
  {
    Code: "TH",
    Country: "Thailand",
  },
  {
    Code: "TJ",
    Country: "Tajikistan",
  },
  {
    Code: "TK",
    Country: "Tokelau",
  },
  {
    Code: "TL",
    Country: "Timor-Leste",
  },
  {
    Code: "TM",
    Country: "Turkmenistan",
  },
  {
    Code: "TN",
    Country: "Tunisia",
  },
  {
    Code: "TO",
    Country: "Tonga",
  },
  {
    Code: "TR",
    Country: "Türkiye",
  },
  {
    Code: "TT",
    Country: "Trinidad and Tobago",
  },
  {
    Code: "TV",
    Country: "Tuvalu",
  },
  {
    Code: "TW",
    Country: "Taiwan, Province of China",
  },
  {
    Code: "TZ",
    Country: "Tanzania, United Republic of",
  },
  {
    Code: "UA",
    Country: "Ukraine",
  },
  {
    Code: "UG",
    Country: "Uganda",
  },
  {
    Code: "UM",
    Country: "United States Minor Outlying Islands",
  },
  {
    Code: "US",
    Country: "United States of America",
  },
  {
    Code: "UY",
    Country: "Uruguay",
  },
  {
    Code: "UZ",
    Country: "Uzbekistan",
  },
  {
    Code: "VA",
    Country: "Holy See",
  },
  {
    Code: "VC",
    Country: "Saint Vincent and the Grenadines",
  },
  {
    Code: "VE",
    Country: "Venezuela, Bolivarian Republic of",
  },
  {
    Code: "VG",
    Country: "Virgin Islands (British)",
  },
  {
    Code: "VI",
    Country: "Virgin Islands (U.S.)",
  },
  {
    Code: "VN",
    Country: "Viet Nam",
  },
  {
    Code: "VU",
    Country: "Vanuatu",
  },
  {
    Code: "WF",
    Country: "Wallis and Futuna",
  },
  {
    Code: "WS",
    Country: "Samoa",
  },
  {
    Code: "YE",
    Country: "Yemen",
  },
  {
    Code: "YT",
    Country: "Mayotte",
  },
  {
    Code: "ZA",
    Country: "South Africa",
  },
  {
    Code: "ZM",
    Country: "Zambia",
  },
  {
    Code: "ZW",
    Country: "Zimbabwe",
  },
];

export const countrySelect = () => {
  return country.map((v) => {
    return {
      label: v.Country,
      value: v.Code,
    };
  });
};
