const data = {
  sector: {
    name: "Sector",
    value: "Healthcare",
  },
  industry: {
    name: "Industry",
    value: "Drug Manufacturers—General",
  },
  ceo: {
    name: "CEO",
    value: "Mr. Joaquin Duato",
  },
  exchange: {
    name: "Exchange",
    value: "New York Stock Exchange",
  },
  ipoDate: {
    name: "IPO Date",
    value: "September 24, 1944",
  },
  website: {
    name: "Website",
    value: "https://www.jnj.com",
  },

  description: {
    name: "Description",
    value:
      "Johnson & Johnson, together with its subsidiaries, researches and develops, manufactures, and sells various products in the healthcare field worldwide. The company's Consumer Health segment offers baby care products under the JOHNSON'S and AVEENO Baby brands; oral care products under the LISTERINE brand; skin health/beauty products under the AVEENO, CLEAN & CLEAR, DR. CI:LABO, NEUTROGENA, and OGX brands; TYLENOL acetaminophen products; SUDAFED cold, flu, and allergy products; BENADRYL and ZYRTEC allergy products; MOTRIN IB ibuprofen products; NICORETTE smoking cessation products; and PEPCID acid reflux products. It also offers STAYFREE and CAREFREE sanitary pads; o.b. tampons; adhesive bandages under the BAND-AID brand; and first aid products under the NEOSPORIN brand. It serves general public, retail outlets, and distributors. The company's Pharmaceutical segment offers products for rheumatoid arthritis, psoriatic arthritis, inflammatory bowel disease, and psoriasis; HIV/AIDS and COVID-19 infectious diseases; mood disorders, neurodegenerative disorders, and schizophrenia; prostate cancer, hematologic malignancies, lung cancer, and bladder cancer; thrombosis, diabetes and macular degeneration; and pulmonary arterial hypertension. This segment serves retailers, wholesalers, distributors, hospitals, and healthcare professionals directly for prescription use. Its MedTech segment provides electrophysiology products to treat cardiovascular diseases; neurovascular care products to treat hemorrhagic and ischemic stroke; orthopaedics products in support of hips, knees, trauma, spine, sports, and other; advanced and general surgery solutions that focus on breast aesthetics, ear, nose, and throat procedures; and disposable contact lenses and ophthalmic products related to cataract and laser refractive surgery under the ACUVUE brand. This segment serves wholesalers, hospitals, and retailers. The company was founded in 1886 and is based in New Brunswick, New Jersey.",
  },
};

export { data };
