import { CustomNegativeChartDataProps } from "./negativeChartTypes";

export const SUPPORTED_QUARTERS = ["Q1", "Q2", "Q3", "Q4"];
export const legendPercentages = [0, 25, 50, 75, 100];
export const threeLegendPercentages = [0, 75, 100];
export const secondLegendPercenteges = [0, 100];
export const secondReverseLegendPercenteges = [0, 100];
export const threeReverseLegendPercentages = [100, 50, 0];
export const reverseLegendPercentages = [100, 75, 50, 25, 0];

export const dataInRange = (data: CustomNegativeChartDataProps[]) => {
  const { min, max } = getMinMaxValueInData(data);
  return min > -1 && max <= 1;
};

export const defaultLegendValues = (data: CustomNegativeChartDataProps[]) => {
  const { min: minValue, max: maxValue } = getMinMaxValueInData(data);

  // calculando la diferencia entre el cero y los extremos para ver a cual esta mas cerca
  const diffMinValue = minValue >= 0 ? minValue : minValue * -1;
  const diffMaxValue = maxValue >= 0 ? maxValue : maxValue * -1;

  let promBetweenMinAndMax = (minValue + maxValue) / 2;

  let promBetweenPromAndMin = (promBetweenMinAndMax + minValue) / 2;
  let promBetweenPromAndMax = (promBetweenMinAndMax + maxValue) / 2;

  let promBetweenZeroAndMin = minValue / 2;

  let promBetweenPromZeroAndMinAndMin = (promBetweenZeroAndMin + minValue) / 2;
  let promBetweenPromZeroAndMinAndZero = promBetweenZeroAndMin / 2;

  let promBetweenZeroAndMax = maxValue / 2;

  let promBetweenPromZeroAndMaxAndZero = promBetweenZeroAndMax / 2;
  let promBetweenPromZeroAndMaxAndMax = (promBetweenZeroAndMax + maxValue) / 2;

  if (minValue >= 0 && maxValue <= 1) {
    return [0, 1];
  }

  if (minValue >= -1 && maxValue <= 0) {
    return [-1, 0];
  }

  if (minValue > -1 && maxValue <= 1) return [minValue, 0, maxValue];

  // el valor minimo es cero
  if (minValue === 0) {
    return [
      minValue,
      promBetweenPromAndMin,
      promBetweenMinAndMax,
      promBetweenPromAndMax,
      maxValue,
    ];
  }

  // el valor maximo es cero
  if (maxValue === 0) {
    return [
      minValue,
      promBetweenPromAndMin,
      promBetweenMinAndMax,
      promBetweenPromAndMax,
      maxValue,
    ];
  }

  // el valor minimo y el valor maximo son ambos mayores q cero
  if (minValue > 0 && maxValue > 0) {
    return [
      0,
      minValue,
      promBetweenPromAndMin,
      promBetweenPromAndMax,
      maxValue,
    ];
  }

  // el valor minimo es mas grande q el valor maximo
  if (diffMinValue > diffMaxValue) {
    return [
      minValue,
      promBetweenPromZeroAndMinAndMin,
      promBetweenPromZeroAndMinAndZero,
      0,
      maxValue,
    ];
  }

  // el valor minimo es mas pequeño q el valor maximo
  if (diffMinValue < diffMaxValue) {
    return [
      minValue,
      0,
      promBetweenPromZeroAndMaxAndZero,
      promBetweenPromZeroAndMaxAndMax,
      maxValue,
    ];
  }

  // ambos valores son iguales
  return [minValue, promBetweenZeroAndMin, 0, promBetweenZeroAndMax, maxValue];
};

export function getMinMaxValueInData(data: CustomNegativeChartDataProps[]) {
  let minValue = data[0].percentile;
  let maxValue = data[0].percentile;

  // obteniendo los valores minimo y maximo de la data
  data.forEach((item: CustomNegativeChartDataProps) => {
    if (minValue && item.percentile <= minValue) {
      minValue = item.percentile;
    }

    if (maxValue && item.percentile >= maxValue) {
      maxValue = item.percentile;
    }
  });

  return {
    min: minValue,
    max: maxValue,
  };
}
