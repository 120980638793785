import { Box, Flex, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  actualRatios,
  actualRecentCompanyRatios,
  actualStock,
} from "../../../store/slices/stock";
import CompanyMetaRow from "../../../subviews/RightSubview/Stocks/CompanyMetaRow";
import { ICompanyRatios } from "../../../types/Chart";
import { FormatChartNumber } from "../../../utils/formatChartNumber";
import { roundNumber } from "../../../utils/Math";
import { ratioDictSign } from "../../../utils/ratios";
import GrowthBadge from "../../common/badges/GrowthBadge";
import CandlestickChart from "../CandlestickChart/CandleStickChart";
import TooltipModal from "../tooltipModal/tooltipModal";

interface ValuationCardProps {
  activeChart: string;
}

const tabs = [
  { tab: "PER", longName: "Price to Earnings" },
  { tab: "P/FCF", longName: "Price to CashFlow" },
  { tab: "P/S", longName: "Price to Sales" },
  { tab: "EV/Revenue", longName: "EV to Revenue" },
  { tab: "EV/Operating Income", longName: "EV to Operating Income" },
  { tab: "EV/Net Income", longName: "EV to Net Income" },
  { tab: "EV/Assets", longName: "EV to Assets" },
  { tab: "PEG", longName: "Price to Earnings Growth" },
  {
    tab: "EV / Operating Earnings",
    longName: "EV to Operating Earnings",
  },
];

const getDataByYear = (
  data: ICompanyRatios[] | undefined,
  year: number,
  key:
    | "priceToEarnings"
    | "priceToFCF"
    | "priceToSales"
    | "evToRevenue"
    | "evToOperatingIncome"
    | "evToNetIncome"
    | "evToAssets"
    | "priceToEarningsGrowth"
    | "evToOperatingEarnings",
) => {
  if (!data || data.length < year * 4) return 0;
  return data[year * 4]?.[key] || 0;
};

const getBarsData = (
  data: ICompanyRatios[] | undefined,
  key:
    | "priceToEarnings"
    | "priceToFCF"
    | "priceToSales"
    | "evToRevenue"
    | "evToOperatingIncome"
    | "evToNetIncome"
    | "evToAssets"
    | "priceToEarningsGrowth"
    | "evToOperatingEarnings",
) => {
  if (!data) return [0, 0, 0];
  return [
    ((getDataByYear(data, 0, key) - getDataByYear(data, 1, key)) * 100) /
      Math.abs(getDataByYear(data, 1, key)),
    ((getDataByYear(data, 1, key) - getDataByYear(data, 2, key)) * 100) /
      Math.abs(getDataByYear(data, 2, key)),
    ((getDataByYear(data, 2, key) - getDataByYear(data, 3, key)) * 100) /
      Math.abs(getDataByYear(data, 3, key)),
  ];
};

const ValuationCard = ({ activeChart }: ValuationCardProps) => {
  const [valuation_para_id, setValuation_para_id] = useState<string>("similar");
  const [isOpen, setIsOpen] = useState(false);

  const tab = tabs.find(({ tab }) => tab === activeChart);

  const compData = useSelector(actualStock);

  const lastUpdate = new Date(compData?.lastScoreUpdateDate || Date.now())
    ?.toLocaleDateString()
    ?.replaceAll("/", "-")
    ?.split("-")
    .reverse()
    .join("-");

  const companyRatios = useSelector(actualRecentCompanyRatios);

  const mostRecentRatio = useSelector(actualRatios);

  useEffect(() => {
    if (compData && compData.valueScore) {
      compData.valueScore >= 80 && setValuation_para_id("pricier");
      compData.valueScore < 80 &&
        compData.valueScore >= 60 &&
        setValuation_para_id("slightly_pricier");
      compData.valueScore < 60 &&
        compData.valueScore >= 40 &&
        setValuation_para_id("similar");
      compData.valueScore < 40 &&
        compData.valueScore >= 20 &&
        setValuation_para_id("slightly_cheaper");
      compData.valueScore < 20 && setValuation_para_id("cheaper");
    }
  }, [compData]);

  const sign = ratioDictSign[activeChart] ?? "%";

  const getChartDetails = useCallback(() => {
    if (!companyRatios || companyRatios.length === 0)
      return {
        chartNumber: 0,
        barsData: [0, 0, 0],
        oneYearMetric: 0,
        chartData: [],
      };

    const dict = {
      "Price / Earnings": "priceToEarnings",
      "P/FCF": "priceToFCF",
      "Price / Sale": "priceToSales",
      "EV / Revenue": "evToRevenue",
      "EV/Operating Income": "evToOperatingIncome",
      "EV/Net Income": "evToNetIncome",
      "EV/Assets": "evToAssets",
      "Price / Earnings Growth": "priceToEarningsGrowth",
      "EV / Operating Earnings": "evToOperatingEarnings",
      "EV / EBITDA": "evToEBITDA",
      "EV / FCF": "evToFCF",
    };

    const chartNumber = FormatChartNumber({
      payload:
        mostRecentRatio == null
          ? getDataByYear(companyRatios, 0, dict[activeChart])
          : mostRecentRatio[dict[activeChart]],
    });

    const barsData = getBarsData(companyRatios, dict[activeChart]);
    const oneYearMetric = barsData[0] || 0;
    const ratiosData =
      mostRecentRatio == null
        ? companyRatios
        : [mostRecentRatio, ...companyRatios];

    const chartData = ratiosData
      ?.map((val, i) => ({
        time: i,
        period: val.period,
        value: val[dict[activeChart]] || 0,
        yearQ: `${val.calendarYear}`,
        date: new Date(i === 0 ? val.ratiosUpdateDate : val.statementsDate)
          ?.toLocaleDateString()
          ?.replaceAll("/", "-")
          ?.split("-")
          .reverse()
          .join("-"),
      }))
      .reverse();

    return {
      chartNumber: Number(chartNumber).toFixed(2),
      barsData,
      oneYearMetric: Number.isNaN(oneYearMetric) ? 0 : oneYearMetric,
      chartData,
    };
  }, [activeChart, companyRatios, mostRecentRatio]);

  return (
    <Box bgColor="#FFF" w="100%" h="100%">
      <Box
        position="relative"
        my={"10px"}
        backgroundColor={"#FFF"}
        borderRadius={"20px"}
        id="ValuationCard"
      >
        <Flex
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          gap="10px"
          ml="10px"
        >
          <Text
            as={"p"}
            fontSize={"24px"}
            lineHeight={"36px"}
            fontWeight={500}
            display={"flex"}
            alignItems={"flex-end"}
          >
            {`${roundNumber(Number.parseFloat(getChartDetails().chartNumber.toString()))} `}
            <Text fontSize={"12px"} lineHeight={"28px"}>
              {sign}
            </Text>
          </Text>
        </Flex>

        <Box mb="25px" ml="10px">
          <GrowthBadge
            vector={getChartDetails().oneYearMetric > 0 ? "up" : "down"}
            meta={`${
              getChartDetails().oneYearMetric > 0 ? "+" : ""
            }${getChartDetails().oneYearMetric?.toFixed(
              activeChart === "P/FCF" ? 0 : 2,
            )} ${sign === "%" ? "bps" : "%"} last 1 year`}
          />
        </Box>

        <Box>
          <CandlestickChart
            data={getChartDetails().chartData}
            activeChart={activeChart}
          />
        </Box>

        <CompanyMetaRow
          compData={compData}
          lastUpdate={lastUpdate}
          idElementForScreenshot={`ValuationCardRatios`}
          isChart
          styledBox
        />
      </Box>
      <TooltipModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        activeChart={activeChart}
      />
    </Box>
  );
};

export default ValuationCard;
