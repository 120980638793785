import { Box, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { useFetchCompanyRatiosQuery } from "../../../services/chartApi";
import { IStock } from "../../../types/Stock";
import { FormatChartNumber } from "../../../utils/formatChartNumber";
import FAQBox from "../FAQBox";

interface FAQCardProps {
  compData: IStock;
  fullTicker: string;
}

const FAQCard = ({ compData, fullTicker }: FAQCardProps) => {
  const locale = navigator.language;
  const { data: companyRatios } = useFetchCompanyRatiosQuery(fullTicker);

  const dividendYeildThisYear =
    companyRatios &&
    companyRatios.length > 0 &&
    companyRatios[0].dividendYield !== undefined
      ? companyRatios[0].dividendYield * 100
      : 0;

  const name =
    compData && compData?.companyName ? compData?.companyName : "Apple Inc.";

  const FAQData = [
    {
      title: `Is ${name} stock a good stock to buy?`,
      description: isGoodStock(name, compData?.monkScore),
    },
    {
      title: `Is ${name} overvalued or undervalued?`,
      description: isOvervalued(name, compData?.valueScore),
    },
    {
      title: `Is this a good moment to buy ${name} stock?`,
      description: isGoodMoment(name, compData?.momentumScore),
    },
    {
      title: `How much is ${name} worth?`,
      description: `${name} market capitalization was ${FormatChartNumber({
        payload: compData?.mktCap,
      })} ${compData?.currency} as of market close yesterday.`,
    },
    {
      title: `Does ${name} pay a dividends?`,
      description:
        dividendYeildThisYear > 0
          ? `Yes, ${name} pays out dividends of about ${FormatChartNumber({
              payload: dividendYeildThisYear,
            })}% per year.`
          : `No, ${name} does not pay dividends.`,
    },
  ];

  // const FAQSpanish = [
  //   {
  //     title: `¿Cuánto cuesta ${name} vale la pena?`,
  //     description: `La capitalización de mercado de ${name} era ${intl.formatNumber(
  //       Number(alphaData?.MarketCapitalization || 0),
  //       {
  //         notation: "compact",
  //         compactDisplay: "long",
  //       }
  //     )} USD al cierre del mercado ayer.`,
  //   },
  //   {
  //     title: `¿Es ${name} ¿sobrevalorado o infravalorado?`,
  //     description: `Según nuestras estimaciones, cada acción de ${name} tiene un valor de $${Math.round(
  //       Number(alphaData?.MarketCapitalization) /
  //         Number(alphaData?.SharesOutstanding)
  //     )}, que es un ${Math.round(
  //       Number(alphaData?.AnalystTargetPrice) - stockCurrentPrice
  //     )}% menos de lo que estaba negociando cuando el mercado de valores cerró ayer. Por lo tanto, parece que ${name} está infravalorado.
  //     Search for this on.`,
  //   },
  //   {
  //     title: `¿Es ${name} acciones ¿comprar o vender?`,
  //     description: `${name} es una buena acción para comprar, según 31 analistas. 23 analistas recomiendan comprar la acción, 6 recomiendan mantenerla y 2 recomiendan vender.`,
  //   },
  //   {
  //     title: `¿Es este un ${
  //       compData?.momentumScore > 50 ? "buen" : "malo"
  //     } momento para comprar ${name}?`,
  //     description: `Este podría ser un ${
  //       compData?.momentumScore > 50 ? "buen" : "malo"
  //     } momento para comprar ${name} ya que sus acciones han tenido mejores resultados que la mayoría de sus competidores en los últimos 6 meses.`,
  //   },
  //   {
  //     title: `¿Cuánto costará ${name} valdrán las acciones en un año?`,
  //     description: `Según las previsiones de 37 analistas, ${name} las acciones valdrán aproximadamente ${
  //       alphaData?.AnalystTargetPrice
  //     } USD en un año. Eso es un ${Math.round(
  //       Number(alphaData?.AnalystTargetPrice) - stockCurrentPrice || 0
  //     )}% más alto que el precio de cierre del mercado ayer.`,
  //   },
  //   {
  //     title: `¿Es ${name} acciones ¿comprar o vender?`,
  //     description: `${name} es una buena acción para comprar, según 31 analistas. 23 analistas recomiendan comprar la acción, 6 recomiendan mantenerla y 2 recomiendan vender.`,
  //   },
  //   {
  //     title: `¿${name} pagar un dividendo?`,
  //     description:
  //       Number(alphaData?.DividendYield) > 0
  //         ? `Sí, ${name} paga un dividendo de $${
  //             alphaData?.DividendPerShare
  //           } por acción, lo que representa un rendimiento del ${formatNumberToDecimalPlaces(
  //             Number(alphaData?.DividendYield) * 100
  //           )}%.`
  //         : `No, ${name} no paga dividendos.`,
  //   },
  //   {
  //     title: `¿Vale la pena comprar 1 acción de ${name}?`,
  //     description: `Sí, todavía obtienes el mismo porcentaje de ganancias, pero en cantidades más pequeñas. Si eso es todo lo que tiene para invertir, hágalo si le gustan las acciones; esto es válido para todas las acciones.`,
  //   },
  // ];

  return (
    <>
      <Text
        fontSize="24px"
        fontWeight={600}
        lineHeight="36px"
        fontFamily="Poppins"
        px={1}
      >
        <FormattedMessage id="FAQ_title" />
      </Text>
      <Text fontSize="16px" fontWeight={300} lineHeight="24px" px={1} mb="16px">
        <FormattedMessage
          id="FAQ_subText"
          values={{ name: compData && compData?.companyName }}
        />
      </Text>
      <Box
        display={"flex"}
        flexDirection={"column"}
        rowGap={3}
        mb={{ base: 6, md: 3 }}
        pb={3}
        className="parent_card_height"
      >
        {(locale === "es" || locale === "es-ES" ? FAQData : FAQData).map(
          (data, i) => (
            <FAQBox data={data} key={i} />
          ),
        )}
      </Box>
    </>
  );
};

export default FAQCard;

function isGoodStock(companyName: string, monkScoreRank: number) {
  if (monkScoreRank >= 60) {
    return `${companyName} seems to be a good stock to buy. It outperforms ${Math.round(
      monkScoreRank,
    )}% of its competitors in the quantitative factors that science has demonstrated to have the greatest predictive power.`;
  } else if (monkScoreRank < 60 && monkScoreRank >= 40) {
    return `${companyName} doesn't seem a particularly good nor bad stock to buy. It performs better than ${Math.round(
      monkScoreRank,
    )}% of its competitors and worse than ${Math.round(
      100 - monkScoreRank,
    )}% of them in the quantitative factors that science has demonstrated to have the greatest predictive power.`;
  }
  return `${companyName} may be a bad stock to buy. It underpeforms ${Math.round(
    100 - monkScoreRank,
  )}% of its competitors in the quantitative factors that science has demonstrated to have the greatest predictive power.`;
}

function isOvervalued(companyName: string, valueRank: number) {
  if (valueRank >= 60) {
    return `${companyName} seems to be undervalued. It's cheaper than ${Math.round(
      valueRank,
    )}% of its competitors in the quantitative factors that science has demonstrated to have the greatest predictive power.`;
  } else if (valueRank < 60 && valueRank >= 40) {
    return `${companyName} may be fairly valued. It's cheaper than ${Math.round(
      valueRank,
    )}% of its competitors and more expensive than ${Math.round(
      100 - valueRank,
    )}% of them according to the quantitative factors that science has demonstrated to have the greatest predictive power.`;
  }
  return `${companyName} may be overvalued. It's more expensive than ${Math.round(
    100 - valueRank,
  )}% of its competitors in the quantitative factors that science has demonstrated to have the greatest predictive power.`;
}

function isGoodMoment(companyName: string, momentumScoreRank: number) {
  if (momentumScoreRank >= 60) {
    return `This seems to be a good moment to buy ${companyName} as the stock is oversold according to the quantitative factors that science has demonstrated to have the greatest predictive power.`;
  } else if (momentumScoreRank >= 40) {
    return `This doesn't seem like a particularly good nor bad moment to buy ${companyName} as the stock is neither overbought nor oversold according to the quantitative factors that science has demonstrated to have the greatest predictive power.`;
  }
  return `This looks like a bad moment to buy ${companyName} as the stock is overbought according to the quantitative factors that science has demonstrated to have the greatest predictive power.`;
}
