import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_TYPES_ENUM } from "../../../constants/variables";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { closeModal, selectModal } from "../../../store/slices/modal";
import PricingCards from "./pricing-cards";

const getModalTitle = (type: string, num?: number) => {
  if (type === "analysis") {
    return {
      title: `Wow! You've analyzed ${num} companies this month!`,
      subTitle: "Upgrade now and keep analyzing those companies in seconds!",
    };
  }

  if (type === "portfolio") {
    let title = `Wow! You've added ${num} companies to your portfolio!`;
    if (num === 0) {
      title = `Uhoh! You need basic tier to add companies to your portfolio!`;
    }
    return {
      title: title,
      subTitle: "Upgrade now and keep adding those companies in seconds!",
    };
  }

  let title = `Wow! You've added ${num} companies to your watchlist!`;
  if (num === 0) {
    title = `Uhoh! You need advanced tier to add companies to your portfolio!`;
  }
  // for watchlist
  return {
    title,
    subTitle: "Upgrade now and keep adding those companies in seconds!",
  };
};

export function PricingModal() {
  const modalData = useSelector(selectModal);
  const dispatch = useDispatch();
  const handleCloseModal = () => dispatch(closeModal());
  const [modalType, setModalType] = useState("analysis");
  const [limit, setLimit] = useState<number>(30);
  const [isMonthly, setIsMonthly] = useState(false);
  const [loading, setLoading] = useState(-1);
  const [accessToken, setAccessToken] = useLocalStorage("accessToken", "");

  useEffect(() => {
    setModalType(modalData?.link?.split("-")[0] || "analysis");
    setLimit(parseInt(modalData?.link?.split("-")[1] || "30"));
  }, [modalData]);

  const getCheckoutUrl = async (plan: string) => {
    let planIndex = {
      free: 3,
      basic: 2,
      advanced: 1,
      pro: 0,
    };
    const getProductName = () => {
      if (plan === "free") {
        return null;
      }
      if (plan === "basic") {
        return isMonthly ? "BASIC_MONTHLY" : "BASIC_YEARLY";
      }

      if (plan === "advanced") {
        return isMonthly ? "ADVANCED_MONTHLY" : "ADVANCED_YEARLY";
      }

      if (plan === "pro") {
        return isMonthly ? "PRO_MONTHLY" : "PRO_YEARLY";
      }
    };
    setLoading(planIndex[plan]);
    try {
      const authorization = accessToken.replace(/['"]+/g, "").trim();

      // make api call to get checkout url
      const resp = await axios.post(
        `${
          process.env.REACT_APP_SERVER_URL
        }/api/v1/payments/${getProductName()}`,
        {},
        {
          headers: {
            token: authorization,
            authorization: authorization,
          },
        },
      );

      if (resp.status === 200) {
        console.log(`checkout url:${resp.data.data}`);
        // redirect to checkout url in a new tab
        window.open(resp.data.data, "_blank");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(-1);
    }
  };

  return (
    <Modal
      isOpen={modalData.isOpen && modalData?.type === MODAL_TYPES_ENUM.pricing}
      size="6xl"
      scrollBehavior={"inside"}
      isCentered
      onClose={handleCloseModal}
    >
      <ModalOverlay sx={{ background: "#3a83c9a8" }} />
      <ModalContent
        bg="#FFF"
        borderRadius="20px"
        my={140}
        mx={5}
        p={"20px"}
        display="flex"
        // make the scrollbar narrow
      >
        {/* MONTHLY-YEARLY SWITCH */}
        <ModalBody
          display="flex"
          flexDir="column"
          py={"40px"}
          px="20px"
          rowGap={"30px"}
          mt={0}
          borderRadius={"20px"}
          boxSizing="border-box"
          alignItems={"center"}
          fontFamily="Poppins"
          sx={{
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#0A64BC",
              borderRadius: "24px",
            },
          }}
        >
          <Box
            display={"flex"}
            rowGap={"14px"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Text
              fontFamily="Poppins"
              fontStyle="normal"
              fontWeight="600"
              fontSize="28px"
              lineHeight="36px"
              textAlign="left"
              color="#021425"
            >
              {`${getModalTitle(modalType, limit).title}`.toUpperCase()}
            </Text>
            <Text fontSize={"20px"} fontWeight={"light"}>
              {getModalTitle(modalType, limit).subTitle}
            </Text>
          </Box>
          <PricingCards
            isMonthly={isMonthly}
            setIsMonthly={setIsMonthly}
            showFree={false}
          />

          {/* <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={"10px"}
            fontSize={"sm"}
          >
            <Text color={isMonthly ? "#0A64BC" : ""}>Billed Monthly</Text>
            <Switch
              size="lg"
              isChecked={!isMonthly}
              color={"#0A64BC"}
              onChange={() => setIsMonthly(!isMonthly)}
            />
            <Text color={!isMonthly ? "#0A64BC" : ""}>Billed yearly</Text>
          </Box>
          <Box
            display={{ base: "none", md: "flex" }}
            flexDirection={"row"}
            alignItems={"center"}
            gap={"30px"}
          >
            {Object.values(billingPlans).map((plan, idx) => {
              let { imageSize, width } = getSizes(plan);
              return (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"15px"}
                  // minW={"200px"}
                  maxW={"250px"}
                  backgroundColor={plan.name === "advanced" ? "" : "#F9FAFB"}
                  style={{
                    background:
                      plan.name === "advanced"
                        ? "linear-gradient(to bottom,  #04284B, #085096)"
                        : "",
                  }}
                  color={plan.name === "advanced" ? "#FFFFFF" : ""}
                  p={"30px"}
                  borderRadius={"20px"}
                  boxSizing="content-box"
                >
                  <img
                    alt={`${plan?.title} plan image`}
                    src={`/images/${plan.name}_plan_bg.png`}
                    width={imageSize}
                    height={imageSize}
                    style={{
                      margin: "0 auto",
                      marginBottom: "12px",
                    }}
                  />
                  <Box
                    display="flex"
                    flexDirection={"column"}
                    gap="5px"
                    alignItems={"start"}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"baseline"}
                    >
                      {!isMonthly && plan.name !== "free" && (
                        <Text
                          fontSize={"2xl"}
                          fontWeight={"bold"}
                          textDecoration={"line-through"}
                          marginRight={"5px"}
                          color={
                            plan.name === "advanced" ? "#0A64BC" : "#D3D5DA"
                          }
                        >
                          ${plan.price.monthlyStandard}
                        </Text>
                      )}
                      <Text fontSize={"2xl"} fontWeight={"extrabold"}>
                        $
                        {isMonthly
                          ? plan.price.monthlyDiscounted
                          : plan.price.yearlyDiscounted}
                      </Text>
                      <Text fontSize={"sm"} fontStyle={"italic"} ml={"5px"}>
                        /month
                      </Text>
                    </Box>
                    {!isMonthly && plan.name !== "free" && (
                      <Text
                        fontStyle={"italic"}
                        fontSize={"smaller"}
                        color={plan.name === "advanced" ? "" : "#4D5461"}
                      >
                        billed as ${plan.price.yearlyTotal} per year
                      </Text>
                    )}
                  </Box>

                  <Text fontSize={"xl"} fontWeight={"bold"}>
                    {plan.title}
                  </Text>

                  <Text fontSize={"smaller"}>{plan.summary}</Text>

                  <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                    {plan.optionsList.map((option) => {
                      const [firstWord, secondWord, ...rest] =
                        option.split(" ");

                      return (
                        <Box
                          display="flex"
                          gap="5px"
                          alignItems={"start"}
                          className="option-item"
                          fontSize={"smaller"}
                        >
                          <Text fontWeight={"light"}>
                            <Text fontWeight={"semibold"}>
                              {firstWord} {secondWord}
                            </Text>{" "}
                            {rest.join(" ")}
                          </Text>
                        </Box>
                      );
                    })}
                  </Box>

                  <Button
                    colorScheme={plan.name === "free" ? "whiteAlpha" : "blue"}
                    w={"100%"}
                    h={"56px"}
                    minH={"56px"}
                    backgroundColor={plan.name !== "free" ? "#0A64BC" : "white"}
                    color={plan.name !== "free" ? "#F9FAFB" : "#0564B8"}
                    borderRadius={"8px"}
                    fontSize={"16px"}
                    lineHeight={"24px"}
                    fontWeight={600}
                    fontFamily={"Poppins"}
                    onClick={() => getCheckoutUrl(plan.name)}
                  >
                    {loading === idx ? (
                      <Spinner mr="5px" />
                    ) : (
                      <>
                        {plan.name === "free" ? "Current Plan" : "Upgrade Plan"}
                      </>
                    )}
                  </Button>
                </Box>
              );
            })}
          </Box>
          <Box display={{ base: "flex", md: "none" }}>
            display tabs
            <Tabs>
              <TabList>
                {Object.values(billingPlans).map((plan) => (
                  <Tab key={plan.title} value={plan.name}>
                    {plan.title}
                  </Tab>
                ))}
              </TabList>
              <TabPanels>
                {Object.values(billingPlans).map((plan, idx) => {
                  let { imageSize, width } = getSizes(plan);

                  return (
                    <TabPanel key={plan.title} tabIndex={idx}>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"15px"}
                        minW={"200px"}
                        maxW={"250px"}
                        backgroundColor={
                          plan.name === "advanced" ? "" : "#F9FAFB"
                        }
                        style={{
                          background:
                            plan.name === "advanced"
                              ? "linear-gradient(to bottom,  #04284B, #085096)"
                              : "",
                        }}
                        color={plan.name === "advanced" ? "#FFFFFF" : ""}
                        p={"30px"}
                        borderRadius={"20px"}
                        boxSizing="content-box"
                      >
                        <img
                          alt={`${plan?.title} plan image`}
                          src={`/images/${plan.name}_plan_bg.png`}
                          width={imageSize}
                          height={imageSize}
                          style={{
                            margin: "0 auto",
                            marginBottom: "12px",
                          }}
                        />
                        <Box
                          display="flex"
                          flexDirection={"column"}
                          gap="5px"
                          alignItems={"start"}
                        >
                          <Box
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"baseline"}
                          >
                            {!isMonthly && plan.name !== "free" && (
                              <Text
                                fontSize={"2xl"}
                                fontWeight={"bold"}
                                textDecoration={"line-through"}
                                marginRight={"5px"}
                                color={
                                  plan.name === "advanced"
                                    ? "#0A64BC"
                                    : "#D3D5DA"
                                }
                              >
                                ${plan.price.monthlyStandard}
                              </Text>
                            )}
                            <Text fontSize={"2xl"} fontWeight={"extrabold"}>
                              $
                              {isMonthly
                                ? plan.price.monthlyDiscounted
                                : plan.price.yearlyDiscounted}
                            </Text>
                            <Text
                              fontSize={"sm"}
                              fontStyle={"italic"}
                              ml={"5px"}
                            >
                              /month
                            </Text>
                          </Box>
                          {!isMonthly && plan.name !== "free" && (
                            <Text
                              fontStyle={"italic"}
                              fontSize={"smaller"}
                              color={plan.name === "advanced" ? "" : "#4D5461"}
                            >
                              billed as ${plan.price.yearlyTotal} per year
                            </Text>
                          )}
                        </Box>

                        <Text fontSize={"xl"} fontWeight={"bold"}>
                          {plan.title}
                        </Text>

                        <Text fontSize={"smaller"}>{plan.summary}</Text>

                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          gap={"8px"}
                        >
                          {plan.optionsList.map((option) => {
                            const [firstWord, secondWord, ...rest] =
                              option.split(" ");

                            return (
                              <Box
                                display="flex"
                                gap="5px"
                                alignItems={"start"}
                                className="option-item"
                                fontSize={"smaller"}
                              >
                                <Text fontWeight={"light"}>
                                  <Text fontWeight={"semibold"}>
                                    {firstWord} {secondWord}
                                  </Text>{" "}
                                  {rest.join(" ")}
                                </Text>
                              </Box>
                            );
                          })}
                        </Box>

                        <Button
                          colorScheme={
                            plan.name === "free" ? "whiteAlpha" : "blue"
                          }
                          w={"100%"}
                          h={"56px"}
                          minH={"56px"}
                          backgroundColor={
                            plan.name !== "free" ? "#0A64BC" : "white"
                          }
                          color={plan.name !== "free" ? "#F9FAFB" : "#0564B8"}
                          borderRadius={"8px"}
                          fontSize={"16px"}
                          lineHeight={"24px"}
                          fontWeight={600}
                          fontFamily={"Poppins"}
                        >
                          {plan.name === "free"
                            ? "Current Plan"
                            : "Upgrade Plan"}
                        </Button>
                      </Box>
                    </TabPanel>
                  );
                })}
              </TabPanels>
            </Tabs>
          </Box> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
