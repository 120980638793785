export function ShareIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M18 14a4 4 0 0 0-3.08 1.48l-5.1-2.35a3.64 3.64 0 0 0 0-2.26l5.1-2.35A4 4 0 1 0 14 6a4.17 4.17 0 0 0 .07.71L8.79 9.14a4 4 0 1 0 0 5.72l5.28 2.43A4.17 4.17 0 0 0 14 18a4 4 0 1 0 4-4m0-10a2 2 0 1 1-2 2a2 2 0 0 1 2-2M6 14a2 2 0 1 1 2-2a2 2 0 0 1-2 2m12 6a2 2 0 1 1 2-2a2 2 0 0 1-2 2"
      ></path>
    </svg>
  );
}

export function NewShareIcon(props) {
  return (
    <svg
      {...props}
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1926_15850)">
        <path
          d="M3 11.5879C2.725 11.5879 2.48967 11.4901 2.294 11.2944C2.09833 11.0987 2.00033 10.8632 2 10.5879V5.08789C2 4.81289 2.098 4.57756 2.294 4.38189C2.49 4.18622 2.72533 4.08822 3 4.08789H4.5V5.08789H3V10.5879H9V5.08789H7.5V4.08789H9C9.275 4.08789 9.5105 4.18589 9.7065 4.38189C9.9025 4.57789 10.0003 4.81322 10 5.08789V10.5879C10 10.8629 9.90217 11.0984 9.7065 11.2944C9.51083 11.4904 9.27533 11.5882 9 11.5879H3ZM5.5 8.08789V2.50039L4.7 3.30039L4 2.58789L6 0.587891L8 2.58789L7.3 3.30039L6.5 2.50039V8.08789H5.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1926_15850">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0 0.587891)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
