import { Box, Flex } from "@chakra-ui/react";

function DesktopContent({ children }) {
  return (
    <Box
      display={{ base: "block", md: "block" }}
      borderRadius="30px"
      backgroundColor="#04284B"
      overflow={"hidden"}
    >
      <Flex
        display={{ base: "flex", md: "flex" }}
        minHeight={{ md: "calc(100vh - 145px)" }}
        height="auto"
        columnGap="30px"
        p={{ base: 0, md: "30px" }}
        backgroundColor="#F9FAFB"
      >
        {children}
      </Flex>
    </Box>
  );
}

export default DesktopContent;
