import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useState } from "react";
import { FaCcVisa, FaCreditCard } from "react-icons/fa";
import { RiMastercardFill } from "react-icons/ri";

interface PaymentMethodProps {
  card: any;
  //   onUpdatePaymentMethod: (paymentMethodId: string) => void;
}

export const PaymentMethodStripe = ({ card }: PaymentMethodProps) => {
  const [isLoading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [paymentMethod, setPaymentMethod] = useState<string>("credit-card");

  const handlePaymentMethodChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setPaymentMethod(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      console.error("CardElement not found");
      setLoading(false);
      return;
    }

    const { paymentMethod: newPaymentMethod, error } =
      await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

    if (error) {
      console.error(error);
      setLoading(false);
    } else if (newPaymentMethod) {
      try {
        const accessToken = localStorage.getItem("accessToken"); // Get token from localStorage
        const user = JSON.parse(
          localStorage.getItem("user-subscription") || "",
        );
        const headers = {
          Authorization: accessToken, // Include token in Authorization header
          Token: accessToken,
          "Content-Type": "application/json", // Optional: Add if you're sending JSON data
        };

        const payload = {
          userId: user.user._id,
          paymentMethodId: newPaymentMethod.id,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/payments/update-subscription-payment-method`,
          payload,
          { headers }, // Pass the headers with the request
        );
        setLoading(false);
        if (response.data) {
          // Reload the page if a response is received
          window.location.reload();
        }
      } catch (error) {
        setLoading(false);
        console.error("Error updating payment method:", error);
      }
    }
  };

  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p="6"
      boxShadow="lg"
    >
      <Stack spacing={4}>
        <Heading size="md">Payment Method</Heading>
        {card.map((item: any, index: any) => {
          return (
            <Flex alignItems="center" justifyContent="space-between">
              {index === 0 ? (
                <Text fontSize="lg" fontWeight="bold">
                  Default Card:
                </Text>
              ) : (
                <Text fontSize="lg" fontWeight="bold" opacity={0}>
                  Default Card:
                </Text>
              )}
              <Badge
                colorScheme="white"
                px="4"
                py="2"
                borderRadius="full"
                borderWidth="1px"
                borderColor="gray.300"
              >
                <Flex alignItems="center">
                  {item.brand === "visa" ? (
                    <FaCcVisa
                      color="#002A7A"
                      size="20px"
                      style={{ marginRight: "8px" }}
                    />
                  ) : item.brand === "mastercard" ? (
                    <RiMastercardFill
                      color="#EB001B"
                      size="20px"
                      style={{ marginRight: "8px" }}
                    />
                  ) : (
                    <FaCreditCard
                      color="#000"
                      size="20px"
                      style={{ marginRight: "8px" }}
                    />
                  )}
                  {item.brand.toUpperCase()} - {item.last4}
                </Flex>
              </Badge>
            </Flex>
          );
        })}

        <Box>
          <Text>Select New Payment Method:</Text>
          <Select
            placeholder="Select payment method"
            mt="2"
            onChange={handlePaymentMethodChange}
          >
            <option value="credit-card">Credit Card</option>
            <option value="debit-card">Debit Card</option>
            {/* <option value="paypal">PayPal</option>
            <option value="apple-pay">Apple Pay</option> */}
          </Select>
        </Box>

        {/* Conditionally render Stripe CardElement for credit/debit cards */}
        {(paymentMethod === "credit-card" ||
          paymentMethod === "debit-card") && (
          <Box mt="4">
            <FormControl>
              <FormLabel>Card Information</FormLabel>
              <Box borderWidth="1px" borderRadius="md" p="2">
                <CardElement />
              </Box>
            </FormControl>
          </Box>
        )}

        <Button
          isLoading={isLoading}
          colorScheme="teal"
          mt="4"
          onClick={handleSubmit}
          disabled={!stripe}
        >
          Update Payment Method
        </Button>
      </Stack>
    </Box>
  );
};
