import { Box, Text } from "@chakra-ui/react";
import { monkScoreToColor } from "../../utils/monkScore";

interface MonkScoreProps {
  monkScore: number;
}

export default function MonkScore({ monkScore }: MonkScoreProps) {
  return (
    <>
      {/* <Text
        color={monkScoreToColor(monkScore)}
        fontSize={{ base: "8px", md: "10px" }}
        fontWeight={700}
        lineHeight="15px"
        me={2}
      >
        {monkScoreToText(monkScore)}
      </Text> */}

      <Text fontSize={{ base: "10px", md: "12px" }} lineHeight="18px" me={2}>
        {monkScore}
      </Text>

      <Box
        backgroundColor={monkScoreToColor(monkScore)}
        height="21px"
        width="21px"
        borderRadius="50%"
        mr={2}
      >
        &nbsp;
      </Box>
    </>
  );
}
