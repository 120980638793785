import { Box, Button, Flex } from "@chakra-ui/react";
import cn from "classnames";
import { PropsWithChildren } from "react";
import { IMenu } from "../../../types/common";
import styles from "./MobileStockMenu/styles.module.css";

type CommonMenuProps = {
  menuLoading?: boolean;
  sectionName: string;
  currentIdx: number;
  onSelectMenu: (index: number, menuItem: Omit<IMenu, "_id">) => void;
  menu?: Array<Omit<IMenu, "_id">>;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
};

export function CommonMenu({
  currentIdx = 0,
  menu = [],
  onSelectMenu,
  icon,
  sectionName,
  children,
  menuLoading = false,
}: PropsWithChildren<CommonMenuProps>) {
  return (
    <Box w={{ base: "100%" }} className="scrollable_box">
      <Flex
        w={"max-content"}
        px="7px"
        alignItems={"center"}
        justifyContent={"flex-start"}
        columnGap={"20px"}
      >
        {menu.map((item, idx) => {
          return (
            <Button
              key={item?.name}
              // w={'max-content'}
              color={currentIdx === idx ? "#FFF" : "#CEE0F1"}
              variant={"unstyled"}
              fontSize={"14px"}
              fontWeight={currentIdx === idx ? 500 : 400}
              lineHeight={"20px"}
              onClick={() => onSelectMenu(idx, item)}
              className={cn(
                styles.mobileMenuItem,
                currentIdx === idx && styles.active,
              )}
            >
              {item.name}
            </Button>
          );
        })}
      </Flex>
    </Box>
  );
}
