import {
  Box,
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import { FormattedMessage } from "react-intl";
import "../Stocks/tooltipModal/tooltipModal.css";

const TermConditionModal = ({ isOpen, setIsOpen, TCChecked, setTCChecked }) => {
  const [termCheck, setTermCheck] = useState(false);
  const handleCurrentAction = () => {
    setTCChecked(true);
    setTimeout(() => setIsOpen(false), 1000);
  };
  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalOverlay sx={{ background: "#f9fafcd4" }} />
      <ModalContent
        height="400px"
        mx={4}
        sx={{
          boxShadow:
            "0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)",
          borderRadius: "12px",
        }}
        my={4}
      >
        <ModalHeader pb={4} borderBottom="1px solid lightgray">
          <Flex>
            <Text
              fontSize="16px"
              fontFamily="Poppins"
              fontWeight="500"
              lineHeight="24px"
              display="flex"
              alignItems="center"
              ps={3}
              pe={5}
            >
              Terms & Conditions
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton size="lg" />
        <ModalBody pe={4}>
          <Flex mt={5} justifyContent="flex-start" fontFamily="Poppins">
            <Checkbox
              height="24px"
              width="24px"
              mx={3}
              className="form_checkBox"
              backgroundColor="white"
              borderRadius="8px"
              border="1.5px solid #D3D5DA"
              boxShadow={0}
              type="checkbox"
              isChecked={termCheck}
              onChange={(e) => {
                setTermCheck(e.target.checked);
              }}
            />
            <Text
              fontSize="16px"
              fontFamily="Poppins"
              fontWeight="400"
              lineHeight="24px"
              color="#475467"
            >
              <FormattedMessage
                id="tc_check"
                values={{
                  span: (chunks: ReactNode) => (
                    <span className="highlight_link">{chunks}</span>
                  ),
                }}
              />
            </Text>
          </Flex>
          <Flex mt={5}>
            <Checkbox
              height="24px"
              width="24px"
              mx={3}
              className="form_checkBox"
              backgroundColor="white"
              borderRadius="8px"
              border="1.5px solid #D3D5DA"
              boxShadow={0}
              type="checkbox"
            />
            <Text
              fontSize="16px"
              fontFamily="Poppins"
              fontWeight="400"
              lineHeight="24px"
              color="#475467"
            >
              <FormattedMessage
                id="pn_update_check"
                values={{
                  span: (chunks: ReactNode) => (
                    <span className="highlight_link">{chunks}</span>
                  ),
                }}
              />
            </Text>
          </Flex>
        </ModalBody>

        <Box borderTop="1px solid lightgray" p={4}>
          <Button
            background="#0A64BC"
            my={4}
            _hover={
              TCChecked ? { background: "#0A64BC" } : { background: "#0A64BC" }
            }
            py="25px"
            px="20px"
            style={{
              border: "1px solid #F9FAFB",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
              borderRadius: "16px",
              width: "100%",
            }}
            color="white"
            fontSize="16px"
            fontFamily="Poppins"
            fontWeight="600"
            lineHeight="24px"
            isDisabled={!termCheck}
            onClick={handleCurrentAction}
          >
            Continue
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default TermConditionModal;
