import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Dispatch, useState } from "react";
import hugEmoji from "../../../assets/image/hugging-face.png";

export function LoggedUserModal({
  open,
  setOpen,
  setAccessToken,
}: {
  open: boolean;
  setOpen: () => void;
  setAccessToken: Dispatch<string>;
}) {
  const [user, setUser] = useState(() => {
    if (typeof window === "undefined") {
      return null;
    }

    try {
      const item = localStorage.getItem("usr");
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  });

  const handleSignOut = () => {
    localStorage.clear();
    setUser(null);
    setAccessToken("");
    localStorage.removeItem("accessToken");
    setOpen();
    window.location.replace("/");
  };

  return (
    <>
      <Modal
        isOpen={open}
        onClose={() => setOpen()}
        size="sm"
        isCentered
        scrollBehavior="inside"
      >
        <ModalOverlay sx={{ background: "#3a83c9a8" }} />
        <ModalContent bg="#FFF" borderRadius="20px" my={140} mx={5} p={"20px"}>
          {/* <ModalHeader display="flex" alignItems="center" w="full"> */}

          {/* </ModalHeader> */}
          <ModalBody
            display="flex"
            py={"40px"}
            px="20px"
            flexDir="column"
            backgroundColor={"#F9FAFB"}
            borderRadius={"20px"}
            rowGap={"30px"}
            mt={0}
          >
            <img
              style={{ margin: "0 auto", marginBottom: 0, padding: 0 }}
              width={"100px"}
              height={"100px"}
              src={hugEmoji}
              alt="Tada"
            />
            <Text
              fontFamily="Poppins"
              fontStyle="normal"
              fontWeight="600"
              fontSize="24px"
              lineHeight="36px"
              textAlign="center"
              color="#000000"
            >
              {`Howdy${user && user?.firstName ? `, ${user?.firstName}` : ""}!`}
            </Text>

            <Text
              fontFamily="Poppins"
              fontStyle="normal"
              fontWeight="300"
              fontSize="16px"
              lineHeight="24px"
              textAlign="center"
              color="#000"
            >
              You've analyzed <b>1 of your 5</b> available companies this month.
            </Text>

            <Text
              fontFamily="Poppins"
              fontWeight="300"
              fontSize="16px"
              lineHeight="24px"
              textAlign="center"
              color="#000"
            >
              No worries, your analysis-meter resets on <b>February 24th.</b>
            </Text>

            <Text
              fontFamily="Poppins"
              fontWeight="300"
              fontSize="16px"
              lineHeight="24px"
              textAlign="center"
              color="#021425"
            >
              Keep discovering!
            </Text>
            <Button
              colorScheme="blue"
              onClick={() => setOpen()}
              w={"100%"}
              h={"56px"}
              minH={"56px"}
              color={"#F9FAFB"}
              backgroundColor={"#0A64BC"}
              borderRadius={"8px"}
              fontSize={"16px"}
              lineHeight={"24px"}
              fontWeight={600}
              fontFamily={"Poppins"}
            >
              {"Continue Analyzing"}
            </Button>
            <Button
              colorScheme="whiteAlpha"
              onClick={handleSignOut}
              fontFamily={"Poppins"}
              w={"100%"}
              h={"56px"}
              minH={"56px"}
              color="#0564B8"
              backgroundColor={"white"}
              borderRadius={"8px"}
              fontSize={"16px"}
              lineHeight={"24px"}
              fontWeight={600}
            >
              {"Log out"}
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
