import { Text } from "@chakra-ui/react";
import { Cell, Pie, PieChart } from "recharts";
import { monkScoreToColor } from "../../../../utils/monkScore";
import styles from "./styles.module.css";

const COLORS = ["#E5E6EB", "#58CC02"];

const SCORE_COLORS = {
  "#357A01": "#58CC02",
  "#58CC02": "#9BE067",
  "#9BE067": "#FFC800",
  "#FFC800": "#FF4B4B",
  "#FF4B4B": "#FF0000",
};

function ScorePieChart({ score }: { score: number }) {
  const monkColor = score ? monkScoreToColor(score) : "#FFF";
  return (
    <div style={{ position: "relative", height: "auto", width: "auto" }}>
      <div className={styles.textWrapper}>
        <span className={styles.score}>{score}</span>
        <Text as="span" className={styles.label}>
          MonkScore<sup>®</sup>
        </Text>
      </div>
      <PieChart width={120} height={120}>
        <defs>
          <linearGradient id="colorUv" x1="1" y1="1" x2="1" y2="0">
            <stop offset="15%" stopColor={`${monkColor}`} stopOpacity={1} />
            <stop
              offset="85%"
              stopColor={`${SCORE_COLORS[monkColor]}`}
              stopOpacity={1}
            />
          </linearGradient>
        </defs>
        <Pie
          data={[
            { name: "rest", value: 100 - (score ?? 0) },
            { name: "company", value: score ?? 0 },
          ]}
          cx={55}
          cy={55}
          innerRadius={42}
          outerRadius={60}
          startAngle={90}
          endAngle={630}
          paddingAngle={0}
          dataKey="value"
          stroke=""
          fill="#dfdfdf"
        >
          {[
            { name: "company", value: score ?? 0 },
            { name: "rest", value: 100 - (score ?? 0) },
          ].map((entry, idx) => (
            <Cell
              key={`cell-${idx}`}
              fill={idx === 1 ? "url(#colorUv)" : COLORS[idx % COLORS.length]}
            />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
}

export default ScorePieChart;
